import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useAxios from "../../hook/useAxios";
import { MapMarker } from "../../type/mapMarker";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { useSelector } from "react-redux";
import { profileInfo } from "../../store";

const Map: React.FC<{
  markerList: MapMarker[];
  kmlData: string | undefined;
  search: boolean;
  searchingStateHandler: () => void;
}> = ({ markerList, kmlData, search, searchingStateHandler }) => {
  const [infoMarker, setInfoMarker] = useState<{
    marker: google.maps.Marker;
    markerObj: MapMarker;
  }>();
  const profile = useSelector(profileInfo);
  const googleMapRef = useRef<HTMLDivElement>(null);

  const { t, i18n } = useTranslation();
  let googleMap: google.maps.Map;
  let parser;
  let infowindow: google.maps.InfoWindow = new window.google.maps.InfoWindow(
    {}
  );
  const [currentInfoWindow, setCurrentInfoWindow] =
    useState<google.maps.InfoWindow>(infowindow);

  const { fetchData, response } = useAxios("", "GET", false, "", true, true);

  const getTranslatedValue = (field: any, lang: string) => {
    if (!field) return "";

    if (lang === "en") {
      return field.en === "" ? field.es : field.en;
    } else {
      return field.es;
    }
  };

  const species = getTranslatedValue(
    response?.data?.species_name_transes,
    i18n.language
  );
  const nursery = getTranslatedValue(
    response?.data?.nursery_name_transes,
    i18n.language
  );
  const description = getTranslatedValue(
    response?.data?.description_transes,
    i18n.language
  );
  const responsibleCategory =
    i18n.language === "en"
      ? response?.data?.responsible_category_transes.en
      : response?.data?.responsible_category_transes.es;

  const getAge = (years: number, months: number) => {
    if (years < 0 || months < 0) {
      return `<span class='age-btn'>
                    <img class='info-icon' src='/img/info.svg' alt='negative age'/>
                    <div class='age-text'>
                      <p>${i18n.language === "en"
          ? "The age of the tree is wrong. Please check the planting date."
          : "La edad del árbol es incorrecta. Por favor verifique la fecha de siembra."
        }</p>
                    </div>
                  </span>`;
    }
    if (years === 0 && months === 0) {
      return "-";
    }
    if (years > 0 && months > 0) {
      return `${years} ${t("Register.Year")}, ${months} ${t("Register.Month")}`;
    }
    if (years > 0) {
      return `${years} ${t("Register.Year")}`;
    }
    if (months > 0) {
      return `${months} ${t("Register.Month")}`;
    }
    return "";
  };

  const age = getAge(response?.data?.age_year, response?.data?.age_month);

  useEffect(() => {
    if (response) {
      searchingStateHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  useEffect(() => {
    if (
      response &&
      infoMarker?.markerObj.slug === response?.data?.slug &&
      !search
    ) {
      const url = window.location.href;
      currentInfoWindow.setContent(`
    <style>
      h1 {
        font-weight: 600;
        display: inline;
        color: #414141;
      }
      a {
        display: inline;
        margin-left: 10px;
        color: #414141;
      }
      .title-lbl {
        font-size: 14px;
        color: #979797;
      }
      .sub-lbl {
        color: #414141;
        font-size: 14px;
        font-weight: 500;
        margin-top: 8px;
      }
      .desc-lbl {
        color: #414141;
        font-size: 14px;
        font-weight: 500;
        margin-top: 8px;
        max-width: 90%;
        word-wrap: break-word;
        max-height: 30px;
        overflow-y: auto;
      }
      .edit-icon {
        width: 12px;
        height: 12px;
        display: inline;
      }
      .map-icon {
        width:20px;
        height:20px;
      }
      .box {
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .left-box {
        width: 28%;
        border-radius: 8px;
        border: 1px solid #f6f6f6;
      }
      .img-left {
        width:100%;
        height: 100%;
        object-fit: cover;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        max-height: 204px;
      }
      .bottom-box {
        padding-right: 16px;
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 6px;
        height:40%;
      }
      .content-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .first-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        width:40%;
      }
      .desc-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        width:100%;
      }
      .last-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        width:20%;
      }
      .location-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        width:60%;
      }
      .column-box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 12px;
        width: 100%;
      }
      .date-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 20px;
      }
      .date-circle {
        background-color: #e3f3e8;
        border-radius: 14px;
        padding: 4px 10px;
        color: #7eb48b;
        margin-top: 8px;
        width:55%;
      }
      .right-box {
        width: 71%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 8px;
      }
      .child-right-box {
        padding: 16px;
        border: 1px solid #f6f6f6;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .age-btn {
        width:14px;
        height:14px;
        border-radius: 0.8rem;
        color: #d2202a;
        opacity: 1;
        position: relative;
        cursor: pointer;
        }
      .age-text {
        position: absolute;
        display: none;
        width: 250px;
        height:fit-content;
        left:-85px;
        top:-85px;
        padding: 10px;
        border-radius: 0.3rem;
        z-index: 10;
        background-color: white;
        color: #414141;
        overflow-wrap: break-word;
      }
      .age-btn:hover .age-text {
        display: inline;
      }
      .info-icon{
        width:18px; 
        height:18px;
      }
      .image-section{
        height:50%;
      }
      .margin-h1{
        margin-left: 18px;
      }
      @media (min-width: 768px) and (max-width: 1023px) {
        .box{
          justify-content: unset;
        }
         .left-box{
           width: 37%;
         }
           .right-box{
           margin-left: 5px;
           width:68%;
           }
           .sub-lbl{
           font-size:10px;
           }
           .title-lbl{
           font-size:10px;
           }
            .date-circle {
            width: 65%;
      }
      }
        @media (max-width: 767px){
         .box{
           flex-direction: column;
         }
         .left-box{
           width: 100%;
         }
         .right-box{
         margin-top: 10px;
           width: 100%;
         }
           .child-right-box{
            flex-direction: column;
           }
            .first-row{
            margin-bottom: 10px;
            }
              .date-circle {
            width: 38%;
      }
      }
    
    </style>
             <div style="font-family: Inter;">
             <h1 class="margin-h1">${response.data.tag}</h1>
             ${profile.role !== "V" &&
        `<a href='${url.split("/")[0]}/registers/edit-register/${response.data.slug
        }'>`
        }
             <img class='edit-icon' src='/img/Edit pen.svg' alt='edit'></a>
        <div class="box">
        <div class="left-box">
          <div class="image-section">
            ${response.data.planted_tree_image
          ? `<img class="img-left" src=${response.data.planted_tree_image} alt='Tree'>`
          : `<img class="img-left" src="/img/map-placeholder.svg" alt="noPic" />`
        }
          </div>
          <div class="bottom-box">
            <div class="content-box">
              <div class="first-row">
                <div><img class='map-icon' src='/img/map-tree.svg' alt='species'></div>
                <div class="column-box">
                  <div class="title-lbl">${t("Map.Species")}</div>
                  <div class="sub-lbl">${species}</div>
            </div>
              </div>
              <div class="first-row">
                <div><img class='map-icon' src='/img/map-origin.svg' alt='Origin'></div>
                <div class="column-box">
             <div class="title-lbl">${t("Map.Nursery")}</div>
             <div class="sub-lbl">${nursery}</div>
             </div>
              </div>
            </div>
            <div class="date-row">
              <div><img class='map-icon' src='/img/map-date.svg' alt='planting date'></div>
              <div class="column-box">
             <div class="title-lbl">${t("Plantings.Date of planting")}</div>
             <div class="date-circle">${response.data.planting_date}</div>
             </div>
            </div>
          </div>
        </div>
           <div class="right-box">
          <div class="child-right-box">
            <div class="first-row">
              <div><img class='map-icon' src='/img/map-age.svg' alt='age'></div>
              <div class="column-box">
                <div class="title-lbl"> ${t("Register.Age")}</div>
                <div class="sub-lbl"> ${age}</div>
              </div>
            </div>
            <div class="first-row">
              <div><img class='map-icon' src='/img/map-height.svg' alt='height'></div>
              <div class="column-box">
                <div class="title-lbl">${t("Register.Tree height")}</div>
                <div class="sub-lbl">${response.data.height !== null
          ? response.data.height + " " + t("in") + " “cm”"
          : "-"
        }</div>
              </div>
            </div>
            <div class="last-item">
              <div><img class='map-icon' src='/img/map-diameter.svg' alt='diameter'></div>
              <div class="column-box">
                <div class="title-lbl">${t("Register.Diameter")}</div>
                <div class="sub-lbl">${response.data.diameter !== null
          ? response.data.diameter + " " + t("in") + " “mm”"
          : "-"
        }</div>
              </div>
            </div>
          </div>
          <div class="child-right-box">
            <div class="first-row">
              <div><img class='map-icon' src='/img/map-responsible.svg' alt='responsible'></div>
              <div class="column-box">
                <div class="title-lbl">${t("Register.Responsible")}</div>
                <div class="sub-lbl">${response.data.responsible_name}</div>
              </div>
            </div>
             <div class="first-row">
              <div><img class='map-icon' src='/img/map-category.svg' alt='category'></div>
              <div class="column-box">
                <div class="title-lbl">${t("Register.Category")}</div>
                <div class="sub-lbl">${responsibleCategory}</div>
              </div>
            </div>
             <div class="last-item">
              <div><img class='map-icon' src='/img/map-type.svg' alt='type'></div>
              <div class="column-box">
                <div class="title-lbl">${t("Tree Species.Type")}</div>
                <div class="sub-lbl">${response.data.responsible_type || ""
        }</div>
              </div>
            </div>
          </div>
          <div class="child-right-box">
            <div class="first-row">
              <div><img class='map-icon' src='/img/map-latitude.svg' alt='latitude longitude'></div>
              <div class="column-box">
                <div class="title-lbl">${t("Register.Latitude")}, ${t(
          "Register.Longitude"
        )}</div>
                <div class="sub-lbl">${response.data.latitude}, ${response.data.longitude
        }</div>
              </div>
            </div>
             <div class="location-item">
             <div><img class='map-icon' src='/img/map-location.svg' alt='location'></div>
              <div class="column-box">
                <div class="title-lbl">${t("PlantingFilter.Location")}</div>
                <div class="sub-lbl">${response.data.department}, ${response.data.municipality
        }, ${response.data.community || ""}</div>
              </div>
            </div>
          </div>
             <div class="child-right-box">
            <div class="desc-row">
              <div class="column-box">
                <div class="title-lbl">${t("Map.Description")}</div>
                <div class="desc-lbl">${description || "-"}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
       `);
      currentInfoWindow.open(googleMap, infoMarker?.marker);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInfoWindow, response, infoMarker, search]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    googleMap = initGoogleMap();
    let bounds = new window.google.maps.LatLngBounds();
    const markers: google.maps.Marker[] = [];
    markerList.forEach((x) => {
      const marker: any = createMarker(x);
      bounds.extend(marker.position);
      markers.push(marker);
      googleMap.setCenter(new google.maps.LatLng(3.822566, -72.78333));
      googleMap.setZoom(7);
    });
    const cluster = new MarkerClusterer({
      map: googleMap,
      markers: markers,
    });

    if (kmlData !== "") {
      /* eslint-disable new-cap */
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line react-hooks/exhaustive-deps
      parser = new geoXML3.parser({ map: googleMap });
      /* eslint-enable */
      parser.parseKmlString(kmlData);
    }
  }, []);

  // initialize the google map
  const initGoogleMap = () => {
    return new window.google.maps.Map(googleMapRef.current! as HTMLElement, {
      zoom: 6,
      center: { lat: 3.741765, lng: -73.508309 },
      mapId: "de709e4ae98647a3",
    });
  };

  // create marker on google map
  const createMarker = (markerObj: MapMarker) => {
    const marker = new window.google.maps.Marker({
      position: { lat: markerObj.latitude, lng: markerObj.longitude },
      map: googleMap,
      icon: {
        url: markerObj.featured_icon,
        // set marker width and height
        scaledSize: new window.google.maps.Size(25, 25),
      },
    });

    marker.addListener("click", () => {
      if (currentInfoWindow) {
        currentInfoWindow.close();
      }

      fetchData(
        { slug: markerObj.slug },
        process.env.REACT_APP_API_URL +
        `/api/admin/map/${markerObj.slug}/details/`
      );
      setInfoMarker({ marker: marker, markerObj: markerObj });
      setCurrentInfoWindow(infowindow);
    });

    return marker;
  };
  return <div ref={googleMapRef} className="w-full h-[580px]" />;
};

export default Map;
