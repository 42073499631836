import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { reportPlantedFilters } from "../../../store";
import AppliedReportItem from "../Donors & Sponsors/AppliedReportItem";

const PlantedTreesFilterApplied = () => {
    const filters = useSelector(reportPlantedFilters);
    const { t } = useTranslation();
    const formatDate = (dateString: string) => {
        const formattedDate = dateString?.replace(/-/g, '/');
        const splitDate = formattedDate.split("/");
        return `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`
    }
    return (
        <div className="w-full h-fit bg-white p-4 rounded-md relative -mt-2">
            <h1 className="text-ph-gray text-sm font-medium">
                {t("Tree Species.Applied Filters")}
            </h1>
            <div className="flex flex-wrap items-center font-medium">
                {filters.view_by !== "" && (
                    <AppliedReportItem
                        type={t("Reports.View by")}
                        list={false}
                        listValue={[]}
                        stringValue={`${filters.view_by === "LO" ? t("Reports.Location") : filters.view_by === "TR" ? t("Reports.Trees") : t("Reports.Participants & responsible")} `}
                    />
                )}
                {filters.date_to !== "" && (
                    <AppliedReportItem
                        type={t("Tree inventory.Date")}
                        list={false}
                        listValue={[]}
                        stringValue={`${filters.date_from ? formatDate(filters.date_from) : ""} ${filters.date_from !== null ? "-" : ""} ${formatDate(filters.date_to)}`}
                    />

                )}
                {filters.level !== "" && (
                    <AppliedReportItem
                        type={t("Reports.Level")}
                        list={false}
                        listValue={[]}
                        stringValue={`${filters.level === "D" ? t("Reports.Departmental") : filters.level === "N" ? t("Reports.National") : t("Reports.Local")} `}
                    />
                )}
                {(filters.departments.length > 0 && filters.level === "D") && (
                    <AppliedReportItem
                        type={t("Navbar.Departments")}
                        listValue={filters.departments}
                        list={true}
                        stringValue=""
                    />
                )}
                {(filters.communities.length > 0 && filters.level === "L") && (
                    <AppliedReportItem
                        type={t("Plantings.Community")}
                        listValue={filters.communities}
                        list={true}
                        stringValue=""
                    />
                )}
            </div>
        </div>
    );
};

export default PlantedTreesFilterApplied;
