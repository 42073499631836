import { Controller } from "react-hook-form";
import {
  Checkbox,
  TextField,
  Autocomplete,
  autocompleteClasses,
} from "@mui/material";
import { faMagnifyingGlass } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { colors } from "../../styles/colors";
import { useEffect, useState } from "react";

type CustomChangeValue<T> = T | T[] | null;

interface ReusableAutocompleteProps<T> {
  control: any;
  rules?: any;
  name: string;
  selectOptions: T[];
  placeholder: string;
  disabled?: boolean;
  icon?: JSX.Element;
  checkedIcon?: JSX.Element;
  getOptionLabel: (option: T) => string;
  onCustomChange?: (newValue: CustomChangeValue<T>) => void;
  isMulti?: boolean;
  defaultValue?: T | T[];
  readonly?: boolean;
  allValue?: T;
  allSelectDefault?: boolean;
}

const autocompleteStyles = (readonly: boolean, disabled: boolean) => ({
  width: "100%",
  [`& .${autocompleteClasses.popupIndicator}`]: {
    transform: "none",
    color: disabled && readonly && colors.bg_gray,
  },
  color: readonly ? colors.white : undefined,
  border: "1px solid #eeecec",
  borderRadius: "4px",
  "& fieldset": {
    border: "none",
  },
  "& .Mui-focused": {
    outline: "1px solid #efbf07",
  },
  "& .MuiChip-label": {
    color: readonly ? colors.white : undefined,
  },
  "& .MuiFormControl-root": {
    "& .Mui-disabled": {
      backgroundColor: disabled && readonly ? colors.bg_gray : undefined,
      opacity: 100,
    },
  },
  "& .MuiInputBase-root": {
    color: readonly ? colors.white : colors.default_text,
  },
});

const getIsSelected = (
  isMulti: boolean,
  value: any,
  option: any,
  getOptionLabel: Function
) => {
  return isMulti
    ? (value || []).some(
        (v: any) => getOptionLabel(v) === getOptionLabel(option)
      )
    : getOptionLabel(value || {}) === getOptionLabel(option);
};

function CustomAutocomplete<T>({
  control,
  rules,
  name,
  selectOptions,
  placeholder,
  disabled = false,
  icon = <FontAwesomeIcon className="w-4 h-4" icon={faMagnifyingGlass} />,
  checkedIcon = (
    <CheckBoxIcon sx={{ color: colors.green_checkbox }} fontSize="small" />
  ),
  getOptionLabel,
  onCustomChange,
  isMulti = false,
  defaultValue,
  readonly = false,
  allValue,
  allSelectDefault = false,
}: ReusableAutocompleteProps<T>) {
  const [allSelected, isAllSelected] = useState<boolean>(allSelectDefault);
  useEffect(() => {
    isAllSelected(allSelectDefault);
  }, [allSelectDefault]);

  const handleChange = (newValue: T | T[] | null) => {
    if (Array.isArray(newValue)) {
      const deduplicated = Array.from(
        new Set(newValue.map((item) => getOptionLabel(item)))
      ).map((label) => newValue.find((item) => getOptionLabel(item) === label));
      onCustomChange && onCustomChange(deduplicated as T[]);
    } else {
      onCustomChange && onCustomChange(newValue);
    }
  };

  const getValue = (value: any) => {
    if (value) {
      return value;
    } else {
      isAllSelected(false);
      return [];
    }
  };

  const checkAllSelect = (value: string[]) => {
    if (allValue) {
      const data = value.filter((item) => item !== "All" && item !== "Todos");

      const hasAll = value.find((item) => item === "All" || item === "Todos");

      if (hasAll || data.length === selectOptions.length - 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          popupIcon={icon}
          multiple={isMulti}
          disabled={disabled}
          limitTags={1}
          id="reusable-autocomplete"
          options={selectOptions}
          disableCloseOnSelect={isMulti}
          getOptionLabel={getOptionLabel}
          sx={autocompleteStyles(readonly, disabled)}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={isMulti && value?.length > 0 ? "" : placeholder}
              InputProps={{
                ...params.InputProps,
                style: {
                  color: readonly ? colors.white : undefined,
                },
              }}
              InputLabelProps={{
                style: {
                  color: readonly ? colors.white : undefined,
                },
              }}
            />
          )}
          renderOption={(props, option, { selected }) => {
            const isSelected = getIsSelected(
              isMulti,
              value,
              option,
              getOptionLabel
            );

            return (
              <>
                <li {...props}>
                  {isMulti && (
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={checkedIcon}
                      style={{
                        marginRight: 8,
                      }}
                      checked={isSelected || allSelected ? true : false}
                    />
                  )}
                  {getOptionLabel(option)}
                </li>
              </>
            );
          }}
          onChange={(event, newValue) => {
            if (allSelected) {
              isAllSelected(false);
            }
            if (Array.isArray(newValue)) {
              const arrayValues = Array.from(
                new Set(newValue.map((item) => getOptionLabel(item)))
              );

              if (checkAllSelect(arrayValues)) {
                isAllSelected(true);
                onChange([allValue]);
                handleChange([allValue] as T | T[] | null);
              } else {
                onChange(
                  arrayValues.map((label) =>
                    newValue.find((item) => getOptionLabel(item) === label)
                  )
                );
                handleChange(
                  arrayValues.map((label) =>
                    newValue.find((item) => getOptionLabel(item) === label)
                  ) as T | T[] | null
                );
              }
            } else {
              onChange(newValue);
              handleChange(newValue as T | T[] | null);
            }
          }}
          value={value || (isMulti ? getValue(value) : value || null)}
          readOnly={readonly}
        />
      )}
    />
  );
}

export default CustomAutocomplete;
