import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { momentReportFilters } from "../../../store";
import AppliedIncidents from "../../Register/RegisterList/AppliedIncidents";
import AppliedReportItem from "../Donors & Sponsors/AppliedReportItem";

const PlantingMomentFilterApplied = () => {
  const filters = useSelector(momentReportFilters);
  const { t } = useTranslation();

  return (
    <div className="w-full h-fit bg-white p-4 rounded-md relative -mt-2">
      <h1 className="text-ph-gray text-sm">
        {t("Tree Species.Applied Filters")}
      </h1>
      <div className="flex flex-wrap items-center font-medium">
        {filters.level !== "" && (
          <AppliedReportItem
            type={t("Reports.Level")}
            list={false}
            listValue={[]}
            stringValue={`${
              filters.level === "D"
                ? t("Reports.Departmental")
                : t("Reports.National")
            } `}
          />
        )}
        {filters.year !== "" && (
           <AppliedReportItem
           type={t("Register.Year")}
           list={false}
           listValue={[]}
           stringValue={filters.year}
         />
        )}

        {filters.departments.length > 0 && filters.level === "D" && (
           <AppliedReportItem
           type={t("Navbar.Departments")}
           listValue={filters.departments}
           list={true}
           stringValue=""
       />
        )}
        {filters.incidents.length > 0 && (
          <AppliedIncidents
            isReportFilter
            type={t("Incidents.Incidents")}
            value={filters.incidents}
            removeFilter={() => {
            }}
          />
        )}
      </div>
    </div>
  );
};

export default PlantingMomentFilterApplied;
