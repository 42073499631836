import { useTranslation } from "react-i18next";
import useAxios from "../../../hook/useAxios";
import BreadCrumb from "../../Global/BreadCrumb";
import AddTaggingToolForm from "./AddTaggingToolForm";
import { useEffect, useState } from "react";
import { Nursery } from "../../../type/Nursery";
import { Community } from "../../../type/community";
import SpeciesList from "../../../type/speciesList";

const AddTaggingTool = () => {
  const { t } = useTranslation();
  const [selectOption, setSelectOption] = useState<{
    community: Community[];
  }>({ community: [] });
  const [treeSpecies, setTreeSpecies] = useState<SpeciesList[]>([]);
  const [nurseries, setNurseries] = useState<Nursery[]>([]);
  const { fetchData, response } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/tags/new/`,
    "POST",
    false,
    "",
    true,
    true
  );
  const { response: communitiesRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/communities/all",
    "GET",
    true,
    "",
    true,
    false
  );
  const { response: Nurseries } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/nurseries/all/",
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: TreeSpecies } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/species/all/",
    "GET",
    true,
    "",
    false,
    false
  );
  useEffect(() => {
    if (communitiesRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          community: communitiesRes.data,
        };
      });
    }
    if (Nurseries) {
      setNurseries(Nurseries.data);
    }
    if (TreeSpecies) {
      setTreeSpecies(TreeSpecies.data);
    }
  }, [Nurseries, TreeSpecies, communitiesRes]);

  return (
    <div className="parent-container">
      <h1 className="font-semibold text-default-text text-[16px] mt-5">
        {t("Tagging tool.Generate new tags")}
      </h1>
      <BreadCrumb
        breadcrumb={[
          { name: t("Import register.Tools"), url: "" },
          { name: t("Tagging tool.Tagging tool"), url: "/tools/tagging-tool" },
          { name: t("Tagging tool.Generate new tags"), url: "" },
        ]}
      />
      <div className="flex flex-wrap justify-between mt-4">
        <div className=" w-full h-fit lg:w-[49%] sc:w-[611px] bg-white rounded-md p-7 relative">
          <AddTaggingToolForm
            send={fetchData}
            sendResponse={response}
            selectOptions={selectOption}
            nurseries={nurseries}
            treeSpecies={treeSpecies}
          />
        </div>
        <div className="w-full h-fit lg:w-[48%] sc:w-[559px] bg-white rounded-md p-8 mt-4 lg:mt-0">
          <img
            src="/img/Generate new tags.svg"
            alt="Add scientific name"
            className="mt-4 ml-2 xl:ml-10"
          />
        </div>
      </div>
    </div>
  );
};

export default AddTaggingTool;
