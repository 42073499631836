import { Fragment, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";

const AddDistributeButton: React.FC<{
  update: boolean;
  setAction: (action: string) => void;
  isDirty: (dirty: boolean) => void;
}> = ({ update, setAction, isDirty }) => {
  const [showButton, isShowButton] = useState<boolean>(false);
  const { t } = useTranslation();
  const divRef = useRef<HTMLDivElement>(null);
  const btnRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    document.addEventListener("click", handelClick, true);
  }, []);
  const handelClick = (e: any) => {
    if (
      !divRef.current?.contains(e.target) &&
      !btnRef.current?.contains(e.target)
    ) {
      isShowButton(false);
    }
  };
  return (
    <Fragment>
      {update ? (
        <button
          type="submit"
          className="green-btn text-sm  px-6 py-2 mt-5 float-right"
          onClick={() => setAction("update")}
        >
          {t("Tree Species.Update")}
        </button>
      ) : (
        <div className="relative mt-5 text-sm">
          <button
            ref={btnRef}
            className="green-btn-disable green-btn w-fit px-4 float-right py-2 font-medium"
            type="button"
            onClick={() => {
              isShowButton(!showButton);
            }}
          >
            {t("Register.Add")}
            <FontAwesomeIcon
              className="text-base ml-6"
              icon={showButton ? faAngleUp : faAngleDown}
            />
          </button>

          {showButton && (
            <div
              ref={divRef}
              className="absolute bottom-0 right-0 w-[226px] text-default-text p-4 shadow-xl rounded-md bg-white text-left"
            >
              <button
                type="submit"
                className="hover:text-ph-hover pb-2 font-medium"
                onClick={() => {
                  setAction("add");
                  isDirty(false);
                }}
              >
                {t("Tree inventory.Add distribution")}
              </button>
              <button
                type="submit"
                className="hover:text-ph-hover pt-2 text-left font-medium"
                onClick={() => setAction("add more")}
              >
                {t("Tree inventory.Save & add more")}
              </button>
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default AddDistributeButton;
