import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import useAxios from "../../../hook/useAxios";
import BreadCrumb from "../../Global/BreadCrumb";
import { responseFile } from "../../../type/responseFile";
import TranslationField from "../../../type/translationField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBolt,
  faCircleCheck,
  faCircleXmark,
  faInfoCircle,
  faUserShield,
  faCircleLocationArrow,
  faCompass,
  faHandHoldingSeedling,
  faMapLocationDot,
  faQrcode,
  faRectangleList,
  faRulerCombined,
  faRulerVertical,
  faSeedling,
  faTag,
  faTree,
} from "@fortawesome/pro-light-svg-icons";
import OldFile from "../../Global/OldFile";
import EditSlider from "../../Global/EditSlider";
import Loading from "../../Global/Loading";
import {} from "@fortawesome/pro-regular-svg-icons";
import GeneralDetails from "../../Global/GeneralDetails";
import { Nursery } from "../../../type/Nursery";

type FormValues = {
  slug: string;
  species_name_transes: TranslationField;
  incidents: {
    name_transes: TranslationField;
    slug: string;
    featured_icon: string;
  }[];
  first_height: number;
  last_height: number;
  last_diameter: number;
  current_age_year: number;
  current_age_month: number;
  age_year: number;
  age_month: number;
  first_diameter: number;
  nursery_name_transes: TranslationField;
  tag: string;
  is_replanting: boolean;
  responsible_category: string;
  responsible_type_name: string;
  responsible_name: string;
  treeImage: string;
  latitude: number;
  longitude: number;
  files: responseFile[];
  brief_description_transes: TranslationField;
};

const ViewRegister = () => {
  const [register, setRegister] = useState<FormValues>({
    slug: "",
    species_name_transes: { en: "", es: "" },
    incidents: [],
    first_height: 0,
    current_age_month: 0,
    current_age_year: 0,
    first_diameter: 0,
    last_diameter: 0,
    last_height: 0,
    age_month: 0,
    age_year: 0,
    nursery_name_transes: { en: "", es: "" },
    tag: "",
    is_replanting: false,
    responsible_category: "",
    responsible_type_name: "",
    responsible_name: "",
    treeImage: "",
    latitude: 0.0,
    longitude: 0.0,
    files: [
      {
        id: 0,
        file_address: "",
        thumbnail_address: "",
        is_image: false,
      },
    ],
    brief_description_transes: { en: "", es: "" },
  });
  const [treeImage, setTreeImage] = useState<string>("");
  const { i18n, t } = useTranslation();
  const { id } = useParams<any>();

  const {
    response: getEditResponse,
    fetchData: getRegister,
    loading,
  } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/registers/${id}/edit/`,
    "GET",
    false,
    "",
    true,
    true
  );

  const { fetchData: getEventId, response: breadCrumb } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/plantings/${getEditResponse?.data?.planting_slug}/breadcrumb-data/?register_slug=${id}`,
    "GET",
    false,
    "",
    false,
    false
  );

  useEffect(() => {
    if (getEditResponse) {
      setRegister(getEditResponse.data);
      setTreeImage(getEditResponse.data.planted_tree_image);
      let files: responseFile[] = [...getEditResponse.data.files];
      files.push({
        file_address: getEditResponse.data.planted_tree_image,
        id: 1,
        is_image: true,
        thumbnail_address: getEditResponse.data.planted_tree_image,
      });
    }
  }, [getEditResponse]);

  useEffect(() => {
    getRegister({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getEditResponse) {
      getEventId({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEditResponse]);

  const getSpeciesName = (species: TranslationField, language: string) => {
    if (language === "en" && species.en) {
      return species.en;
    }
    return species.es;
  };

  const getNurseryName = (nursery: TranslationField, language: string) => {
    if (language === "en" && nursery.en) {
      return nursery.en;
    }
    return nursery.es;
  };

  const getIncidentName = (incident: Nursery, language: string) => {
    if (language === "en" && incident.name_transes.en) {
      return incident.name_transes.en;
    }
    return incident.name_transes.es;
  };

  const Incidents = () => {
    if (register.incidents.length === 0) return null;

    return (
      <div className="flex flex-row h-fit items-start text-left mt-[30px] md:mt-0 mb-7 md:mb-0">
        <FontAwesomeIcon
          className="text-ph-gray w-[25px] h-[20px] md:my-[11px] mr-[16px]"
          icon={faBolt}
        />
        <div>
          <p className="text-sm text-ph-gray w-[89px]">
            {t("Incidents.Incidents")}
          </p>
          {register.incidents.map((incident, index) => {
            const incidentName = getIncidentName(incident, i18n.language);
            const isLongName = incidentName.length > 17;
            const containerClass = isLongName
              ? "w-[180px] mr-[45px]"
              : "w-[120px] mr-[105px]";

            return (
              <div
                key={incident.slug}
                className={`${containerClass} mt-[6px] pr-2 whitespace-nowrap text-default-text text-sm font-medium flex items-center`}
              >
                <p className="">
                  {incidentName}
                  {index !== register.incidents.length - 1 ? "," : ""}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const BriefDescription = () => {
    let description = "";
    if (i18n.language === "en") {
      if (register.brief_description_transes.en !== "") {
        description = register.brief_description_transes.en;
      } else {
        description = register.brief_description_transes.es || "-";
      }
    } else {
      description = register.brief_description_transes.es || "-";
    }

    if (!description) return null;

    return (
      <div className="flex justify-start m-[30px] flex-col border-[1px] border-ph-input border-opacity-100 p-4">
        <p className="text-sm font-regular text-ph-gray mb-2">
          {t("AddPlanting.Brief description")}
        </p>
        <p className="text-sm font-medium text-default-text max-w-[300px] md:max-w-[500px] max-h-[400px] break-words overflow-y-auto">
          {description}
        </p>
      </div>
    );
  };

  return (
    <div className="parent-container overflow-x-hidden">
      <h1 className="font-semibold text-default-text text-[16px] mt-5">
        {t("Register.View register")}
      </h1>
      <BreadCrumb
        breadcrumb={[
          { name: t("Register.Register"), url: "/registers/all" },
          {
            name: `${breadCrumb?.data.date}-${
              breadCrumb?.data.department_name
            }-${
              i18n.language === "en"
                ? breadCrumb?.data.species_name_transes.en ||
                  breadCrumb?.data.species_name_transes.es
                : breadCrumb?.data.species_name_transes.es
            }-${breadCrumb?.data.unique_id}`,
            url: "",
          },
          { name: t("Register.View register"), url: "" },
        ]}
      />
      {loading ? (
        <Loading loading={true} />
      ) : (
        <div className="flex flex-wrap justify-between mt-4">
          <div className="w-full lg:w-[611px] h-fit sc:w-[620px] bg-white rounded-md relative">
            <div className="flex justify-start m-[30px] flex-col border-[1px] border-ph-input border-opacity-100 p-4">
              <div className="flex flex-col md:flex-row justify-start mb-4">
                <GeneralDetails
                  icon={faTree}
                  label={t("Register.Tree species")}
                  value={getSpeciesName(
                    register.species_name_transes,
                    i18n.language
                  )}
                  isLeft={true}
                />

                <GeneralDetails
                  icon={faMapLocationDot}
                  label={t("Tagging tool.Nursery")}
                  value={getNurseryName(
                    register.nursery_name_transes,
                    i18n.language
                  )}
                  isLeft={false}
                />
              </div>
              <div className="flex flex-col md:flex-row md:my-[40px]">
                {register.incidents.length > 0 && <Incidents />}
                <GeneralDetails
                  icon={faQrcode}
                  label={t("Tagging tool.Tag")}
                  value={register.tag}
                  isLeft={false}
                />
              </div>
              <div className={`flex flex-row items-start text-center `}>
                <FontAwesomeIcon
                  className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-[16px]"
                  icon={faHandHoldingSeedling}
                />
                <div className="flex h-[42px] flex-col items-start text-left">
                  <p className="text-sm text-ph-gray w-[89px]">
                    {t("Register.Replanting")}
                  </p>
                  <p className="text-sm text-default-text w-[15px] h-[15px] font-medium mt-[6px]">
                    {register.is_replanting ? (
                      <FontAwesomeIcon
                        className="opacity-100 text-ph-hover"
                        icon={faCircleCheck}
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="opacity-100 text-ph-red-warning"
                        icon={faCircleXmark}
                      />
                    )}
                  </p>
                </div>
              </div>
            </div>

            {(register.first_height ||
              register.first_diameter ||
              ((register.age_year !== 0 || register.age_month !== 0) &&
                (register.age_year !== null ||
                  register.age_month !== null))) && (
              <div className="flex justify-start m-[30px] flex-col border-[1px] border-ph-input border-opacity-100 p-4">
                <div>
                  <p className="text-sm font-semibold text-default-text py-3">
                    {t("Register.Planting details")}
                  </p>
                  <div className="flex md:flex-row flex-col justify-start mb-4 mt-[24px]">
                    {register.age_year > 0 || register.age_month > 0 ? (
                      <div className="flex">
                        <FontAwesomeIcon
                          className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-[16px]"
                          icon={faSeedling}
                        />
                        <div className="flex h-[42px] flex-col items-start text-left">
                          <p className="text-sm w-[27px] mr-[197px] font-regular text-ph-gray">
                            {t("Register.Age")}
                          </p>
                          {(register.age_year > 0 ||
                            register.age_month > 0) && (
                            <p className="text-sm font-medium text-default-text mt-[6px]">
                              {register.age_year && register.age_year !== 0
                                ? `${register.age_year} ${t("Register.Year")}`
                                : ""}
                              {register.age_year !== 0 &&
                              register.age_year &&
                              register.age_month &&
                              register.age_month !== 0
                                ? `,`
                                : ""}
                              {register.age_month && register.age_month !== 0
                                ? ` ${register.age_month} ${t(
                                    "Register.Month"
                                  )}`
                                : ""}
                            </p>
                          )}
                        </div>
                      </div>
                    ) : (
                      <p className="text-sm text-default-text font-medium"></p>
                    )}
                    {register.first_height > 0 ? (
                      <div className="flex mt-[40px] md:mt-0">
                        <FontAwesomeIcon
                          className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-[16px]"
                          icon={faRulerVertical}
                        />

                        <div className="flex h-[42px] flex-col items-start text-left">
                          <p className="text-sm font-regular text-ph-gray">
                            {t("Register.Tree height")}{" "}
                          </p>
                          <p className="text-sm font-medium text-default-text mt-[6px]">
                            {register.first_height} in "cm"
                          </p>
                        </div>
                      </div>
                    ) : (
                      <p className="text-sm text-default-text font-medium"></p>
                    )}
                  </div>

                  {register.first_diameter > 0 ? (
                    <div className="flex mt-[40px]">
                      <FontAwesomeIcon
                        className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-[16px]"
                        icon={faRulerCombined}
                      />

                      <div className="flex h-[42px] flex-col items-start text-left">
                        <p className="text-sm w-[89px] font-regular text-ph-gray">
                          {t("Register.Diameter")}{" "}
                        </p>
                        <p className="text-sm font-medium text-default-text mt-[6px]">
                          {register.first_diameter} in "mm"
                        </p>
                      </div>
                    </div>
                  ) : (
                    <p className="text-sm text-default-text font-medium"></p>
                  )}
                </div>
              </div>
            )}

            {(register.last_height ||
              register.last_diameter ||
              register.current_age_year !== 0 ||
              register.current_age_month !== 0) && (
              <div className="flex justify-start m-[30px] flex-col border-[1px] border-ph-input border-opacity-100 p-4">
                <div>
                  <p className="text-sm font-semibold text-default-text py-3">
                    {t("Register.Current details")}
                  </p>
                  <div className="flex flex-col md:flex-row justify-start mb-4 mt-[24px]">
                    {register.current_age_year ||
                    register.current_age_month > 0 ? (
                      <div className="flex">
                        <FontAwesomeIcon
                          className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-[16px]"
                          icon={faSeedling}
                        />
                        <div className="flex h-[42px] flex-col items-start text-left">
                          <p className="text-sm w-[27px] mr-[197px] font-regular text-ph-gray">
                            {t("Register.Age")}
                          </p>
                          {register.current_age_year > 0 ||
                          (register.current_age_year >= 0 &&
                            register.current_age_month > 0) ? (
                            <p className="text-sm font-medium text-default-text mt-[6px]">
                              {register.current_age_year > 0
                                ? ` ${register.current_age_year} ${t(
                                    "Register.Year"
                                  )}`
                                : ""}
                              {register.current_age_month > 0
                                ? `${
                                    register.current_age_year > 0 ? "," : ""
                                  } ${register.current_age_month} ${t(
                                    "Register.Month"
                                  )}`
                                : ""}
                            </p>
                          ) : register.current_age_year < 0 ? (
                            <span className="age-btn">
                              <FontAwesomeIcon
                                className=" w-[15px] h-[15px]"
                                icon={faInfoCircle}
                              />
                              <div className={`age-text shadow-2xl`}>
                                <p>
                                  {i18n.language === "en"
                                    ? "The age of the tree is wrong. Please check the planting date."
                                    : "La edad del árbol es incorrecta. Por favor verifique la fecha de siembra."}
                                </p>
                              </div>
                            </span>
                          ) : register.current_age_year === 0 ||
                            register.current_age_month === 0 ? (
                            "-"
                          ) : null}
                        </div>
                      </div>
                    ) : (
                      <p className="text-sm text-default-text font-medium"></p>
                    )}
                    {register.last_height > 0 ? (
                      <div className="flex mt-[40px] md:mt-0">
                        <FontAwesomeIcon
                          className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-[16px]"
                          icon={faRulerVertical}
                        />

                        <div className="flex h-[42px] flex-col items-start text-left">
                          <p className="text-sm font-regular text-ph-gray">
                            {t("Register.Tree height")}{" "}
                          </p>
                          <p className="text-sm font-medium text-default-text mt-[6px]">
                            {register.last_height} in "cm"
                          </p>
                        </div>
                      </div>
                    ) : (
                      <p className="text-sm text-default-text font-medium"></p>
                    )}
                  </div>

                  {register.last_diameter > 0 ? (
                    <div className="flex mt-[40px]">
                      <FontAwesomeIcon
                        className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-[16px]"
                        icon={faRulerCombined}
                      />

                      <div className="flex h-[42px] flex-col items-start text-left">
                        <p className="text-sm w-[89px] font-regular text-ph-gray">
                          {t("Register.Diameter")}{" "}
                        </p>
                        <p className="text-sm font-medium text-default-text mt-[6px]">
                          {register.last_diameter} in "mm"
                        </p>
                      </div>
                    </div>
                  ) : (
                    <p className="text-sm text-default-text font-medium"></p>
                  )}
                </div>
              </div>
            )}

            <div className="flex justify-start m-[30px] pb-[50px] flex-col border-[1px] border-ph-input border-opacity-100 p-4">
              <div className="flex flex-row justify-start mb-4 mt-[24px]">
                <div className="flex mr-[20px] md:mr-[140px]">
                  <FontAwesomeIcon
                    className="text-ph-gray font-bold w-[25px] h-[20px] my-[11px] mr-[16px]"
                    icon={faUserShield}
                  />

                  <div className="flex w-[82px] h-[42px] flex-col items-start text-left">
                    <p className="text-sm font-regular text-ph-gray">
                      {t("Register.Responsible")}
                    </p>
                    <p className="text-sm font-medium text-default-text mt-[6px]">
                      {register.responsible_name}
                    </p>
                  </div>
                </div>
                <div className="flex">
                  <FontAwesomeIcon
                    className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-[16px]"
                    icon={faTag}
                  />
                  <div className="flex h-[42px] flex-col items-start text-left">
                    <p className="text-sm font-regular text-ph-gray">
                      {t("Register.Category")}
                    </p>
                    <p className="text-sm font-medium text-default-text mt-[6px]">
                      {register.responsible_category === "ind"
                        ? t("Register.Individual")
                        : register.responsible_category === "grp"
                        ? t("Register.Group")
                        : register.responsible_category === "ins"
                        ? t("Register.Institution")
                        : "FUNDAEC"}
                    </p>
                  </div>
                </div>
              </div>
              {(register.responsible_type_name !== "" ||
                register.responsible_type_name) && (
                <div className="flex mt-[40px]">
                  <FontAwesomeIcon
                    className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-[16px]"
                    icon={faRectangleList}
                  />
                  <div className="flex h-[42px] flex-col items-start text-left">
                    <span>
                      <p className="text-sm font-regular text-ph-gray">
                        {t("Tree Species.Type")}
                      </p>
                      <p className="text-sm font-medium text-default-text mt-[6px]">
                        {register.responsible_type_name}
                      </p>
                    </span>
                  </div>
                </div>
              )}
            </div>

            {treeImage && (
              <div className="flex justify-start m-[30px] flex-col border-[1px] border-ph-input border-opacity-100 p-4">
                <div>
                  <p className="text-sm font-semibold text-default-text mb-[24px]">
                    {t("Register.Planted tree image")}
                  </p>
                  {treeImage && (
                    <OldFile
                      address={treeImage}
                      id={0}
                      delete={false}
                      removeOldFile={() => {}}
                    />
                  )}
                </div>
              </div>
            )}
            <div className="flex justify-start m-[30px] flex-col border-[1px] border-ph-input border-opacity-100 p-4">
              <div>
                <p className="text-sm font-semibold text-default-text py-3">
                  {t("PlantingFilter.Location")}
                </p>
                <div className="flex flex-col  md:flex-row justify-start mb-4 mt-[24px]">
                  <div className="flex mb-[20px] md:mb-0 md:mr-[140px]">
                    <FontAwesomeIcon
                      className="text-ph-gray font-bold w-[25px] h-[20px] my-[11px] mr-[16px]"
                      icon={faCompass}
                    />

                    <div className="flex w-[82px] h-[42px] flex-col items-start text-left">
                      <p className="text-sm font-regular text-ph-gray">
                        {t("Register.Latitude")}
                      </p>
                      <p className="text-sm font-medium text-default-text mt-[6px]">
                        {register.latitude}
                      </p>
                    </div>
                  </div>
                  <div className="flex">
                    <FontAwesomeIcon
                      className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-[16px]"
                      icon={faCircleLocationArrow}
                    />

                    <div className="flex h-[42px] flex-col items-start text-left">
                      <p className="text-sm font-regular text-ph-gray">
                        {t("Register.Longitude")}
                      </p>
                      <p className="text-sm font-medium text-default-text mt-[6px]">
                        {register.longitude}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {register.files.length > 0 && (
              <div className="flex justify-start m-[30px] flex-col border-[1px] border-ph-input border-opacity-100 p-4">
                <div>
                  <p className="text-sm font-semibold text-default-text py-3 mb-[24px]">
                    {t("AddPlanting.Additional resources")}
                  </p>
                  {register.files.map((file: any) => (
                    <OldFile
                      address={file.file_address}
                      id={file.id}
                      delete={false}
                      removeOldFile={() => {}}
                      key={file.id}
                    />
                  ))}
                </div>
              </div>
            )}

            {(register.brief_description_transes.en !== "" ||
              register.brief_description_transes.es !== "") && (
              <BriefDescription />
            )}
            <Link
              to="/registers/all"
              className="green-btn text-sm  px-6 py-2 mt-5  mb-[40px] mr-[90px] md:mr-[55px] float-right font-medium"
            >
              {t("Register.Back to register")}
            </Link>
          </div>
          <EditSlider
            images={["/img/p1.svg", "/img/p2.svg"]}
            response={getEditResponse}
          />
        </div>
      )}
    </div>
  );
};

export default ViewRegister;
