/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react";
import useAxios from "../../../hook/useAxios";
import { Department } from "../../../type/department";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faInbox,
  faTree,
  faTrees,
  faUsers,
} from "@fortawesome/pro-light-svg-icons";
import { t } from "i18next";
import GenericTable from "../../Global/GenericTable";
import { LocationReport } from "../../../type/locationReport";
import { TableCell } from "@mui/material";
import i18n from "../../../i18n";
import { useSelector } from "react-redux";
import { reportPlantedURL } from "../../../store";

interface ReportFilter {
  dateTo: Date;
  dateFromReport: Date | null | "";
  level: string;
  department: Department[] | null;
  community: Department[] | null;
}

const ReportsTreesView: React.FC<{ reportData: ReportFilter }> = ({
  reportData,
}) => {
  const [page, setPage] = useState<number>(1);
  const [ordering, setOrdering] = useState<string>("");
  const [count, setCount] = useState<number>(0);
  const [dataList, setDataList] = useState<LocationReport[]>([]);
  const url = useSelector(reportPlantedURL);
  const { fetchData, response, loading } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/reports/planted_trees${url}&page=${page}&ordering=${ordering}`,
    "GET",
    true,
    "",
    true,
    false
  );

  useEffect(() => {
    if (response) {
      setCount(response.data.count);
      setDataList(response.data.results);
    }
  }, [response]);

  useEffect(() => {
    if (reportData.level === "N" && ordering === "community") {
      setOrdering("");
    }
  }, [reportData.level]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const order = (value: string) => {
    setOrdering(value);
  };
  return response?.data?.count !== 0 ? (
    <div className="flex flex-col p-6 bg-ph-white">
      <h1 className="font-semibold text-default-text text-[14px] mb-5">
        {t("Reports.Trees")}
      </h1>
      <span className="flex flex-col lg:flex-row mb-5 border-[1px] border-ph-input border-opacity-100 p-4">
        {reportData.level !== "N" && (
          <>
            {reportData.level === "D" ? (
              <FontAwesomeIcon
                className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-[16px]"
                icon={faBuilding}
              />
            ) : (
              <FontAwesomeIcon
                className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-[16px]"
                icon={faUsers}
              />
            )}
            <div className="mr-5 md:mr-[205px] mb-7 lg:mb-0">
              <p className="text-sm text-ph-gray">
                {reportData.level === "D"
                  ? t("Navbar.Departments")
                  : t("Community.Communities")}
              </p>
              <p className="text-sm text-default-text font-medium">
                {response?.data?.department_community_count}
              </p>
            </div>
          </>
        )}
        <FontAwesomeIcon
          className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-[16px]"
          icon={faTree}
        />
        <div className="mr-5 md:mr-[300px] mb-7 lg:mb-0">
          <p className="text-sm text-ph-gray">{t("Plantings.Species")}</p>
          <p className="text-sm text-default-text font-medium">
            {response?.data?.species_count}
          </p>
        </div>
        <FontAwesomeIcon
          className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-[16px]"
          icon={faTrees}
        />
        <div className="mr-5">
          <p className="text-sm text-ph-gray">{t("Reports.Trees")}</p>
          <p className="text-sm text-default-text font-medium">
            {response?.data?.trees_count}
          </p>
        </div>
      </span>
      <GenericTable
        countData={count}
        handleChange={handleChange}
        dataList={dataList}
        fetchData={() => {
          fetchData({});
        }}
        loading={loading}
        setOrderingList={order}
        showAction={false}
        titles={[
          reportData.level === "N"
            ? { name: " ", value: " " }
            : reportData.level === "D"
            ? { name: t("Plantings.Department"), value: "department" }
            : { name: t("Community.Community"), value: "community" },
          { name: t("Plantings.Species"), value: "species" },
          { name: t("Tree Species.Type"), value: "species_type" },
          { name: t("Reports.Trees"), value: "trees" },
        ]}
        renderItem={(departments) => (
          <Fragment>
            {reportData.level === "N" ? (
              <TableCell></TableCell>
            ) : (
              <TableCell align="left">
                {departments.department_community_name}
              </TableCell>
            )}
            <TableCell>
              {i18n.language === "en"
                ? departments.species_name_transes.en === ""
                  ? departments.species_name_transes.es
                  : departments.species_name_transes.en
                : departments.species_name_transes.es}
            </TableCell>
            <TableCell>
              {i18n.language === "en"
                ? departments.species_type_transes.en === ""
                  ? departments.species_type_transes.es
                  : departments.species_type_transes.en
                : departments.species_type_transes.es}
            </TableCell>
            <TableCell>{departments.trees_count}</TableCell>
            <TableCell></TableCell>
          </Fragment>
        )}
      />
    </div>
  ) : (
    <div className="parent-container">
      <p className="m-auto text-center font-medium text-2xl text-default-text">
        <FontAwesomeIcon className="mr-4" icon={faInbox} />
        {t("Message.No results found")}
      </p>
    </div>
  );
};

export default ReportsTreesView;
