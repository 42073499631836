import { Fragment } from "react";
import TableCell from "@mui/material/TableCell";
import { TreeSupplies } from "../../../type/treeSupplies";
import i18n from "../../../i18n";

const TreeSuppliesTableItem: React.FC<{
  supplies: TreeSupplies;
}> = ({ supplies }) => {
  return (
    <Fragment>
      <TableCell align="left" width="500px">
        {i18n.language === "en"
          ? supplies.species_name_transes?.en === ""
            ? supplies.species_name_transes?.es
            : supplies.species_name_transes?.en
          : supplies.species_name_transes?.es}
      </TableCell>
      <TableCell align="left" width="500px">
        {supplies.current_age}
      </TableCell>
      <TableCell align="left" width="200px">
        {supplies.available_trees}
      </TableCell>
    </Fragment>
  );
};

export default TreeSuppliesTableItem;
