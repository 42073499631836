/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, Fragment, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { ThemeProvider } from "@emotion/react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Modal,
  selectClasses,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BreadCrumb from "../Global/BreadCrumb";
import { plantingTheme } from "../Register/RegisterList/PlantingTheme";
import CustomAutocomplete from "../Global/CustomAutocomplete";
import CustomDate from "../Global/CustomDate";
import { Department } from "../../type/department";
import { TemplateType } from "../../type/templateType";
import SpeciesList from "../../type/speciesList";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { plantingMomentReportAction } from "../../store/plantingMomentReport-slice";
import {
  donorsSponsorsReportFilterSet,
  momentReportFilterSet,
  reportPlantedFilterSet,
} from "../../store";
import { toast } from "react-toastify";
import { reportPlantedFilterAction } from "../../store/reportPlantedFilter-slice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons";
import { colors } from "../../styles/colors";
import { donorsSponsorsReportFilterAction } from "../../store/donorsSponsorsReportFilter-slice";

type FormValues = {
  name: string;
  templateName: TemplateType;
  dateFromReport: Date | null;
  dateTo: Date;
  level: string;
  view: string;
  department: Department[] | null;
  community: Department[] | null;
  incidents?: SpeciesList[] | null;
  year: string;
};

interface ReportsGeneralProps {
  header: string;
  type: string;
  lists: {
    templateList: TemplateType[];
    departmentList: Department[];
    communityList?: Department[];
    incidentList: SpeciesList[];
    years?: string[];
  };
  onApplyFilter: (data: FormValues) => void;
  onSaveTemplate: (data: any) => void;
  templateResponse: any;
  templateList: any;
  exportResponse: () => void;
  filterData: any;
  chartPlantingData?: any;
  chartOccurrenceData?: any;
}

const ReportsGeneral: React.FC<ReportsGeneralProps> = ({
  header,
  lists,
  type,
  onApplyFilter,
  onSaveTemplate,
  templateResponse,
  templateList,
  exportResponse,
  filterData,
  chartPlantingData,
  chartOccurrenceData,
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const isMomentFilter = useSelector(momentReportFilterSet);
  const isPlantedFilter = useSelector(reportPlantedFilterSet);
  const isDonorsSponsorsFilter = useSelector(donorsSponsorsReportFilterSet);
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);

  const {
    handleSubmit,
    control,
    watch,
    clearErrors,
    getValues,
    trigger,
    register,
    setError,
    setValue,
    reset,
    resetField,
    formState: { errors },
  } = useForm<FormValues>({ mode: "onSubmit" });

  const dispatch = useDispatch();

  // watching field
  const levelItem = watch("level");
  const selectedYear = watch("year");
  const templateItem = watch("templateName");
  const { t, i18n } = useTranslation();
  // set value when select a template name

  const clearFilter = () => {
    if (type === "moments") {
      reset();
      dispatch(plantingMomentReportAction.clearFilter());
      setSelectedTemplate(null);
      dispatch(plantingMomentReportAction.clearFilter());
      dispatch(plantingMomentReportAction.setURL());
    }
    if (type === "planted") {
      reset();
      dispatch(reportPlantedFilterAction.clearFilter());
      setSelectedTemplate(null);
      dispatch(reportPlantedFilterAction.clearFilter());
      dispatch(reportPlantedFilterAction.setURL());
    }
    if (type === "sponsors") {
      reset();
      dispatch(donorsSponsorsReportFilterAction.clearFilter());
      setSelectedTemplate(null);
      dispatch(donorsSponsorsReportFilterAction.clearFilter());
      dispatch(donorsSponsorsReportFilterAction.setURL());
    }
  };
  const handleOpenModal = async () => {
    const isValid = await trigger(); // Triggers validation for all registered fields
    if (isValid) {
      setOpenModal(true); // Open modal only if the form is valid
    }
  };
  // Function to handle the form submit
  const onSubmit = async (data: FormValues) => {
    const isValid = await trigger([
      "level",
      "year",
      "view",
      "dateTo",
      "name",
      "community",
      "department",
    ]);
    let hasError = false;
    const dateT =
      !data.dateTo || !data.dateTo?.getDate()
        ? ""
        : `${data.dateTo.getFullYear()}-${
            data.dateTo.getMonth() + 1
          }-${data.dateTo.getDate()}`;
    const dateF =
      !data.dateFromReport || !data.dateFromReport?.getDate()
        ? ""
        : `${data.dateFromReport.getFullYear()}-${
            data.dateFromReport.getMonth() + 1
          }-${data.dateFromReport.getDate()}`;
    if (data.dateFromReport) {
      if (data.dateFromReport > data.dateTo) {
        setError("dateFromReport", {
          type: "custom",
          message: `${t(
            "Message.The start date must be smaller or equal than the end date."
          )}`,
        });
        hasError = true;
      }
    }
    if (!isValid || hasError) {
      return;
    }
    onApplyFilter(data);

    if (type === "moments") {
      dispatch(
        plantingMomentReportAction.setFilter({
          incidents: data?.incidents || [],
          departments: data?.department || [],
          level: `${data?.level || ""}`,
          year: `${data?.year || ""}`,
        })
      );
      dispatch(plantingMomentReportAction.setURL());
      dispatch(plantingMomentReportAction.isFilterSet());
    }
    if (type === "planted" && !hasError) {
      dispatch(
        reportPlantedFilterAction.setFilter({
          communities: data?.community || [],
          departments: data?.department || [],
          level: `${data?.level || ""}`,
          date_to: dateT,
          date_from: dateF || null,
          view_by: `${data.view || ""}`,
        })
      );
      dispatch(reportPlantedFilterAction.setURL());
      dispatch(reportPlantedFilterAction.isFilterSet());
    }
    if (type === "sponsors" && !hasError) {
      dispatch(
        donorsSponsorsReportFilterAction.setFilter({
          communities: data?.community || [],
          departments: data?.department || [],
          level: `${data?.level || ""}`,
          date_to: dateT,
          date_from: dateF || null,
          view_by: `${data.view || ""}`,
        })
      );
      dispatch(donorsSponsorsReportFilterAction.setURL());
      dispatch(donorsSponsorsReportFilterAction.isFilterSet());
    }
  };

  const getYearRange = (
    date: string
  ): { from_date: string; to_date: string } => {
    const year = parseInt(date, 10);

    const fromDate = new Date(year, 0, 1); // January 1st

    const toDate = new Date(year, 11, 31); // December 31st

    const from_date = `${fromDate.getDate().toString().padStart(2, "0")}/${(
      fromDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${fromDate.getFullYear()}`;

    const to_date = `${toDate.getDate().toString().padStart(2, "0")}/${(
      toDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${toDate.getFullYear()}`;

    return { from_date, to_date };
  };

  const extractYear = (date: string): string => {
    const parts = date.split("/");

    return parts[2];
  };

  const { from_date, to_date } = getYearRange(selectedYear);

  // handle save button clicked
  const handleSaveTemplate = async () => {
    const isValid = await trigger([
      "level",
      "year",
      "view",
      "dateTo",
      "name",
      "dateFromReport",
      "community",
      "department",
    ]);
    if (isValid) {
      const enteredName = getValues("name");
      const dateTo = getValues("dateTo");
      const dateFromReport = getValues("dateFromReport");
      const department = getValues("department");
      const incident = getValues("incidents");
      const community = getValues("community");

      const duplicateTemplate = lists.templateList.find(
        (template) => template.name === enteredName
      );

      if (duplicateTemplate) {
        toast.error(
          t(
            "Message.Template name already exists. Please choose a unique name."
          )
        );
        return;
      }

      const dateT =
        !dateTo || !dateTo?.getDate()
          ? ""
          : `${dateTo.getDate()}/${
              dateTo.getMonth() + 1
            }/${dateTo.getFullYear()}`;
      const dateF =
        !dateFromReport || !dateFromReport?.getDate()
          ? ""
          : `${dateFromReport.getDate()}/${
              dateFromReport.getMonth() + 1
            }/${dateFromReport.getFullYear()}`;

      if (dateFromReport) {
        if (dateFromReport > dateTo) {
          setError("dateFromReport", {
            type: "custom",
            message: `${t(
              "Message.The start date must be smaller or equal than the end date."
            )}`,
          });
        }
      }

      const formData = {
        name: enteredName,
        type: type === "planted" ? "PT" : type === "moments" ? "PM" : "DS",
        view_by: type === "moments" ? null : getValues("view") || "",
        level: getValues("level"),
        date_from: type === "moments" ? from_date : dateF ? dateF : null,
        date_to: type === "moments" ? to_date : dateT,
        is_department_all:
          department?.length === 1 && department[0].slug === "all"
            ? true
            : false,
        departments:
          (department?.length === 1 && department[0].slug === "all") ||
          !department
            ? []
            : department.map((dep) => dep.slug),
        is_incident_all:
          incident?.length === 1 && incident[0].slug === "all" ? true : false,
        incidents:
          (incident?.length === 1 && incident[0].slug === "all") || !incident
            ? []
            : incident.map((dep) => dep.slug),
        is_community_all:
          community?.length === 1 && community[0].slug === "all" ? true : false,
        communities:
          (community?.length === 1 && community[0].slug === "all") || !community
            ? []
            : community.map((dep) => dep.slug),
      };
      onSaveTemplate(formData);
      setOpenModal(false);
      resetField("name");
      setValue("templateName", formData);
    }
  };
  useEffect(() => {
    if (templateResponse) {
      toast.success("The template saved successfully.");
      templateList({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateResponse]);

  useEffect(() => {
    if (templateItem && lists.templateList?.length > 0) {
      const selectedTemp = lists.templateList.find(
        (item) => item.name === templateItem.name
      );
      setSelectedTemplate(selectedTemp);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [templateItem]);

  useEffect(() => {
    if (selectedTemplate) {
      setValue("level", "");
      setValue("view", "");
      if (type === "moments") {
        setValue("year", "");
      }
      if (type !== "moments") {
        setValue("dateFromReport", new Date());
        setValue("dateTo", new Date());
      }
      setValue("department", []);
      setValue("community", []);
      setValue("incidents", []);
      const dateF: string[] = selectedTemplate.date_from?.split("/");
      const dateT: string[] = selectedTemplate.date_to.split("/");
      setValue("level", selectedTemplate.level);
      setValue("view", selectedTemplate.view_by);
      if (type === "moments") {
        setValue("year", extractYear(selectedTemplate?.date_from));
      }
      if (type !== "moments") {
        setValue(
          "dateFromReport",
          selectedTemplate?.date_from
            ? new Date(Number(dateF[2]), Number(dateF[1]) - 1, Number(dateF[0]))
            : null
        );
        setValue(
          "dateTo",
          new Date(Number(dateT[2]), Number(dateT[1]) - 1, Number(dateT[0]))
        );
      }
      setValue(
        "department",
        selectedTemplate.is_department_all
          ? [{ name: "All", slug: "all" }]
          : selectedTemplate.departments?.length
          ? selectedTemplate.departments.map((dep: any) => ({
              slug: dep.slug,
              name: dep.name,
            }))
          : []
      );
      const communityInitials: Department[] = selectedTemplate.communities
        ?.length
        ? selectedTemplate.communities.map((community: any) => {
            return {
              name: community.name + ` (${community.initials})`, // Concatenate name and initials
              slug: community.slug,
            };
          })
        : [];
      setValue(
        "community",
        selectedTemplate.is_community_all
          ? [{ name: "All", slug: "all" }]
          : communityInitials
      );
      setValue(
        "incidents",
        selectedTemplate.is_incident_all
          ? [{ name_transes: { en: "All", es: "Todos" }, slug: "all" }]
          : selectedTemplate.incidents?.length
          ? selectedTemplate.incidents.map((inc: any) => ({
              slug: inc.slug,
              name_transes: inc.name_transes,
            }))
          : []
      );
    }
  }, [selectedTemplate]);

  const handleTemplateChange = (selectedOption: any) => {
    setSelectedTemplate(null);
    setTimeout(() => {
      setSelectedTemplate(selectedOption); // Set to the selected template
    }, 0);
  };
  const handleExportClick = () => {
    if (
      filterData === null ||
      (type === "moments" &&
        (!chartOccurrenceData || chartOccurrenceData.datasets.length === 0) &&
        (!chartPlantingData || chartPlantingData.datasets.length === 0))
    ) {
      toast.error(`${t("No data available to export")}`);
    } else {
      exportResponse();
    }
  };

  return (
    <div className="parent-container">
      <div className="flex justify-between items-center">
        <span className="flex flex-col">
          <h1 className="font-semibold text-default-text text-[16px] mt-5">
            {header}
          </h1>
          <BreadCrumb
            breadcrumb={[
              { name: t("Reports.Reports"), url: "" },
              { name: header, url: "" },
            ]}
          />
        </span>
        <span
          className="cursor-pointer text-ph-btn"
          onClick={handleExportClick}
        >
          <FontAwesomeIcon
            icon={faFileExport}
            className="w-5 h-[18px]"
            style={{ color: colors.green_checkbox }}
          />
        </span>
      </div>
      <ThemeProvider theme={plantingTheme}>
        <div className="w-full h-fit bg-white p-4 rounded-md relative mt-3">
          <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
            <div className="w-full sm:w-[80%] md:w-[65%] lg:w-[31%]">
              <CustomAutocomplete
                control={control}
                name="templateName"
                selectOptions={lists.templateList}
                placeholder={t("Reports.Template name")}
                getOptionLabel={(option) => option.name}
                onCustomChange={(selectedItem) => {
                  handleTemplateChange(selectedItem);
                }}
              />
            </div>
            <button
              className="text-sm text-ph-btn font-medium mt-7 md:mt-0"
              onClick={handleOpenModal}
            >
              {t("Reports.Save as template")}
            </button>
          </div>
        </div>
        <div className="w-full h-fit bg-white p-4 rounded-md relative mt-1">
          {openModal && (
            <Modal
              open={openModal}
              onClose={() => setOpenModal(false)}
              className="w-full h-full z-[9990] bg-ph-gray bg-opacity-70 flex items-center justify-center fixed top-0 left-0 overflow-scroll box-border"
            >
              <div className="w-[492px] h-[320px] bg-white p-8 rounded-md shadow-md flex flex-col">
                <h2 className="font-semibold text-default-text text-[16px] mt-5">
                  {t("Reports.Save template")}
                </h2>
                <form>
                  <div className="w-full justify-between mt-7 items-center">
                    <p className="text-sm text-default-text mb-[6px]">
                      {t("Reports.Enter template name")} *
                    </p>
                    <input
                      {...register("name", {
                        required: `${t("Message.Required field")}`,
                      })}
                      type="text"
                      placeholder={t("Reports.Template name") + ""}
                      className="input-field text-sm pl-4 py-3 placeholder-ph-light-gray"
                    />
                    {errors.name && (
                      <p className="error-text">
                        {t("Message.Required field")}
                      </p>
                    )}
                    <div className="flex justify-end mt-4">
                      <button
                        onClick={() => {
                          setOpenModal(false);
                        }}
                        type="button"
                        className="text-ph-dark-gray-text mr-4 float-right text-sm mt-7 rounded-3xl px-6 py-2 hover:bg-ph-input font-medium"
                      >
                        {t("Message.Cancel")}
                      </button>
                      <button
                        onClick={handleSubmit(handleSaveTemplate)}
                        className="bg-ph-btn float-right text-sm mt-7 text-white rounded-3xl px-6 py-2 hover:bg-ph-hover font-medium"
                      >
                        {t("Profile.Save")}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Modal>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-wrap justify-between w-full sm:w-[80%] md:w-[65%] items-start">
              {type === "planted" || type === "sponsors" ? (
                <div className="w-full lg:w-[48%]">
                  <p className="text-sm text-default-text mb-[6px]">
                    {t("Reports.View by")} *
                  </p>
                  {type === "planted" ? (
                    <>
                      <Controller
                        control={control}
                        name="view"
                        rules={{ required: `${t("Message.Required field")}` }}
                        render={({ field: { onChange, value } }) => (
                          <FormControl style={{ width: "100%" }}>
                            <InputLabel shrink={false}>
                              {!value && `${t("Reports.View by")}`}
                            </InputLabel>
                            <Select
                              value={value || ""}
                              onChange={(e) => {
                                onChange(e.target.value);
                                resetField("templateName");
                              }}
                              IconComponent={ExpandMoreIcon}
                              variant="outlined"
                              sx={{
                                border: "1px solid #eeecec",
                                borderRadius: "4px",
                                "& fieldset": { border: "none" },
                              }}
                            >
                              <MenuItem value="TR">
                                {t("Reports.Trees")}
                              </MenuItem>
                              <MenuItem value="LO">
                                {t("Reports.Location")}
                              </MenuItem>
                              <MenuItem value="PR">
                                {t("Reports.Participants & responsible")}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        )}
                      />
                      {errors.view && (
                        <p className="error-text">
                          {t("Message.Required field")}
                        </p>
                      )}
                    </>
                  ) : (
                    <>
                      <Controller
                        control={control}
                        rules={{ required: `${t("Message.Required field")}` }}
                        name="view"
                        render={({ field: { onChange, value } }) => (
                          <FormControl style={{ width: "100%" }}>
                            <InputLabel shrink={false}>
                              {!value && `${t("Reports.View by")}`}
                            </InputLabel>
                            <Select
                              value={value || ""}
                              onChange={(e) => {
                                onChange(e.target.value);
                                resetField("templateName");
                              }}
                              IconComponent={ExpandMoreIcon}
                              variant="outlined"
                              sx={{
                                border: "1px solid #eeecec",
                                borderRadius: "4px",
                                "& fieldset": { border: "none" },
                              }}
                            >
                              <MenuItem value="TR">
                                {t("Reports.Trees")}
                              </MenuItem>
                              <MenuItem value="TP">
                                {t("Reports.Training participants")}
                              </MenuItem>
                              <MenuItem value="PO">
                                {t(
                                  "Reports.Planting participants & organizers"
                                )}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        )}
                      />
                      {errors.view && (
                        <p className="error-text">
                          {t("Message.Required field")}
                        </p>
                      )}
                    </>
                  )}
                </div>
              ) : (
                <div className="w-full lg:w-[48%]">
                  <p className="text-sm text-default-text mb-[6px]">
                    {t("Incidents.Incidents")} *
                  </p>
                  <CustomAutocomplete
                    rules={{
                      required: `${t("Message.Required field")}`,
                      validate: (value: any) => {
                        if (!value || value.slug === "") {
                          return `${t("Message.Required field")}`;
                        }
                        return true;
                      },
                    }}
                    control={control}
                    isMulti={true}
                    onCustomChange={() => {
                      clearErrors("incidents");
                      resetField("templateName");
                    }}
                    name="incidents"
                    selectOptions={lists.incidentList}
                    placeholder={t("Incidents.Incidents")}
                    getOptionLabel={(option) =>
                      i18n.language === "en"
                        ? option.name_transes?.en === ""
                          ? option.name_transes?.es
                          : option.name_transes?.en
                        : option.name_transes?.es
                    }
                    allValue={{
                      name_transes: { en: "All", es: "Todos" },
                      slug: "all",
                    }}
                    allSelectDefault={
                      selectedTemplate?.is_incident_all || false
                    }
                  />
                  {errors.incidents ? (
                    <p className="error-text">{t("Message.Required field")}</p>
                  ) : (
                    <p className="error-text">&nbsp;</p>
                  )}
                </div>
              )}

              <div className="w-full lg:w-[48%]">
                {type === "moments" ? (
                  <>
                    <p className="text-sm text-default-text mb-[6px]">
                      {t("Register.Year")} *
                    </p>
                    <Controller
                      rules={{ required: `${t("Message.Required field")}` }}
                      control={control}
                      name="year"
                      render={({ field: { onChange, value } }) => (
                        <FormControl style={{ width: "100%" }}>
                          <InputLabel shrink={false}>
                            {(selectedYear && selectedYear === "") ||
                            selectedYear === undefined
                              ? t("Register.Year")
                              : ""}
                          </InputLabel>
                          <Select
                            style={{
                              height: "40px",
                              width: "100%",
                              backgroundColor: "#f6f6f6",
                            }}
                            value={value || ""}
                            onChange={(e) => {
                              onChange(e.target.value);
                              clearErrors("year");
                              resetField("templateName");
                            }}
                            IconComponent={ExpandMoreIcon}
                            variant="outlined"
                            sx={{
                              [`& .${selectClasses.iconOpen}`]: {
                                transform: "none",
                              },
                              border: "1px solid #eeecec",
                              borderRadius: "4px",
                              "& fieldset": { border: "none" },
                            }}
                          >
                            {lists.years &&
                              lists.years?.map((year: string) => (
                                <MenuItem value={year}>{year}</MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                    {errors.year ? (
                      <p className="error-text">
                        {t("Message.Required field")}
                      </p>
                    ) : (
                      <p className="error-text">&nbsp;</p>
                    )}
                  </>
                ) : (
                  <>
                    <p className="text-sm text-default-text mb-[6px] mt-7 lg:mt-0">
                      {t("Workshops.Date")} *
                    </p>
                    <div className="flex items-center">
                      <CustomDate
                        clearError={clearErrors}
                        control={control}
                        name="dateFromReport"
                        label={t("PlantingFilter.From")}
                        onDateChange={() => resetField("templateName")}
                      />

                      <span className="px-2 text-default-text text-xs">
                        &#9866;
                      </span>

                      <CustomDate
                        clearError={clearErrors}
                        rules={{ required: `${t("Message.Required field")}` }}
                        control={control}
                        name="dateTo"
                        label={t("PlantingFilter.To")}
                        onDateChange={() => resetField("templateName")}
                      />
                    </div>
                    {errors.dateTo && (
                      <p className="error-text">
                        {t("Message.End date required")}
                      </p>
                    )}
                    {errors?.dateFromReport && (
                      <p className="error-text">
                        {t(
                          "Message.The start date must be smaller or equal than the end date."
                        )}
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="flex flex-wrap justify-between w-full sm:w-[80%] md:w-[65%] items-start">
              <div className="w-full lg:w-[48%] mt-7">
                <p className="text-sm text-default-text mb-[6px]">
                  {t("Reports.Level")} *
                </p>
                {type !== "moments" ? (
                  <Controller
                    control={control}
                    rules={{ required: `${t("Message.Required field")}` }}
                    name="level"
                    render={({ field: { onChange, value } }) => (
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel shrink={false}>
                          {!value && `${t("Reports.Level")}`}
                        </InputLabel>
                        <Select
                          style={{
                            height: "38px",
                          }}
                          IconComponent={ExpandMoreIcon}
                          variant="outlined"
                          value={value || null}
                          onChange={(e) => {
                            onChange(e as ChangeEvent<Element>);
                            if (e.target.value !== "D") {
                              setValue("department", []);
                            }
                            if (e.target.value !== "L") {
                              setValue("community", []);
                            }
                            resetField("templateName");
                          }}
                          sx={{
                            border: "1px solid #eeecec",
                            borderRadius: "4px",
                            "& fieldset": { border: "none" },
                          }}
                        >
                          <MenuItem value="N">{t("Reports.National")}</MenuItem>
                          <MenuItem value="D">
                            {t("Reports.Departmental")}
                          </MenuItem>
                          <MenuItem value="L">{t("Reports.Local")}</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                ) : (
                  <Controller
                    control={control}
                    rules={{ required: `${t("Message.Required field")}` }}
                    name="level"
                    render={({ field: { onChange, value } }) => (
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel shrink={false}>
                          {!value && `${t("Reports.Level")}`}
                        </InputLabel>
                        <Select
                          style={{
                            height: "38px",
                          }}
                          IconComponent={ExpandMoreIcon}
                          variant="outlined"
                          value={value || null}
                          onChange={(e) => {
                            onChange(e as ChangeEvent<Element>);
                            clearErrors("level");
                            resetField("templateName");
                          }}
                          sx={{
                            border: "1px solid #eeecec",
                            borderRadius: "4px",
                            "& fieldset": { border: "none" },
                          }}
                        >
                          <MenuItem value="N">{t("Reports.National")}</MenuItem>
                          <MenuItem value="D">
                            {t("Reports.Departmental")}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                )}
                {errors.level && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>

              <div className="w-full lg:w-[48%] mt-7">
                {levelItem === "D" && (
                  <Fragment>
                    <p className="text-sm text-default-text mb-[6px]">
                      {t("PlantingFilter.Department")} *
                    </p>
                    <CustomAutocomplete
                      rules={{
                        required: `${t("Message.Required field")}`,
                      }}
                      control={control}
                      name="department"
                      isMulti={true}
                      onCustomChange={() => {
                        clearErrors("department");
                        resetField("templateName");
                      }}
                      selectOptions={lists.departmentList!!}
                      placeholder={t("PlantingFilter.Department")}
                      getOptionLabel={(option) => {
                        if (option.slug === "all") {
                          const label =
                            i18n.language === "en" ? "All" : "Todos";
                          return label;
                        } else {
                          return option.name;
                        }
                      }}
                      allValue={{ name: "All", slug: "all" }}
                      allSelectDefault={
                        selectedTemplate?.is_department_all || false
                      }
                    />
                    {errors.department && (
                      <p className="error-text">
                        {t("Message.Required field")}
                      </p>
                    )}
                  </Fragment>
                )}

                {levelItem === "L" && (
                  <Fragment>
                    <p className="text-sm text-default-text mb-[6px]">
                      {t("Plantings.Community")} *
                    </p>
                    <CustomAutocomplete
                      rules={{
                        required: `${t("Message.Required field")}`,
                      }}
                      control={control}
                      name="community"
                      isMulti={true}
                      onCustomChange={() => {
                        clearErrors("community");
                        resetField("templateName");
                      }}
                      selectOptions={lists.communityList!!}
                      placeholder={t("Plantings.Community")}
                      getOptionLabel={(option) => {
                        if (option.slug === "all") {
                          const label =
                            i18n.language === "en" ? "All" : "Todos";
                          return label;
                        } else {
                          return option.name;
                        }
                      }}
                      allValue={{ name: "All", slug: "all" }}
                      allSelectDefault={
                        selectedTemplate?.is_community_all || false
                      }
                    />
                    {errors.community && (
                      <p className="error-text">
                        {t("Message.Required field")}
                      </p>
                    )}
                  </Fragment>
                )}
              </div>
            </div>
            <div className="mt-5 sm:mt-0 sm:absolute sm:right-4 sm:bottom-4">
              {(isMomentFilter ||
                isPlantedFilter ||
                isDonorsSponsorsFilter) && (
                <button
                  className="py-2 px-6 text-sm font-medium rounded-3xl text-default-text hover:bg-ph-input mr-3"
                  type="button"
                  onClick={clearFilter}
                >
                  {t("PlantingFilter.Reset")}
                </button>
              )}
              <button
                type="submit"
                className="white-btn border border-ph-btn text-sm font-medium py-2 px-6"
              >
                {t("PlantingFilter.Apply")}
              </button>
            </div>
          </form>
        </div>
      </ThemeProvider>
    </div>
  );
};

export default ReportsGeneral;
