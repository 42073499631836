import PaginationItem from "@mui/material/PaginationItem";
import Pagination from "@mui/material/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesLeft, faAnglesRight } from "@fortawesome/pro-solid-svg-icons";

const FirstButton = (props: any) => (
  <button {...props}>
    <FontAwesomeIcon
      className="text-ph-gray w-[12px] h-[15px]"
      icon={faAnglesLeft}
    />
  </button>
);

const LastButton = (props: any) => (
  <button {...props}>
    <FontAwesomeIcon
      className="text-ph-gray w-[12px] h-[15px]"
      icon={faAnglesRight}
    />
  </button>
);

const TablePagination: React.FC<{
  handleChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  count: number;
}> = ({ handleChange, count }, props) => {
  return (
    <Pagination
      color="primary"
      onChange={handleChange}
      count={count}
      siblingCount={0}
      showFirstButton
      showLastButton
      shape="rounded"
      renderItem={(item) => (
        <PaginationItem
          components={{
            first: FirstButton,
            last: LastButton,
          }}
          {...item}
        />
      )}
    />
  );
};

export default TablePagination;
