import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { profileInfo, workshopFilterSet, workshopURL } from "../../../store";
import WorkshopFilterBox from "./WorkshopFilterBox";
import { faGrid2, faListUl } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WorkshopAppliedFilter from "./WorkshopAppliedFilter";
import { Workshops } from "../../../type/workshops";
import useAxios from "../../../hook/useAxios";
import WorkshopGrid from "./WorkshopGrid";
import { TableThem } from "../../../styles/TableThem";
import { ThemeProvider } from "@emotion/react";
import TablePagination from "../../Global/TablePagination";
import Export from "../../ExportLists/Export";
import SearchField from "../../Global/SearchField";
import Loading from "../../Global/Loading";
import GenericTable from "../../Global/GenericTable";
import WorkshopTableItem from "./WorkshopTableItem";

const Workshop = () => {
  const [search, setSearch] = useState<string>("");
  const [sendSearch, setSendSearch] = useState<string>("");
  const [gridView, isGridView] = useState<boolean>(true);
  const [workshopList, setWorkshopList] = useState<Workshops[]>([]);
  const [page, setPage] = useState<number>(1);
  const [ordering, setOrdering] = useState<string>("");
  const [count, setCount] = useState<number>(0);
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const profile = useSelector(profileInfo);
  const isFilter = useSelector(workshopFilterSet);
  const url = useSelector(workshopURL);
  const { t } = useTranslation();

  const { fetchData, response, loading } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/workshop${url}&page=${page}&search=${sendSearch}&ordering=${ordering}`,
    "GET",
    true,
    "",
    true,
    false
  );

  useEffect(() => {
    if (loading && workshopList.length > 0) {
      setIsLoaded(false);
    } else {
      setIsLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (response) {
      setCount(response.data.count);
      setWorkshopList(response.data.results);
    }
  }, [response]);

  useEffect(() => {
    setPage(1);
  }, [url, sendSearch]);

  const applySearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSendSearch(search);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const order = (value: string) => {
    setOrdering(value);
  };

  const handleLoading = () => {
    setExportLoading(!exportLoading);
  };

  return (
    <div className="parent-container">
      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-default-text text-[16px] mt-5">
          {t("Workshops.Workshops")}
        </h1>
        <div>
          <Export
           url={`${url}&search=${sendSearch}&ordering=${ordering}`}
            name="workshop"
            title={t("Workshops.Export workshops") + ""}
            handleLoading={handleLoading}
            needBorder={true}
          />
          {(profile.role === "A" || profile.role === "C") && (
            <Link
              to="/workshops/add-workshop"
              className="green-btn px-[23px] py-[10px] text-sm"
            >
              {t("Workshops.Add Workshop")}
            </Link>
          )}
          <span
            onClick={() => {
              isGridView(!gridView);
            }}
            title={
              gridView
                ? t("Workshops.Switch to list view") + ""
                : t("Workshops.Switch to grid view") + ""
            }
            className="ml-3 cursor-pointer text-ph-btn"
          >
            <FontAwesomeIcon icon={gridView ? faListUl : faGrid2} size="lg" />
          </span>
        </div>
      </div>
      <WorkshopFilterBox />
      {isFilter && <WorkshopAppliedFilter />}

      <SearchField
        applySearch={applySearch}
        change={(e) => setSearch(e.target.value)}
        searchValue={search}
      />
      <div className="p-4 bg-white mt-3 rounded-md">
        {gridView ? (
          <div className="flex justify-between flex-wrap">
            {workshopList.length > 0 ? (
              workshopList.map((workshop: Workshops) => (
                <WorkshopGrid
                  workshop={workshop}
                  key={workshop.slug}
                  fetchData={() => {
                    fetchData({});
                  }}
                />
              ))
            ) : (
              <Loading loading={loading} />
            )}
          </div>
        ) : (
          <GenericTable
            search={sendSearch}
            countData={count}
            handleChange={handleChange}
            dataList={workshopList}
            fetchData={() => {
              fetchData({});
            }}
            loading={loading}
            setOrderingList={order}
            titles={[
              { name: t("Tree Species.Name"), value: "name" },
              { name: t("PlantingFilter.From"), value: "from" },
              { name: t("PlantingFilter.To"), value: "to" },
              { name: t("Plantings.Department"), value: "department" },
              { name: t("Plantings.Municipality"), value: "municipality" },
              { name: t("Plantings.Community"), value: "community" },
              { name: t("Tree Species.Description"), value: "description" },
              {
                name: t("PlantingFilter.Participants"),
                value: "participants",
              },
            ]}
            renderItem={(workShopRecord, fetchData, lastIndex) => (
              <WorkshopTableItem
                workshopItem={workShopRecord}
                fetchData={fetchData}
                lastIndex={lastIndex}
              />
            )}
          />
        )}
        {gridView && workshopList.length > 0 && (
          <div className="flex justify-between items-center mt-4">
            <ThemeProvider theme={TableThem}>
              <TablePagination
                count={Math.ceil(count / 10)}
                handleChange={handleChange}
              />
            </ThemeProvider>
            <p className="text-sm text-default-text">
              {" "}
              {`${t("Message.Showing")} ${(page - 1) * 10 + 1} - ${
                (page - 1) * 10 + workshopList.length
              } ${t("Message.of")} ${count}`}
            </p>
          </div>
        )}
        <br />
      </div>
      {exportLoading && (
        <div className="w-full h-full z-[9990] bg-ph-gray bg-opacity-70 flex items-center justify-center fixed top-0 left-0">
          <Loading loading={true} />
        </div>
      )}
      {!isLoaded && (
        <div className="w-full h-full z-[9990] bg-white bg-opacity-70 flex items-center justify-center fixed top-0 left-0">
          <Loading loading={true} fullSize={true} />
        </div>
      )}
    </div>
  );
};

export default Workshop;
