import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Department } from "../type/department";
import SpeciesList from "../type/speciesList";

type ReportFilter = {
  year: string;
  departments: Department[];
  incidents: SpeciesList[];
  level: string;
};
const initialState: {
  filterItem: ReportFilter;
  url: string;
  filterSet: boolean;
} = {
  filterItem: {
    departments: [],
    incidents: [],
    year: "",
    level: "",
  },
  url: "/?",
  filterSet: false,
};

const plantingMomentReportSlice = createSlice({
  name: "plantingMomentReportFilter",
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<ReportFilter>) => {
      state.filterItem = action.payload;
    },
    setURL: (state) => {
      let dep = "";
      let inc = "";
      let queryParams = [];

      if (
        state.filterItem.departments.length !== 0 &&
        state.filterItem.level === "D"
      ) {
        state.filterItem.departments.forEach((c) => {
          dep += `departments=${c.slug}&`;
        });
      }
      if (state.filterItem.incidents.length !== 0) {
        state.filterItem.incidents.forEach((c) => {
          inc += `incidents=${c.slug}&`;
        });
      }
      if (state.filterItem.year) {
        queryParams.push(`date=${state.filterItem.year}`);
      }
      if (dep) {
        queryParams.push(dep.slice(0, -1));
      }
      if (inc) {
        queryParams.push(inc.slice(0, -1));
      }

      if (state.filterItem.level) {
        queryParams.push(`level=${state.filterItem.level}`);
      }
      state.url = `/?${queryParams.join("&")}`;
    },
    clearFilter: (state) => {
      state.filterSet = false;
      state.filterItem.level = "";
      state.filterItem.year = "";
      state.filterItem.departments = [];
      state.filterItem.incidents = [];
    },
    isFilterSet: (state) => {
      if (
        state.filterItem.level === "" &&
        state.filterItem.year === "" &&
        state.filterItem.incidents.length === 0 &&
        state.filterItem.departments.length === 0
      ) {
        state.filterSet = false;
      } else {
        state.filterSet = true;
      }
    },
  },
});

export const plantingMomentReportAction = plantingMomentReportSlice.actions;
export default plantingMomentReportSlice;
