import { ThemeProvider } from "@emotion/react";
import { theme } from "../../Register/AddRegister/FormTheme";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import TranslationField from "../../../type/translationField";
import LanguagePoint from "../../Global/LanguagePoint";
import SelectLanguage from "../../Global/SelectLanguage";
import Warning from "../../ErrorAndAlert/Warning";
import CustomAutocomplete from "../../Global/CustomAutocomplete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomDate from "../../Global/CustomDate";
import SpeciesList from "../../../type/speciesList";
import i18n from "../../../i18n";

type FormValues = {
  date: Date;
  nr_trees: number;
  initial_age: number;
  ageYear: string;
  ageMonth: string;
  species: SpeciesList;
};

const AddTreeSuppliesForm: React.FC<{
  send: (data: any) => void;
  sendResponse: any;
  getInfo?: any;
  species: SpeciesList[];
  textButton: string;
  inventory?: string;
}> = ({ send, sendResponse, getInfo, textButton, inventory, species }) => {
  const { t } = useTranslation();
  const [lang, setLang] = useState<string>("ES");
  const [dirty, isDirty] = useState<boolean>(true);
  const [translatableInput, setTranslatableInput] = useState<{
    note: TranslationField;
  }>({
    note: { en: "", es: "" },
  });
  const [loading, setLoading] = useState<boolean>(true);
  const {
    handleSubmit,
    setValue,
    clearErrors,
    register,
    control,
    formState: { errors },
  } = useForm<FormValues>();

  const history = useHistory();
  const handleLangChange = (event: SelectChangeEvent) => {
    setLang(event.target.value);
  };

  useEffect(() => {
    if (getInfo) {
      const dateF: string[] = getInfo?.data?.date.split("/");
      setValue(
        "date",
        new Date(Number(dateF[2]), Number(dateF[1]) - 1, Number(dateF[0]))
      );
      setTranslatableInput({
        note: getInfo.data.note_transes,
      });
      setValue("nr_trees", getInfo.data.number_of_trees);
      setValue("initial_age", getInfo.data.initial_age);
      setValue("ageYear", getInfo.data.age_year + "");
      setValue("ageMonth", getInfo.data.age_month + "");
      setValue("species", {
        slug: getInfo.data.species_slug,
        name_transes: getInfo.data.species_name_transes,
      });
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInfo]);

  useEffect(() => {
    if (sendResponse) {
      toast.success(
        `${getInfo
          ? `${t("Message.The record updated successfully")}`
          : `${t("Message.The record added successfully")}`
        }`
      );
      history.replace(`/setting/nurseries/inventory-tracking/${inventory}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendResponse]);

  const noteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (lang === "EN") {
      setTranslatableInput((prevState) => {
        return {
          ...prevState,
          note: {
            en: event.target.value,
            es: prevState.note.es,
          },
        };
      });
    } else if (lang === "ES") {
      setTranslatableInput((prevState) => {
        return {
          ...prevState,
          note: {
            es: event.target.value,
            en: prevState.note.en,
          },
        };
      });
    }
  };

  const addSupplies = (data: FormValues) => {
    isDirty(false);
    if (getInfo) {
      // Splitting the date string into day, month, and year components
      const dateComponents = getInfo?.data?.date.split("/");
      const day = parseInt(dateComponents[0], 10);
      const month = parseInt(dateComponents[1], 10) - 1; // Adjusting month to start from 0
      const year = parseInt(dateComponents[2], 10);

      // Creating a new Date object with the parsed components
      const getInfoDate = new Date(year, month, day);
      const formattedGetInfoDate = `${getInfoDate.getDate()}/${getInfoDate.getMonth() + 1
        }/${getInfoDate.getFullYear()}`;

      const dateEdited =
        formattedGetInfoDate !==
        `${data.date.getDate()}/${data.date.getMonth() + 1
        }/${data.date.getFullYear()}`;
      const speciesEdited = data.species.slug !== getInfo?.data?.species_slug;
      const ageEdited =
        data.ageYear !== getInfo?.data.age_year + "" ||
        data.ageMonth !== getInfo?.data.age_month + "";
      if (
        data.nr_trees !== getInfo?.data.number_of_trees &&
        data.nr_trees < getInfo?.data.distributed_number
      ) {
        toast.error(
          `${t("Message.Supplied trees can't be less than distributed trees")}`
        );
      } else if (
        (dateEdited || speciesEdited || ageEdited) &&
        getInfo?.data.distributed_number > 0
      ) {
        if (dateEdited) {
          toast.error(
            `${t(
              "Message.You can't edit date of the supplied trees. There are distributed trees for selected species and age."
            )}`
          );
        }
        if (speciesEdited) {
          toast.error(
            `${t(
              "Message.You can't edit species of the supplied trees. There are distributed trees for selected species and age."
            )}`
          );
        }
        if (ageEdited) {
          toast.error(
            `${t(
              "Message.You can't edit age of the supplied trees. There are distributed trees for selected species and age."
            )}`
          );
        }
      } else {
        send({
          date: `${data.date.getDate()}/${data.date.getMonth() + 1
            }/${data.date.getFullYear()}`,
          note_transes: translatableInput.note,
          age_year: Number(data.ageYear),
          age_month: Number(data.ageMonth),
          species: data.species.slug,
          number_of_trees: data.nr_trees,
        });
      }
    } else {
      if (
        translatableInput.note.en.trim() !== "" &&
        translatableInput.note.es.trim() === ""
      ) {
        toast.error(
          `${t(
            "Message.While other languages have a translation, the default language cannot be empty."
          )}`
        );
      } else {
        send({
          date: `${data.date.getDate()}/${data.date.getMonth() + 1
            }/${data.date.getFullYear()}`,
          note_transes: translatableInput.note,
          age_year: data.ageYear,
          age_month: data.ageMonth,
          species: data.species.slug,
          number_of_trees: data.nr_trees,
        });
      }
    }
  };

  return (
    <Fragment>
      {loading && getInfo ? (
        <p className="text-center text-2xl font-medium text-default-text">
          {t("Message.Loading")}
        </p>
      ) : (
        <form onSubmit={handleSubmit(addSupplies)}>
          <ThemeProvider theme={theme}>
            <p className="text-sm text-default-text mb-[6px]">
              {t("AddPlanting.Language")}
            </p>
            <SelectLanguage handleLangChange={handleLangChange} lang={lang} />
            <div className="flex flex-wrap justify-between mt-7">
              <div className="w-full sm:w-1/2 sm:pr-2">
                <p className="text-sm  text-default-text mb-[6px]">
                  {t("Workshops.Date")}*
                </p>
                <CustomDate
                  clearError={clearErrors}
                  control={control}
                  name="date"
                  label={t("AddPlanting.Select date")}
                  rules={{ required: `${t("Message.Required field")}` }}
                />
                {errors.date && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
              <div className="w-full sm:w-1/2 sm:pl-2 mt-7 md:mt-0">
                <p className="text-sm text-default-text mb-[6px]">
                  {t("Tree inventory.Nr. Trees")}*
                </p>
                <input
                  {...register("nr_trees", {
                    required: `${t("Message.Required field")}`,
                  })}
                  type="number"
                  className="input-field pl-4 py-3 text-sm placeholder-ph-light-gray"
                  placeholder={t("Tree inventory.Nr. Trees") + ""}
                />
                {errors.nr_trees && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
            </div>
            <div className="flex flex-wrap justify-between mt-7">
              <div className="w-full sm:w-1/2 sm:pr-2">
                <p className="text-sm text-default-text mb-[6px]">
                  {t("Tree inventory.Initial age")}*
                </p>
                <div className="flex items-center">
                  <Controller
                    control={control}
                    name="ageYear"
                    rules={{ required: `${t("Message.Required field")}` }}
                    render={({ field: { onChange, value } }) => (
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel shrink={false}>
                          {!value && `${t("Register.Year")}`}
                        </InputLabel>
                        <Select
                          style={{
                            height: "48px",
                          }}
                          IconComponent={ExpandMoreIcon}
                          variant="outlined"
                          value={value || null}
                          onChange={(e) => {
                            onChange(e as ChangeEvent<Element>);
                          }}
                          sx={{
                            border: "1px solid #eeecec",
                            borderRadius: "4px",
                            "& fieldset": {
                              border: "none",
                            },
                          }}
                        >
                          <MenuItem value="0">0</MenuItem>
                          <MenuItem value="1">1</MenuItem>
                          <MenuItem value="2">2</MenuItem>
                          <MenuItem value="3">3</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                  <span className="px-2 text-default-text text-xs">
                    &#9866;
                  </span>
                  <Controller
                    control={control}
                    name="ageMonth"
                    rules={{ required: `${t("Message.Required field")}` }}
                    render={({ field: { onChange, value } }) => (
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel shrink={false}>
                          {!value && `${t("Register.Month")}`}
                        </InputLabel>
                        <Select
                          style={{ height: "48px" }}
                          IconComponent={ExpandMoreIcon}
                          variant="outlined"
                          value={value || null}
                          onChange={(e) => {
                            onChange(e as ChangeEvent<Element>);
                          }}
                          sx={{
                            border: "1px solid #eeecec",
                            borderRadius: "4px",
                            "& fieldset": { border: "none" },
                          }}
                        >
                          <MenuItem value="0">0</MenuItem>
                          <MenuItem value="1">1</MenuItem>
                          <MenuItem value="2">2</MenuItem>
                          <MenuItem value="3">3</MenuItem>
                          <MenuItem value="4">4</MenuItem>
                          <MenuItem value="5">5</MenuItem>
                          <MenuItem value="6">6</MenuItem>
                          <MenuItem value="7">7</MenuItem>
                          <MenuItem value="8">8</MenuItem>
                          <MenuItem value="9">9</MenuItem>
                          <MenuItem value="10">10</MenuItem>
                          <MenuItem value="11">11</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                </div>
                {(errors.ageYear || errors.ageMonth) && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
              <div className="w-full sm:w-1/2 sm:pl-2">
                <p
                  className={`text-default-text text-sm mb-[6px] mt-7 sm:mt-0`}
                >
                  {t("Navbar.Tree species")}*
                </p>
                <CustomAutocomplete
                  control={control}
                  name="species"
                  rules={{ required: `${t("Message.Required field")}` }}
                  selectOptions={species}
                  placeholder={t("Navbar.Tree species")}
                  getOptionLabel={(option) =>
                    i18n.language === "en"
                      ? option.name_transes?.en === ""
                        ? option.name_transes?.es
                        : option.name_transes?.en
                      : option.name_transes?.es
                  }
                />
                {errors.species && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
            </div>
          </ThemeProvider>

          <p className="text-sm text-default-text mt-7 mb-[6px]">
            {t("Tree inventory.Note")} <LanguagePoint lang={lang} />
          </p>
          <textarea
            onChange={noteChange}
            value={
              lang === "EN"
                ? translatableInput.note?.en
                : translatableInput.note?.es
            }
            rows={7}
            className="input-field resize-none text-sm  pl-4  py-[10px] placeholder-ph-light-gray"
            placeholder={t("Tree inventory.Note") + ""}
          />
          <button
            type="submit"
            className="green-btn text-sm  px-6 py-2 mt-5 float-right font-medium"
          >
            {textButton}
          </button>
        </form>
      )}
      <Warning when={dirty} onCancel={() => false} onOK={() => true} />
    </Fragment>
  );
};

export default AddTreeSuppliesForm;
