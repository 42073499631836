import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import CollaboratorName from "../../../type/collaboratorName";
const AppliedCollaborator: React.FC<{
  type: string;
  value: CollaboratorName[];
  removeFilter: () => void;
  isReportFilter?: boolean;
}> = ({ type, value, removeFilter, isReportFilter }) => {
  const removerFilter = () => {
    removeFilter();
  };
  return (
    <div className="applied-filter-item">
      <p className="text-xs text-default-text">
        {type}:{" "}
        {value.map((c, index) => (
          <span key={c.slug}>
            {c.name}
            {index < value.length - 1 ? ", " : ""}
          </span>
        ))}
      </p>
      {!isReportFilter ? (
        <span className="relative top-[2px] pl-2" onClick={removerFilter}>
          <FontAwesomeIcon icon={faXmark} />
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

export default AppliedCollaborator;
