import { Fragment, useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hook/useAxios";
import ActionItem from "../../Global/ActionItem";
import { toast } from "react-toastify";
import WarningRedAlert from "../../ErrorAndAlert/WarningRedAlert";
import { TaggingToolRecord } from "../../../type/taggingToolRecord";

const TaggingToolTableItem: React.FC<{
  taggingTool: TaggingToolRecord;
  fetchData: () => void;
  lastIndex: number;
}> = ({ taggingTool, fetchData: propFetchData, lastIndex }) => {
  const { i18n, t } = useTranslation();
  const [showDelete, isShowDelete] = useState<boolean>(false);

  const { response: deleteR, fetchData } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/tags/${taggingTool.id}/delete/`,
    "DELETE",
    false,
    `${t("Message.The record deleted successfully")}`,
    true,
    true
  );
  useEffect(() => {
    if (deleteR) {
      isShowDelete(false);
      propFetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteR]);

  const deleteAction = () => {
    if (taggingTool) {
      isShowDelete(true);
    } else {
      toast.error(
        t(
          "Message.You can’t delete the taggingTool. It is used in a planting or workshop."
        )
      );
    }
  };

  const hide = () => {
    isShowDelete(false);
  };

  const deleteItem = () => {
    fetchData({});
  };

  return (
    <Fragment>
      <TableCell align="left">{taggingTool.community}</TableCell>
      <TableCell>{taggingTool.code}</TableCell>
      <TableCell>{taggingTool.responsible_name}</TableCell>
      <TableCell>
        {i18n.language === "en"
          ? taggingTool.nursery_transes.en || taggingTool.nursery_transes.es
          : taggingTool.nursery_transes.es}
      </TableCell>
      <TableCell>
        {i18n.language === "en"
          ? taggingTool.species_name_transes.en ||
            taggingTool.species_name_transes.es
          : taggingTool.species_name_transes.es}
      </TableCell>
      <TableCell>
        {taggingTool.is_used ? t("Message.Yes") : t("Message.No")}
      </TableCell>
      <TableCell align="right">
        {!taggingTool.is_used && (
          <ActionItem
            delete={true}
            deleteAction={deleteAction}
            view=""
            isEditModal={false}
            viewLink=""
            historyLink=""
            viewHistory=""
            notEditable={true}
            width="w-[104px]"
            height="h-[41px]"
          />
        )}
      </TableCell>
      {showDelete && (
        <WarningRedAlert
          type={t("Tagging tool.Tag")}
          hide={hide}
          action={deleteItem}
          isDelete={true}
          isSignOut={false}
        />
      )}
    </Fragment>
  );
};

export default TaggingToolTableItem;
