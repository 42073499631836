import { useTranslation } from "react-i18next";
import useAxios from "../../../hook/useAxios";
import BreadCrumb from "../../Global/BreadCrumb";
import AddTreeSuppliesForm from "./AddTreeSuppliesForm";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import SpeciesList from "../../../type/speciesList";
import i18n from "../../../i18n";

const AddTreeSupplies = () => {
  const { t } = useTranslation();
  const [species, setSpecies] = useState<SpeciesList[]>([]);

  const { slug } = useParams<any>();
  const { fetchData: addSupply, response } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/nurseries/${slug}/supply/new/`,
    "POST",
    false,
    "",
    true,
    true
  );
  const { response: speciesRes } = useAxios(
    `${process.env.REACT_APP_API_URL}/api/admin/species/all/`,
    "GET",
    true,
    "",
    true,
    false
  );
  const { response: breadcrumb } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/nurseries/${slug}/inventory_tracking/breadcrumb_data`,
    "GET",
    true,
    "",
    false,
    false
  );
  useEffect(() => {
    if (speciesRes && speciesRes.data) {
      setSpecies(speciesRes.data);
    }
  }, [speciesRes]);

  return (
    <div className="parent-container">
      <h1 className="font-semibold text-default-text text-[16px] mt-5">
        {t("Tree inventory.Supply trees")}
      </h1>
      <BreadCrumb
        breadcrumb={[
          { name: t("Navbar.Settings"), url: "" },
          {
            name: t("Tree nurseries.Tree nurseries"),
            url: "/setting/nurseries",
          },
          {
            name: `${
              i18n.language === "en"
                ? breadcrumb?.data.name_transes.en === ""
                  ? breadcrumb?.data.name_transes.es
                  : breadcrumb?.data.name_transes.en
                : breadcrumb?.data.name_transes.es
            }(${breadcrumb?.data.code})`,
            url: "",
          },
          {
            name: t("Tree inventory.Tree inventory tracking"),
            url: `/setting/nurseries/inventory-tracking/${slug}`,
          },
          { name: t("Tree inventory.Supply trees"), url: "" },
        ]}
      />
      <div className="flex flex-wrap justify-between mt-4">
        <div className=" w-full h-fit lg:w-[49%] sc:w-[611px] bg-white rounded-md p-7 relative">
          <AddTreeSuppliesForm
            send={addSupply}
            sendResponse={response}
            textButton={t("Tree inventory.Add")}
            inventory={slug}
            species={species}
          />
        </div>
        <div className="w-full h-fit lg:w-[48%] sc:w-[559px] bg-white rounded-md p-8 mt-4 lg:mt-0">
          <img
            src="/img/Botanical families.svg"
            alt="Add scientific name"
            className="mt-4 ml-2"
          />
        </div>
      </div>
    </div>
  );
};

export default AddTreeSupplies;
