/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react";
import useAxios from "../../../hook/useAxios";
import { Department } from "../../../type/department";
import { t } from "i18next";
import GenericTable from "../../Global/GenericTable";
import { LocationReport } from "../../../type/locationReport";
import { TableCell } from "@mui/material";
import { useSelector } from "react-redux";
import { reportPlantedURL } from "../../../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInbox } from "@fortawesome/pro-regular-svg-icons";

interface ReportFilter {
    dateTo: Date;
    dateFromReport: Date | null | "";
    level: string;
    department: Department[] | null;
    community: Department[] | null;
}


const ReportsParticipantsView: React.FC<{ reportData: ReportFilter }> = ({ reportData }) => {

    const [page, setPage] = useState<number>(1);
    const [ordering, setOrdering] = useState<string>("");
    const [count, setCount] = useState<number>(0);
    const [dataList, setDataList] = useState<LocationReport[]>([])
    const url = useSelector(reportPlantedURL);
    const { fetchData, response, loading } = useAxios(
        process.env.REACT_APP_API_URL + `/api/admin/reports/planted_trees${url}&page=${page}&ordering=${ordering}`,
        "GET",
        true,
        "",
        true,
        false
    );

    function validateCounts(data: any) {
        const keysToCheck = [
            "total_responsible_count",

        ];

        return keysToCheck.every(key => data?.[key] > 0);
    }
    useEffect(() => {
        if (response) {
            setCount(response.data.count);
            setDataList(response.data.results)
        }
    }, [response]);

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };
    const handleChangeSecond = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const order = (value: string) => {
        setOrdering(value);
    };
    return (
        validateCounts(response?.data) ? (
            <div className="flex flex-col p-6 bg-ph-white">
                <h1 className="font-semibold text-default-text text-[14px] mb-5">
                    {t("Reports.Participants & responsible")}
                </h1>
                <span className="text-ph-dark-gray bg-ph-bg-white text-sm font-medium py-4 pl-5">
                    {t("Reports.Tree responsible")}
                </span>
                <span className="flex flex-col xl:flex-row mb-5 md:mb-0 border-[1px] border-ph-input border-opacity-100 p-4">
                    <span className="flex flex-col md:flex-row">
                        <div className="mr-5 md:mr-[180px]">
                            <p className="text-sm text-ph-gray">
                                {t("Reports.Total responsible")}
                            </p>
                            <p className="mt-2 text-sm text-default-text font-medium">
                                {response?.data?.total_responsible_count}
                            </p>
                        </div>
                        <div className="mr-5 mt-7 md:mt-0 md:mr-[180px]">
                            <p className="text-sm text-ph-gray">
                                FUNDAEC
                            </p>
                            <p className="mt-2 text-sm text-default-text font-medium">
                                {response?.data?.fundaec_sum}
                            </p>
                        </div>
                        <div className="mr-5 mt-7 md:mt-0 md:mr-[180px]">
                            <p className="text-sm text-ph-gray">
                                {t("Register.Group")}
                            </p>
                            <p className="mt-2 text-sm text-default-text font-medium">
                                {response?.data?.group_sum}
                            </p>
                        </div>
                    </span>
                    <span className="flex  flex-col md:flex-row md:mt-7 xl:mt-0">
                        <div className="sm:mr-[190px] lg:mr-[225px] mt-7 md:mt-0 xl:mr-[180px]">
                            <p className="text-sm text-ph-gray">
                                {t("Register.Individual")}
                            </p>
                            <p className="mt-2 text-sm text-default-text font-medium">
                                {response?.data?.individual_sum}
                            </p>
                        </div>
                        <div className="mr-5 mt-7 md:mt-0">
                            <p className="text-sm text-ph-gray">
                                {t("Register.Institution")}
                            </p>
                            <p className="mt-2 text-sm text-default-text font-medium">
                                {response?.data?.institution_sum}
                            </p>
                        </div>
                    </span>
                </span>
                <span className="mt-5 text-ph-dark-gray bg-ph-bg-white text-sm font-medium py-4 pl-5">
                    {t("Planting organizers.Planting organizers")}
                </span>
                {reportData.level !== "N" ? (
                    <GenericTable
                        countData={count}
                        handleChange={handleChange}
                        dataList={dataList}
                        fetchData={() => {
                            fetchData({});
                        }}
                        loading={loading}
                        setOrderingList={order}
                        showAction={false}
                        titles={[
                            reportData.level === "D" ?
                                ({ name: t("Plantings.Department"), value: "department" }) :
                                ({ name: t("Community.Community"), value: "community" }),
                            { name: t("Register.PSA group"), value: "psa_group" },
                            { name: t("Statistics.Planted trees"), value: "trees" },
                        ]}
                        renderItem={(departments) => (
                            <Fragment>
                                <TableCell align="left">{departments.department_community_name}</TableCell>
                                <TableCell>{departments.psa_group_name}</TableCell>
                                <TableCell>{departments.trees_count}</TableCell>
                                <TableCell></TableCell>
                            </Fragment>
                        )}
                    />
                ) : (
                    <GenericTable
                        countData={count}
                        handleChange={handleChangeSecond}
                        dataList={dataList}
                        fetchData={() => {
                            fetchData({});
                        }}
                        loading={loading}
                        setOrderingList={order}
                        showAction={false}
                        titles={[
                            { name: t("Register.PSA group"), value: "psa_group" },
                            { name: t("Statistics.Planted trees"), value: "trees" },
                        ]}
                        renderItem={(departments) => (
                            <Fragment>
                                <TableCell>{departments.psa_group_name}</TableCell>
                                <TableCell>{departments.trees_count}</TableCell>
                                <TableCell></TableCell>
                            </Fragment>
                        )}
                    />
                )}
            </div>
        ) :
            <div className="parent-container">
                <p className="m-auto text-center font-medium text-2xl text-default-text">
                    <FontAwesomeIcon className="mr-4" icon={faInbox} />
                    {t("Message.No results found")}
                </p>
            </div>
    );
};

export default ReportsParticipantsView;