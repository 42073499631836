import { Fragment, useEffect, useState } from "react";
import useAxios from "../../../hook/useAxios";
import { Department } from "../../../type/department";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faInbox,
  faTree,
  faTrees,
  faUsers,
} from "@fortawesome/pro-light-svg-icons";
import { t } from "i18next";
import { TableCell } from "@mui/material";
import i18n from "../../../i18n";
import { useSelector } from "react-redux";
import { donorsSponsorsReportURL } from "../../../store";
import { DonorsTreeSummery } from "../../../type/donorsTreeSummery";
import TreeChart from "./TreeChart";
import ReportTable from "./ReportTable";

interface ReportFilter {
  dateTo: Date;
  dateFromReport: Date | null | "";
  level: string;
  department: Department[] | null;
  community: Department[] | null;
}

const DonorsSponsorsTreesView: React.FC<{ reportData: ReportFilter }> = ({
  reportData,
}) => {
  const [summery, setSummery] = useState<DonorsTreeSummery>();

  const url = useSelector(donorsSponsorsReportURL);

  const { response: treeSummeryResponse } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/reports/donors/trees/summery${url}`,
    "GET",
    true,
    "",
    true,
    false
  );


  useEffect(() => {
    if (treeSummeryResponse) {
      setSummery(treeSummeryResponse.data);
    }
  }, [treeSummeryResponse]);


  return summery?.trees ? (
    <div className="flex flex-col p-6 bg-ph-white">
      <h1 className="font-semibold text-default-text text-[14px] mb-5">
        {t("Reports.Trees")}
      </h1>
      <div className="flex flex-wrap mb-5 border-[1px] border-ph-input border-opacity-100 p-4">
        {reportData.level !== "N" && (
          <>
            <div className="flex justify-center sm:justify-start items-center mb-9 sm:mb-1 w-full sm:w-[380px]">
              {reportData.level === "D" ? (
                <FontAwesomeIcon
                  className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-[16px]"
                  icon={faBuilding}
                />
              ) : (
                <FontAwesomeIcon
                  className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-[16px]"
                  icon={faUsers}
                />
              )}
              <div>
                <p className="text-sm text-ph-gray">
                  {reportData.level === "D"
                    ? t("Navbar.Departments")
                    : t("Community.Communities")}
                </p>
                <p className="text-sm text-default-text font-medium">
                  {reportData.level === "D"
                    ? summery?.departments
                    : summery?.communities}
                </p>
              </div>
            </div>
          </>
        )}

        <div className="flex justify-center sm:justify-start mb-9 sm:mb-1 items-center w-full sm:w-[380px]">
          <FontAwesomeIcon
            className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-[16px]"
            icon={faTree}
          />
          <div>
            <p className="text-sm text-ph-gray">{t("Plantings.Species")}</p>
            <p className="text-sm text-default-text font-medium">
              {summery?.species}
            </p>
          </div>
        </div>

        <div className="flex justify-center sm:justify-start items-center w-full sm:w-[100px]">
          <FontAwesomeIcon
            className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-[16px]"
            icon={faTrees}
          />
          <div>
            <p className="text-sm text-ph-gray">{t("Reports.Trees")}</p>
            <p className="text-sm text-default-text font-medium">
              {summery?.trees}
            </p>
          </div>
        </div>
      </div>
      <ReportTable
        url={`donors/trees/list${url}`}
        titles={
          reportData.level === "N"
            ? [
                { name: t("Plantings.Species"), value: "species" },
                { name: t("Reports.Trees"), value: "trees" },
              ]
            : [
                reportData.level === "D"
                  ? { name: t("Plantings.Department"), value: "department" }
                  : { name: t("Community.Community"), value: "community" },
                { name: t("Plantings.Species"), value: "species" },
                { name: t("Reports.Trees"), value: "trees" },
              ]
        }
        renderItem={(treeListItem) => (
          <Fragment>
            {reportData.level === "N" ? (
              <Fragment></Fragment>
            ) : reportData.level === "D" ? (
              <TableCell align="left">{treeListItem.department}</TableCell>
            ) : (
              <TableCell align="left">{treeListItem.community}</TableCell>
            )}
            <TableCell>
              {i18n.language === "en"
                ? treeListItem.species_name_transes.en === ""
                  ? treeListItem.species_name_transes.es
                  : treeListItem.species_name_transes.en
                : treeListItem.species_name_transes.es}
            </TableCell>
            <TableCell>{treeListItem.trees}</TableCell>
            <TableCell></TableCell>
          </Fragment>
        )}
      />

      {reportData.level === "D" && <TreeChart />}
    </div>
  ) : (
    <div className="parent-container">
      <p className="m-auto text-center font-medium text-2xl text-default-text">
        <FontAwesomeIcon className="mr-4" icon={faInbox} />
        {t("Message.No results found")}
      </p>
    </div>
  );
};

export default DonorsSponsorsTreesView;
