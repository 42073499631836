import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hook/useAxios";
import { Department } from "../../../type/department";
import BreadCrumb from "../../Global/BreadCrumb";
import AddPlantingForm from "./AddPlantingForm";
import { OrganizerType } from "../../../type/OrganizerType";

const AddPlanting = () => {
  const [selectOption, setSelectOption] = useState<{
    department: Department[];
    psa: Department[];
    ins: OrganizerType[];
    ind: Department[];
  }>({ department: [], ind: [], ins: [], psa: [] });
  const { t } = useTranslation();
  const { fetchData, response } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/plantings/new/",
    "POST",
    false,
    "",
    true,
    true
  );

  const { response: departmentRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/departments/all/",
    "GET",
    true,
    "",
    true,
    false
  );
  const { response: psaRes } = useAxios(
    process.env.REACT_APP_API_URL +
      "/api/admin/organizers/names/?organizer_type=psa",
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: indRes } = useAxios(
    process.env.REACT_APP_API_URL +
      "/api/admin/organizers/names/?organizer_type=ind",
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: insRes } = useAxios(
    process.env.REACT_APP_API_URL +
      "/api/admin/organizers/names/?organizer_type=ins",
    "GET",
    true,
    "",
    false,
    false
  );
  const addOrganizerName = (type: string, value: OrganizerType) => {
    if (type === "psa") {
      let psa = selectOption.psa;
      psa.push(value);
      setSelectOption({
        department: selectOption.department,
        ind: selectOption.ind,
        ins: selectOption.ins,
        psa: psa,
      });
    } else if (type === "ind") {
      let ind = selectOption.ind;
      ind.push(value);
      setSelectOption({
        department: selectOption.department,
        ind: ind,
        ins: selectOption.ins,
        psa: selectOption.psa,
      });
    } else if (type === "ins") {
      let ins = selectOption.ins;
      ins.push(value);
      setSelectOption({
        department: selectOption.department,
        ind: selectOption.ind,
        ins: ins,
        psa: selectOption.psa,
      });
    }
  };
  useEffect(() => {
    if (departmentRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          department: departmentRes.data,
        };
      });
    }
    if (psaRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          psa: psaRes.data,
        };
      });
    }
    if (indRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          ind: indRes.data,
        };
      });
    }
    if (insRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          ins: insRes.data,
        };
      });
    }
  }, [departmentRes, indRes, insRes, psaRes]);

  return (
    <div className="parent-container">
      <h1 className="font-semibold text-default-text text-[16px] mt-5">
        {t("Plantings.Add planting")}
      </h1>
      <BreadCrumb
        breadcrumb={[
          { name: t("Plantings.Plantings"), url: "/plantings" },
          { name: t("Plantings.Add planting"), url: "" },
        ]}
      />
      <div className="flex flex-wrap justify-between mt-4">
        <div className=" w-full h-fit lg:w-[49%] sc:w-[611px] bg-white rounded-md p-7 relative">
          <AddPlantingForm
            addOrganizer={addOrganizerName}
            selectOptions={selectOption}
            send={fetchData}
            sendResponse={response}
            textButton={t("Plantings.Add planting")}
          />
        </div>
        <div className="w-full h-fit lg:w-[48%] sc:w-[559px] bg-white rounded-md p-8 mt-4 lg:mt-0">
          <img
            src="/img/Add Event.svg"
            alt="addEvent"
            className="mt-4 ml-2 xl:ml-10"
          />
        </div>
      </div>
    </div>
  );
};

export default AddPlanting;
