import { useTranslation } from "react-i18next";

const NoDataAvailable: React.FC<{
  detailText: string;
}> = ({ detailText }) => {
  const { t } = useTranslation();

  return (
    <div className="p-4 bg-white rounded-md text-center text-default-text">
      <div>
        <p className="md:mt-28 mr-4 font-medium text-2xl">
          {t("Import register.No data")}
        </p>
        <p className="md:w-[75%] md:pl-[20%] mt-4 font-medium text-base ">
          {detailText}
        </p>
      </div>
      <img
        src="/img/Imports illu.png"
        alt="addEvent"
        className="mx-auto mt-8 md:mt-[-90px] md:ml-2 md:mr-5"
      />
    </div>
  );
};

export default NoDataAvailable;
