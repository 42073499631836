import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useAxios from "../../../hook/useAxios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBolt,
  faChartLineUp,
  faCircleCheck,
  faInfoCircle,
  faPencil,
  faRulerCombined,
  faRulerVertical,
  faSeedling,
  faSkull,
  faTrashCan,
} from "@fortawesome/pro-light-svg-icons";
import { toast } from "react-toastify";
import Timeline from "../Timeline";
import AddGrowthHistory from "../AddHistory/AddGrowthHistory";
import EditHistory from "../EditHistory/EditHistory";
import { GrowthHistory } from "../../../type/growthHistory";
import SpeciesList from "../../../type/speciesList";
import HistoryBreadcrumb from "../HistoryBreadcrumb";
import { useSelector } from "react-redux";
import { profileInfo } from "../../../store";
import Loading from "../../Global/Loading";
import WarningRedAlert from "../../ErrorAndAlert/WarningRedAlert";
import FileResource from "../../Global/FileResource";

const ViewGrowthHistory: React.FC<{}> = () => {
  const [histories, setHistories] = useState<GrowthHistory[]>([]);
  const [isAddView, setIsAddView] = useState<boolean>(false);
  const [isEditView, setIsEditView] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [activeDate, setActiveDate] = useState<string>("");
  const [isNew, setIsNew] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectOption, setSelectOption] = useState<{
    growth_stage: SpeciesList[];
    incident: SpeciesList[];
  }>({ growth_stage: [], incident: [] });
  const [loading, setLoading] = useState<boolean>(true);
  const [showDelete, isShowDelete] = useState<boolean>(false);

  const profile = useSelector(profileInfo);
  const { i18n, t } = useTranslation();
  const { id } = useParams<any>();

  const { response: getListResponse, fetchData: getHistory } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/registers/${id}/histories/`,
    "GET",
    true,
    "",
    true,
    false
  );

  const { response: growthStageRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/growth_stages/all/",
    "GET",
    true,
    "",
    false,
    false
  );

  const { response: incidentRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/incidents/all/",
    "GET",
    true,
    "",
    false,
    false
  );

  const { response: getEditResponse } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/registers/${id}/edit/`,
    "GET",
    true,
    "",
    false,
    false
  );

  const { fetchData: getPlantingId, response: breadCrumbResponse } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/plantings/${getEditResponse?.data?.planting_slug}/breadcrumb-data/?register_slug=${id}`,
    "GET",
    false,
    "",
    false,
    true
  );

  const { response: deleteR, fetchData: deleteHistory } = useAxios(
    "",
    "DELETE",
    false,
    `${t("Message.The record deleted successfully")}`,
    false,
    true
  );

  useEffect(() => {
    if (getEditResponse) {
      getPlantingId({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEditResponse]);

  useEffect(() => {
    if (getListResponse) {
      setHistories(getListResponse.data);
      setLoading(false);
    }
    if (growthStageRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          growth_stage: growthStageRes.data,
        };
      });
    }
    if (incidentRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          incident: incidentRes.data,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getListResponse, growthStageRes, incidentRes]);

  useEffect(() => {
    setSelectedIndex(histories.length - 1);
  }, [histories]);

  useEffect(() => {
    const date = new Date(activeDate);
    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };
    handleRecordClick({ title: date.toLocaleDateString("en-GB", options) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [histories]);

  useEffect(() => {
    if (deleteR) {
      getHistory({});
      if (getListResponse) {
        setHistories(getListResponse.data);
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteR]);

  const addButtonHandler = () => {
    setIsAddView(true);
    setIsEditView(false);
  };

  // Below function gets the date with yyyy-mm-dd format and convert it into dd/mm/yyyy format.
  const convertDate = (date: string) => {
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  };

  // Below function gets the date with dd months yyyy format and convert it into yyyy-mm-dd format.
  const parseDate = (formattedDate: string): string => {
    const date = new Date(formattedDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleActiveItemChange = (newActiveItem: string) => {
    setActiveDate(newActiveItem);
    setIsNew(true);
  };

  const handleRecordClick = (record: { title: string }) => {
    if (isNew && !isEdit) {
      const index = histories.findIndex(
        (history) => history.date === activeDate
      );
      if (index !== -1) {
        setSelectedIndex(index);
        setIsNew(false);
      }
    } else if (!isNew && isEdit) {
      const index = histories.findIndex(
        (history) => history.date === parseDate(record.title)
      );
      setIsEdit(false);
      if (index !== -1) {
        setSelectedIndex(index);
      }
    } else {
      const index = histories.findIndex(
        (history) => history.date === parseDate(record.title)
      );
      if (index !== -1) {
        setSelectedIndex(index);
      }
    }
  };

  const deleteHandler = () => {
    if (histories[selectedIndex].is_deletable) {
      setLoading(true);
      deleteHistory(
        { id: histories[selectedIndex].id },
        process.env.REACT_APP_API_URL +
          `/api/admin/registers/histories/${histories[selectedIndex].id}/delete/`
      );
      isShowDelete(false);
    } else {
      toast.error(`${t("Message.Not allowed delete register history")}`);
    }
  };

  const deleteAction = () => {
    isShowDelete(true);
  };

  const hide = () => {
    isShowDelete(false);
  };

  return (
    <div className="parent-container">
      <HistoryBreadcrumb breadCrumb={breadCrumbResponse} />
      <div className="flex flex-wrap justify-center mt-4">
        <div className="w-full h-fit sc:w-[1200px] bg-ph-white rounded-md p-7 relative">
          <div>
            {profile.role !== "V" && (
              <button
                type="button"
                onClick={addButtonHandler}
                className="green-btn text-sm px-6 py-2 float-right font-medium"
              >
                {t("Growth history.Add tree growth history")}
              </button>
            )}

            <br />
            {loading ? (
              <Loading loading={true} />
            ) : (
              <Fragment>
                {histories.length > 0 && (
                  <Timeline
                    activeItemIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                    onItemSelected={handleRecordClick}
                    values={histories}
                  />
                )}
                {histories.length === 0 && !isAddView && !isEditView && (
                  <Loading loading={false} />
                )}
              </Fragment>
            )}
          </div>
          {!isAddView &&
            !isEditView &&
            histories.length > 0 &&
            histories[selectedIndex] && (
              <div className="sm:px-[50px]">
                <div className="w-full h-fit sc:w-[500px] mx-auto">
                  <p className="text-xl text-default-text font-bold pb-4">
                    {t("Growth history.Tree growth history")}
                  </p>
                  <p className="text-base text-ph-light-gray font-bold pb-6">
                    {convertDate(histories[selectedIndex].date)}
                    {profile.role !== "V" && (
                      <span title={t("Message.Edit") + ""}>
                        <button
                          type="button"
                          onClick={() => {
                            setIsEditView(true);
                          }}
                          className="text-sm text-default-text items-center pl-[22px]"
                        >
                          <FontAwesomeIcon
                            className="mr-2 text-default-text hover:text-ph-hover"
                            icon={faPencil}
                            size="sm"
                          />
                        </button>
                      </span>
                    )}
                    {profile.role !== "V" && profile.role !== "O" && (
                      <span title={t("PlantingFilter.Delete") + ""}>
                        <button
                          type="button"
                          onClick={() => {
                            deleteAction();
                          }}
                          className="text-sm text-default-text items-center pl-[13px]"
                        >
                          <FontAwesomeIcon
                            className="mr-2 text-default-text hover:text-ph-red-warning"
                            icon={faTrashCan}
                            size="sm"
                          />
                        </button>
                      </span>
                    )}
                  </p>
                </div>
                <div className="flex flex-wrap justify-center">
                  <div className="w-full h-fit sc:w-[500px] bg-ph-bg-white rounded-md px-6 py-6 relative">
                    <div className="flex flex-row justify-between items-center mb-10 ">
                      <div className="flex flex-row items-center">
                        <div>
                          <FontAwesomeIcon
                            className="text-ph-gray"
                            icon={faRulerVertical}
                            size="lg"
                          />
                        </div>
                        <div className="flex flex-col justify-between ml-5">
                          <div className="text-sm text-ph-gray">
                            {t("Register.Tree height")}
                          </div>
                          <div className="text-sm font-medium text-default-text  py-2">
                            {histories[selectedIndex].height
                              ? histories[selectedIndex].height + " “cm”"
                              : "-"}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row items-center w-[40%]">
                        <div>
                          <FontAwesomeIcon
                            className="text-ph-gray"
                            icon={faRulerCombined}
                            size="lg"
                          />
                        </div>
                        <div className="flex flex-col justify-between ml-5">
                          <div className="text-sm text-ph-gray">
                            {t("Register.Diameter")}
                          </div>
                          <div className="text-sm font-medium text-default-text  py-2">
                            {histories[selectedIndex].diameter
                              ? histories[selectedIndex].diameter + " “mm”"
                              : "-"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row justify-between items-center mb-10">
                      <div className="flex flex-row items-center">
                        <div>
                          <FontAwesomeIcon
                            className="text-ph-gray"
                            icon={faSeedling}
                            size="lg"
                          />
                        </div>
                        <div className="flex flex-col justify-between ml-5">
                          <div className="text-sm text-ph-gray">
                            {t("Register.Age")}
                          </div>
                          <div className="text-sm font-medium text-default-text py-2">
                            {histories[selectedIndex].age_year > 0 ? (
                              `${histories[selectedIndex].age_year} ${t(
                                "Register.Year"
                              )}` +
                              (histories[selectedIndex].age_month > 0
                                ? `, ${histories[selectedIndex].age_month} ${t(
                                    "Register.Month"
                                  )}`
                                : "")
                            ) : histories[selectedIndex].age_year < 0 ? (
                              <span className="age-btn">
                                <FontAwesomeIcon
                                  className="text-lg"
                                  icon={faInfoCircle}
                                />
                                <div className={`age-text shadow-2xl`}>
                                  <p>
                                    {i18n.language === "en"
                                      ? "The age of the tree is wrong. Please check the planting date."
                                      : "La edad del árbol es incorrecta. Por favor verifique la fecha de siembra."}
                                  </p>
                                </div>
                              </span>
                            ) : histories[selectedIndex].age_year === 0 ||
                              histories[selectedIndex].age_month === 0 ? (
                              "-"
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row items-center w-[40%]">
                        <div>
                          <FontAwesomeIcon
                            className="text-ph-gray"
                            icon={faChartLineUp}
                            size="lg"
                          />
                        </div>
                        <div className="flex flex-col justify-between ml-5">
                          <div className="text-sm text-ph-gray">
                            {t("Growth stages.Growth stage")}
                          </div>
                          <div className="text-sm font-medium text-default-text py-2">
                            {i18n.language === "en"
                              ? histories[selectedIndex]?.growth_stage_transes
                                  ?.en ||
                                histories[selectedIndex]?.growth_stage_transes
                                  ?.es ||
                                "-"
                              : histories[selectedIndex]?.growth_stage_transes
                                  ?.es || "-"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row justify-between items-center mb-10">
                      <div className="flex flex-row items-center">
                        <div>
                          <FontAwesomeIcon
                            className="text-ph-gray"
                            icon={faBolt}
                            size="lg"
                          />
                        </div>
                        <div className="flex flex-col justify-between ml-5">
                          <div className="text-sm text-ph-gray">
                            {t("Incidents.Incidents")}
                          </div>
                          <div className="text-sm font-medium text-default-text">
                            {histories[selectedIndex]?.incidents.length > 0 ? (
                              <div className="flex max-w-[30px] flex-wrap py-2">
                                {histories[selectedIndex]?.incidents.map(
                                  (incident, index) => (
                                    <div
                                      className="pr-2 whitespace-nowrap text-default-text text-sm font-medium flex items-center"
                                      key={incident.slug}
                                    >
                                      <p>
                                        {i18n.language === "en"
                                          ? incident.name_transes.en === ""
                                            ? incident.name_transes.es
                                            : incident.name_transes.en
                                          : incident.name_transes.es}
                                      </p>
                                      {index <
                                        histories[selectedIndex]?.incidents
                                          .length -
                                          1 && ","}
                                    </div>
                                  )
                                )}
                              </div>
                            ) : (
                              <p className="text-sm text-default-text font-medium py-2">
                                -
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row items-center w-[40%]">
                        <div>
                          <FontAwesomeIcon
                            className="text-ph-gray"
                            icon={faSkull}
                            size="lg"
                          />
                        </div>
                        <div className="flex flex-col justify-between ml-5">
                          <div className="text-sm text-ph-gray">
                            {t("Growth history.Dead")}
                          </div>
                          <div className="text-sm font-medium text-default-text py-2">
                            {histories[selectedIndex].is_dead ? (
                              <FontAwesomeIcon
                                className="text-lg text-ph-hover "
                                icon={faCircleCheck}
                              />
                            ) : (
                              <p className="text-sm text-default-text font-medium ">
                                -
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-10 text-ph-gray text-sm">
                      {t("AddPlanting.Relevant resources")}
                    </div>
                    <div className="mt-4">
                      <div className="flex flex-col justify-between">
                        {histories[selectedIndex].relevant_resources.length >
                        0 ? (
                          histories[selectedIndex].relevant_resources.map(
                            (file: any) => (
                              <div
                                key={file.id}
                                className="mb-4 p-2 border rounded bg-white shadow-sm"
                              >
                                <FileResource
                                  address={file.file_address}
                                  id={file.id}
                                  key={file.id}
                                />
                              </div>
                            )
                          )
                        ) : (
                          <p className="text-sm text-default-text font-medium">
                            -
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mt-10">
                      <div className="text-sm text-ph-gray">
                        {t("Growth history.Observation")}
                      </div>
                      <div className="text-sm text-default-text font-medium py-2 max-h-[250px] break-words overflow-y-auto">
                        {histories[selectedIndex].observation_transes.en !==
                          "" ||
                        histories[selectedIndex].observation_transes.es !==
                          "" ? (
                          <span className="">
                            {i18n.language === "en"
                              ? histories[selectedIndex].observation_transes
                                  .en ||
                                histories[selectedIndex].observation_transes.es
                              : histories[selectedIndex].observation_transes.es}
                          </span>
                        ) : (
                          <p className="text-sm text-default-text font-medium">
                            -
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mt-10">
                      <div className="text-sm text-ph-gray">
                        {t("Tree Species.Description")}
                      </div>
                      <div className="text-sm text-default-text font-medium py-2 max-h-[250px] break-words overflow-y-auto">
                        {i18n.language === "en"
                          ? histories[selectedIndex].description_transes.en ||
                            histories[selectedIndex].description_transes.es
                          : histories[selectedIndex].description_transes.es}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          <br />
          {isAddView && (
            <AddGrowthHistory
              onAdd={getHistory}
              setActiveDate={handleActiveItemChange}
              addViewHandler={() => {
                setIsAddView(!isAddView);
              }}
              selectOptions={selectOption}
            />
          )}
          {isEditView && (
            <EditHistory
              onUpdate={getHistory}
              setActiveDate={handleActiveItemChange}
              setIsEdit={setIsEdit}
              histories={histories}
              selectedIndex={selectedIndex}
              editViewHandler={() => {
                setIsEditView(!isEditView);
              }}
              selectOptions={selectOption}
            />
          )}
          {showDelete && (
            <WarningRedAlert
              type={t("Growth history.Growth history")}
              hide={hide}
              action={deleteHandler}
              isDelete={true}
              isSignOut={false}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default ViewGrowthHistory;
