import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilterList } from "@fortawesome/pro-light-svg-icons";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { ThemeProvider } from "@emotion/react";
import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { plantingTheme } from "./PlantingTheme";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import {
  registerFilterDelete,
  registerFilterSet,
  speciesList,
} from "../../../store";
import { selectFieldAction } from "../../../store/selectFields-slice";
import { registerFilterAction } from "../../../store/registerFilter-slice";
import { Department } from "../../../type/department";
import useAxios from "../../../hook/useAxios";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import SpeciesList from "../../../type/speciesList";
import CustomDate from "../../Global/CustomDate";
import { ResponsibleType } from "../../../type/responsibleType";
import CustomAutocomplete from "../../Global/CustomAutocomplete";

type FormValues = {
  collaboratorType: string;
  dateFrom: Date;
  dateTo: Date;
  treeSpecies: SpeciesList;
  collaboratorName: ResponsibleType | null;
  partiFrom: number;
  partiTo: number;
  department: Department;
  incidents: SpeciesList[];
  other: SpeciesList[];
};

const FilterBox = () => {
  const [advanceFilter, isAdvanceFilter] = useState<boolean>(false);
  const [selectOption, setSelectOption] = useState<{
    psa: ResponsibleType[];
    ins: ResponsibleType[];
    fun: ResponsibleType[];
    incidents: SpeciesList[];
  }>({ ins: [], psa: [], fun: [], incidents: [] });
  const { t, i18n } = useTranslation();
  const { response: speRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/species/all/",
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: psaRes } = useAxios(
    process.env.REACT_APP_API_URL +
      "/api/admin/responsible/type/all/?category=grp",
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: funRes } = useAxios(
    process.env.REACT_APP_API_URL +
      "/api/admin/responsible/type/all/?category=fun",
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: insRes } = useAxios(
    process.env.REACT_APP_API_URL +
      "/api/admin/responsible/type/all/?category=ins",
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: incidentRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/incidents/all/",
    "GET",
    true,
    "",
    false,
    false
  );
  const {
    handleSubmit,
    getValues,
    setError,
    control,
    reset,
    clearErrors,
    formState: { errors },
    resetField,
  } = useForm<FormValues>();
  const isFilterSet = useSelector(registerFilterSet);
  const treeSpeciesList = useSelector(speciesList);
  const deleteItem = useSelector(registerFilterDelete);

  const dispatch = useDispatch();
  useEffect(() => {
    if (deleteItem === "date") {
      resetField("dateTo");
      resetField("dateFrom");
    } else if (deleteItem === "collaboratorType") {
      resetField("collaboratorType");
      resetField("collaboratorName");
    } else if (deleteItem !== "") {
      resetField(deleteItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteItem]);
  useEffect(() => {
    if (psaRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          psa: psaRes.data,
        };
      });
    }
    if (insRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          ins: insRes.data,
        };
      });
    }
    if (funRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          fun: funRes.data,
        };
      });
    }
    if (speRes) {
      dispatch(selectFieldAction.setSpeciesList(speRes.data));
    }
    if (incidentRes) {
      setSelectOption((prevState) => {
        return {
          ...prevState,
          incidents: [
            {name_transes: {en: "All", es: "Todos"}, slug: "all"},
            ...incidentRes.data,
          ],
        };
      });
    }
  }, [funRes, insRes, psaRes, speRes, dispatch, incidentRes]);

  const applyFilter = (data: FormValues) => {
    const dateT =
      !data.dateTo || !data.dateTo?.getDate()
        ? ""
        : `${data.dateTo.getDate()}/${
            data.dateTo.getMonth() + 1
          }/${data.dateTo.getFullYear()}`;
    const dateF =
      !data.dateFrom || !data.dateFrom?.getDate()
        ? ""
        : `${data.dateFrom.getDate()}/${
            data.dateFrom.getMonth() + 1
          }/${data.dateFrom.getFullYear()}`;
    if (data.dateFrom > data.dateTo) {
      setError("dateFrom", {
        type: "custom",
        message: `${t(
          "Message.The start date must be smaller or equal than the end date."
        )}`,
      });
      return;
    }
    dispatch(
      registerFilterAction.setFilter({
        dateTo: dateT,
        dateFrom: dateF,
        collaboratorType: `${
          data.collaboratorType === undefined ? "" : data.collaboratorType
        }`,
        collaborator: data.collaboratorName,
        treeSpecies: data.treeSpecies,
        incidents: data.incidents || [],
        other: data.other || [],
      })
    );
    dispatch(registerFilterAction.setURL());
    dispatch(registerFilterAction.isFilterSet());
  };

  const clearFilter = () => {
    reset();
    dispatch(registerFilterAction.clearFilter());
    dispatch(registerFilterAction.setURL());
  };

  const otherItems: SpeciesList[] = [
    {
      name_transes: { en: "Has description", es: "Tiene descripción" },
      slug: "has_description",
    },
    {
      name_transes: { en: "Out of country", es: "Fuera del país" },
      slug: "out_of_country",
    },
    {
      name_transes: { en: "Unknown tag", es: "Etiqueta desconocida" },
      slug: "unknown_tag",
    },
    {
      name_transes: { en: "Old tag", es: "Etiqueta antigua" },
      slug: "abnormal_tag",
    },
  ];

  return (
    <ThemeProvider theme={plantingTheme}>
      <div className="w-full h-fit bg-white p-4 rounded-md relative mt-3">
        <span
          title={t("Tree Species.Advanced filter") + ""}
          className={`${
            advanceFilter ? "text-ph-grayD" : "text-ph-btn"
          } float-right  cursor-pointer`}
          onClick={() => {
            isAdvanceFilter(!advanceFilter);
          }}
        >
          <FontAwesomeIcon
            className="w-[21px] h-[21px]"
            icon={advanceFilter ? faXmark : faFilterList}
          />
        </span>
        <br />
        <form onSubmit={handleSubmit(applyFilter)} className="mt-4">
          <div className="flex flex-wrap justify-between w-full sm:w-[80%] md:w-[65%]">
            <div className="w-full lg:w-[48%]">
              <p className="text-sm text-default-text mb-[6px]">
                {t("Register.Planting date")}
              </p>
              <div className="flex items-center">
                <CustomDate
                  clearError={clearErrors}
                  control={control}
                  name="dateFrom"
                  label={t("PlantingFilter.From")}
                />

                <span className="px-2 text-default-text text-xs">&#9866;</span>

                <CustomDate
                  control={control}
                  name="dateTo"
                  label={t("PlantingFilter.To")}
                />
              </div>
              {errors?.dateFrom && (
                <p className="error-text">
                  {t(
                    "Message.The start date must be smaller or equal than the end date."
                  )}
                </p>
              )}
            </div>
            <div className="w-full lg:w-[48%] mt-5 lg:mt-0">
              <p className="text-sm text-default-text mb-[6px]">
                {t("PlantingFilter.Trees")}
              </p>
              <CustomAutocomplete
                control={control}
                name="treeSpecies"
                selectOptions={treeSpeciesList}
                placeholder={t("Navbar.Tree species")}
                getOptionLabel={(option) =>
                  i18n.language === "en"
                    ? option.name_transes?.en === ""
                      ? option.name_transes?.es
                      : option.name_transes?.en
                    : option.name_transes?.es
                }
              />
            </div>
          </div>
          {advanceFilter && (
            <Fragment>
              <div className="flex flex-wrap justify-between w-full sm:w-[80%] md:w-[65%] mt-5">
                <div className="w-full lg:w-[48%]">
                  <p className="text-sm text-default-text mb-[6px]">
                    {t("Register.Category")}
                  </p>
                  <Controller
                    control={control}
                    name="collaboratorType"
                    render={({ field: { onChange, value } }) => (
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel shrink={false}>
                          {!value && t("Register.Category")}
                        </InputLabel>
                        <Select
                          style={{ height: "38px" }}
                          IconComponent={ExpandMoreIcon}
                          variant="outlined"
                          value={value || null}
                          onChange={(e) => {
                            onChange(e as ChangeEvent<Element>);
                            resetField("collaboratorName");
                            dispatch(
                              selectFieldAction.setCollaboratorNames(
                                e.target.value + ""
                              )
                            );
                          }}
                          sx={{
                            border: "1px solid #eeecec",
                            borderRadius: "4px",
                            "& fieldset": {
                              border: "none",
                            },
                          }}
                        >
                          <MenuItem value="fun">FUNDAEC</MenuItem>
                          <MenuItem value="grp">{t("Register.Group")}</MenuItem>
                          <MenuItem value="ind">
                            {t("Register.Individual")}
                          </MenuItem>
                          <MenuItem value="ins">
                            {t("Register.Institution")}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                </div>
                <div className="w-full lg:w-[48%] mt-5 lg:mt-0">
                  <p
                    className={`${
                      getValues("collaboratorType") === undefined
                        ? "text-ph-light-gray"
                        : "text-default-text"
                    } text-sm mb-[6px]`}
                  >
                    {t("Tree Species.Type")}
                  </p>
                  <CustomAutocomplete
                    control={control}
                    name="collaboratorName"
                    selectOptions={
                      getValues("collaboratorType") === "fun"
                        ? selectOption.fun
                        : getValues("collaboratorType") === "ins"
                        ? selectOption.ins
                        : selectOption.psa
                    }
                    placeholder={t("Tree Species.Type")}
                    disabled={
                      getValues("collaboratorType") &&
                      getValues("collaboratorType") !== "ind"
                        ? false
                        : true
                    }
                    getOptionLabel={(option) => option.name}
                  />
                </div>
              </div>

              <div className="flex flex-wrap justify-between w-full sm:w-[80%] md:w-[65%] mt-5">
                <div className="w-full lg:w-[48%]">
                  <p className="text-sm text-default-text mb-[6px]">
                    {t("Incidents.Incidents")}
                  </p>
                  <CustomAutocomplete
                    control={control}
                    name="incidents"
                    isMulti={true}
                    selectOptions={selectOption.incidents}
                    placeholder={t("Incidents.Incidents")}
                    getOptionLabel={(option) =>
                      i18n.language === "en"
                        ? option.name_transes?.en === ""
                          ? option.name_transes?.es
                          : option.name_transes?.en
                        : option.name_transes?.es
                    }
                    allValue={{name_transes: {en: "All", es: "Todos"}, slug: "all"}}
                  />
                </div>

                <div className="w-full lg:w-[48%] mt-5 lg:mt-0">
                  <p className="text-sm text-default-text mb-[6px]">
                    {t("Register.Other")}
                  </p>
                  <CustomAutocomplete
                    control={control}
                    name="other"
                    isMulti={true}
                    selectOptions={otherItems}
                    placeholder={t("Register.Other")}
                    getOptionLabel={(option) =>
                      i18n.language === "en"
                        ? option.name_transes?.en
                        : option.name_transes?.es
                    }
                  />
                </div>
              </div>
            </Fragment>
          )}
          <div className="mt-5 sm:mt-0 sm:absolute sm:right-4 sm:bottom-4">
            {isFilterSet && (
              <button
                className="py-2 px-6 text-sm font-medium rounded-3xl text-default-text hover:bg-ph-input mr-3"
                type="button"
                onClick={clearFilter}
              >
                {t("PlantingFilter.Reset")}
              </button>
            )}

            <button
              type="submit"
              className="white-btn border border-ph-btn text-sm font-medium py-2 px-6"
            >
              {t("PlantingFilter.Apply")}
            </button>
          </div>
        </form>
      </div>
    </ThemeProvider>
  );
};
export default FilterBox;
