export const handleExport = (
  exportResponse: any
) => {
  if (exportResponse) {
    const blob = new Blob([exportResponse.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);

    const fileName = exportResponse.headers['content-disposition'].split("=")[1];

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();

    window.URL.revokeObjectURL(url);
  }
};
