import {
  faCircleMinus,
  faCirclePlus,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NumberField: React.FC<{
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  register: any;
  name: string;
  setValue: any;
  getValues: any;
  clearError?: any;
  styleClass?: string;
  readOnl: boolean;
  inputStyle: string;
  invalidInput?: boolean;
  maxValue?: number;
  disabled?: boolean;
}> = ({
  onChange,
  placeholder,
  register,
  name,
  setValue,
  getValues,
  clearError,
  styleClass,
  readOnl,
  inputStyle,
  invalidInput,
  maxValue,
  disabled,
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = parseInt(e.target.value, 10);

    if (!isNaN(value) && value >= 0) {
      if (maxValue && value > maxValue) {
        value = maxValue;
      }
      setValue(name, value);
      if (clearError) {
        clearError(name);
      }
    } else {
      setValue(name, "");
      if (clearError) {
        clearError(name);
      }
    }

    onChange(e);
  };

  const baseClass = "text-sm px-10 border  outline-none rounded-[4px] w-full";
  const readOnlyClass = "text-ph-white bg-ph-bg-gray h-12 border-ph-iborder";
  const editableClass =
    "bg-ph-input focus:bg-white focus:border-default-highlight";
  const errorClass = invalidInput ? "border-red-500" : "border-ph-iborder";

  // Combine the styles based on the readOnl flag
  const inputClassName = readOnl
    ? `${baseClass} ${readOnlyClass} ${inputStyle}`
    : `${baseClass} ${editableClass} ${inputStyle} ${errorClass}`;

  return (
    <div className={`relative ${styleClass}`}>
      <button
        type="button"
        onClick={() => {
          if (!readOnl) {
            if (getValues(name) > 0) {
              setValue(name, Number(getValues(name)) - 1);
            }
          }
        }}
        className={`number-field-icons top-[27%] left-2 ${
          readOnl ? "bg-ph-bg-white" : ""
        }`}
        disabled={disabled}
      >
        <FontAwesomeIcon icon={faCircleMinus} size="lg" />
      </button>
      <input
        {...register}
        type="number"
        readOnly={readOnl}
        onChange={handleInputChange}
        placeholder={placeholder}
        className={inputClassName}
        disabled={disabled}
      />
      <button
        type="button"
        onClick={() => {
          if (clearError) {
            clearError(name);
          }
          if (!readOnl) {
            const currentValue = Number(getValues(name));
            if (maxValue) {
              if (currentValue < maxValue!!) {
                setValue(name, currentValue + 1);
              } else {
                setValue(name, maxValue);
              }
            } else {
              if (getValues(name) > 0) {
                setValue(name, Number(getValues(name)) + 1);
              } else {
                setValue(name, 1);
              }
            }
          }
        }}
        className={`number-field-icons top-[27%] right-2 ${
          readOnl ? "bg-ph-bg-white" : ""
        }`}
        disabled={disabled}
      >
        <FontAwesomeIcon icon={faCirclePlus} size="lg" />
      </button>
    </div>
  );
};

export default NumberField;
