/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react";
import useAxios from "../../../hook/useAxios";
import { Department } from "../../../type/department";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faHandHoldingSeedling,
  faInbox,
  faTrees,
  faUser,
} from "@fortawesome/pro-light-svg-icons";
import { t } from "i18next";
import GenericTable from "../../Global/GenericTable";
import { LocationReport } from "../../../type/locationReport";
import { TableCell } from "@mui/material";
import { useSelector } from "react-redux";
import { reportPlantedURL } from "../../../store";

interface ReportFilter {
  dateTo: Date;
  dateFromReport: Date | null | "";
  level: string;
  department: Department[] | null;
  community: Department[] | null;
}

const ReportsLocationView: React.FC<{ reportData: ReportFilter }> = ({
  reportData,
}) => {
  const [page, setPage] = useState<number>(1);
  const [ordering, setOrdering] = useState<string>("");
  const [count, setCount] = useState<number>(0);
  const [dataList, setDataList] = useState<LocationReport[]>([]);
  const url = useSelector(reportPlantedURL);
  const { fetchData, response, loading } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/reports/planted_trees${url}&page=${page}&ordering=${ordering}`,
    "GET",
    true,
    "",
    true,
    false
  );
  function validateCounts(data: any) {
    const keysToCheck = [
      "department_community_count",
      "planting_events",
      "planted_trees",
    ];

    return keysToCheck.every((key) => data?.[key] > 0);
  }

  useEffect(() => {
    if (response) {
      setCount(response.data.count);
      setDataList(response.data.results);
    }
  }, [response]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const order = (value: string) => {
    setOrdering(value);
  };
  return validateCounts(response?.data) ? (
    reportData.level === "N" ? (
      <div className="flex flex-col p-6 bg-ph-white">
        <h1 className="font-semibold text-default-text text-[14px] mb-5">
          {t("Reports.Location")}
        </h1>
        <span className="flex flex-col md:flex-row mb-5 md:mb-0 border-[1px] border-ph-input border-opacity-100 p-4">
          <FontAwesomeIcon
            className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-[16px]"
            icon={faBuilding}
          />
          <div className="mr-5 md:mr-[50px] lg:mr-[100px] xl:mr-[150px]">
            <p className="text-sm text-ph-gray">{t("Navbar.Departments")}</p>
            <p className="text-sm text-default-text font-medium">
              {response?.data?.department_community_count}
            </p>
          </div>
          <FontAwesomeIcon
            className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-[16px]"
            icon={faHandHoldingSeedling}
          />
          <div className="mr-5 md:mr-[50px] lg:mr-[100px] xl:mr-[150px]">
            <p className="text-sm text-ph-gray">
              {t("Help videos.Planting events")}
            </p>
            <p className="text-sm text-default-text font-medium">
              {response?.data?.planting_events}
            </p>
          </div>
          <FontAwesomeIcon
            className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-[16px]"
            icon={faTrees}
          />
          <div className="mr-5 md:mr-[50px] lg:mr-[100px] xl:mr-[150px]">
            <p className="text-sm text-ph-gray">
              {t("Statistics.Planted trees")}
            </p>
            <p className="text-sm text-default-text font-medium">
              {response?.data?.planted_trees}
            </p>
          </div>
          <FontAwesomeIcon
            className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-[16px]"
            icon={faUser}
          />
          <div className="mr-5 md:mr-[50px] lg:mr-[100px] xl:mr-[150px]">
            <p className="text-sm text-ph-gray">
              {t("PlantingFilter.Participants")}
            </p>
            <p className="text-sm text-default-text font-medium">
              {response?.data?.participants_total}
            </p>
          </div>
        </span>
        <span className="flex flex-col mt-[20px] mb-5 md:mb-0 border-[1px] border-ph-input border-opacity-100 p-4">
          <span className="flex flex-col md:flex-row">
            <div className="mr-5 md:mr-[50px] lg:mr-[100px] xl:mr-[200px]">
              <p className="text-sm text-ph-gray mt-2 md:mt-0">
                {t("Statistics.Total participants")}
              </p>
              <p className="text-sm text-default-text font-medium">
                {response?.data?.participants_total}
              </p>
            </div>
            <div className="mr-5 md:mr-[50px] lg:mr-[100px] xl:mr-[200px]">
              <p className="text-sm text-ph-gray mt-2 md:mt-0">
                {t("Workshops.PSA students")}
              </p>
              <p className="text-sm text-default-text font-medium">
                {response?.data?.psa_students}
              </p>
            </div>
            <div className="mr-5 md:mr-[50px] lg:mr-[100px] xl:mr-[180px]">
              <p className="text-sm text-ph-gray mt-2 md:mt-0">
                {t("Workshops.School students")}
              </p>
              <p className="text-sm text-default-text font-medium">
                {response?.data?.school_students}
              </p>
            </div>
            <div className="mr-5">
              <p className="text-sm text-ph-gray mt-2 md:mt-0">
                {t("Workshops.PSA tutors")}
              </p>
              <p className="text-sm text-default-text font-medium">
                {response?.data?.psa_tutors}
              </p>
            </div>
          </span>
          <span className="flex flex-col md:flex-row mt-[32px]">
            <div className="mr-5 md:mr-[50px] lg:mr-[100px] xl:mr-[205px]">
              <p className="text-sm text-ph-gray mt-2 md:mt-0">
                {t("Workshops.School teachers")}
              </p>
              <p className="text-sm text-default-text font-medium">
                {response?.data?.school_teachers}
              </p>
            </div>
            <div className="mr-5">
              <p className="text-sm text-ph-gray mt-2 md:mt-0">
                {t("Workshops.Other participants")}
              </p>
              <p className="text-sm text-default-text font-medium">
                {response?.data?.other_participants}
              </p>
            </div>
          </span>
        </span>
      </div>
    ) : (
      <div className="flex flex-col p-6  bg-ph-white">
        <h1 className="font-semibold text-default-text text-[14px] mb-5">
          {t("Reports.Location")}
        </h1>
        <span className="flex flex-col md:flex-row mb-5 md:mb-0 border-[1px] border-ph-input border-opacity-100 p-4">
          <FontAwesomeIcon
            className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-[16px]"
            icon={faBuilding}
          />
          <div className="mr-5 mb-7 md:mb-0 md:mr-[50px] lg:mr-[100px] xl:mr-[150px]">
            <p className="text-sm text-ph-gray">
              {reportData.level === "D"
                ? t("Navbar.Departments")
                : t("Community.Communities")}
            </p>
            <p className="text-sm text-default-text font-medium">
              {response?.data?.department_community_count}
            </p>
          </div>
          <FontAwesomeIcon
            className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-[16px]"
            icon={faHandHoldingSeedling}
          />
          <div className="mr-5 md:mr-[50px] lg:mr-[100px] xl:mr-[150px] mb-7 md:mb-0">
            <p className="text-sm text-ph-gray">
              {t("Help videos.Planting events")}
            </p>
            <p className="text-sm text-default-text font-medium">
              {response?.data?.planting_events}
            </p>
          </div>
          <FontAwesomeIcon
            className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-[16px]"
            icon={faTrees}
          />
          <div className="mr-5 md:mr-[50px] lg:mr-[100px] xl:mr-[150px] mb-7 md:mb-0">
            <p className="text-sm text-ph-gray">
              {t("Statistics.Planted trees")}
            </p>
            <p className="text-sm text-default-text font-medium">
              {response?.data?.planted_trees}
            </p>
          </div>
          <FontAwesomeIcon
            className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-[16px]"
            icon={faUser}
          />
          <div className="mr-5 md:mr-[50px] lg:mr-[100px] xl:mr-[150px]">
            <p className="text-sm text-ph-gray">
              {t("PlantingFilter.Participants")}
            </p>
            <p className="text-sm text-default-text font-medium">
              {response?.data?.participants_total}
            </p>
          </div>
        </span>
        <span className="flex flex-row md:flex-col mt-[20px] mb-5 border-[1px] border-ph-input border-opacity-100 p-4">
          <span className="flex flex-col md:flex-row">
            <div className="mr-5 md:mr-[50px] lg:mr-[100px] xl:mr-[200px] mb-7 md:mb-0">
              <p className="text-sm text-ph-gray mt-2 md:mt-0">
                {t("Statistics.Total participants")}
              </p>
              <p className="text-sm text-default-text font-medium">
                {response?.data?.participants_total}
              </p>
            </div>
            <div className="mr-5 md:mr-[50px] lg:mr-[100px] xl:mr-[200px] mb-7 md:mb-0">
              <p className="text-sm text-ph-gray mt-2 md:mt-0">
                {t("Workshops.PSA students")}
              </p>
              <p className="text-sm text-default-text font-medium">
                {response?.data?.psa_students}
              </p>
            </div>
            <div className="mr-5 md:mr-[50px] lg:mr-[100px] xl:mr-[180px] mb-7 md:mb-0">
              <p className="text-sm text-ph-gray mt-2 md:mt-0">
                {t("Workshops.School students")}
              </p>
              <p className="text-sm text-default-text font-medium">
                {response?.data?.school_students}
              </p>
            </div>
            <div className="mr-5">
              <p className="text-sm text-ph-gray mt-2 md:mt-0">
                {t("Workshops.PSA tutors")}
              </p>
              <p className="text-sm text-default-text font-medium">
                {response?.data?.psa_tutors}
              </p>
            </div>
          </span>
          <span className="flex flex-col md:flex-row mt-[20px] md:mt-[32px]">
            <div className="mr-5 md:mr-[50px] lg:mr-[100px] xl:mr-[205px] mb-7 md:mb-0">
              <p className="text-sm text-ph-gray mt-2 md:mt-0">
                {t("Workshops.School teachers")}
              </p>
              <p className="text-sm text-default-text font-medium">
                {response?.data?.school_teachers}
              </p>
            </div>
            <div className="mr-5">
              <p className="text-sm text-ph-gray mt-2 md:mt-0">
                {t("Workshops.Other participants")}
              </p>
              <p className="text-sm text-default-text font-medium">
                {response?.data?.other_participants}
              </p>
            </div>
          </span>
        </span>
        <GenericTable
          countData={count}
          handleChange={handleChange}
          dataList={dataList}
          fetchData={() => {
            fetchData({});
          }}
          loading={loading}
          setOrderingList={order}
          showAction={false}
          titles={[
            reportData.level === "D"
              ? { name: t("Plantings.Department"), value: "department" }
              : { name: t("Community.Community"), value: "community" },
            {
              name: t("Help videos.Planting events"),
              value: "planting_events",
            },
            { name: t("Statistics.Planted trees"), value: "planted_trees" },
            {
              name: t("PlantingFilter.Participants"),
              value: "total_participants",
            },
          ]}
          renderItem={(departments) => (
            <Fragment>
              <TableCell align="left">
                {departments.department_community_name}
              </TableCell>
              <TableCell>{departments.planting_events}</TableCell>
              <TableCell>{departments.planted_trees}</TableCell>
              <TableCell>{departments.participants}</TableCell>
              <TableCell></TableCell>
            </Fragment>
          )}
        />
      </div>
    )
  ) : (
    <div className="parent-container">
      <p className="m-auto text-center font-medium text-2xl text-default-text">
        <FontAwesomeIcon className="mr-4" icon={faInbox} />
        {t("Message.No results found")}
      </p>
    </div>
  );
};

export default ReportsLocationView;
