import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SpeciesBaseId } from "../type/SpeciesBaseId";

type SpeciesFilter = {
  type: SpeciesBaseId | null | undefined;
  abundance: string;
};
const initialState: {
  filterItem: SpeciesFilter;
  url: string;
  filterSet: boolean;
  deleteFilter: "type" | "abundance" | "";
} = {
  filterItem: {
    abundance: "",
    type: null,
  },
  url: "/species/?",
  filterSet: false,
  deleteFilter: "",
};

const speciesFilterSlice = createSlice({
  name: "speciesFilter",
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<SpeciesFilter>) => {
      state.filterItem = action.payload;
      state.deleteFilter = "";
    },
    removeType: (state) => {
      state.filterItem.type = null;
      state.deleteFilter = "type";
    },
    removeAbundance: (state) => {
      state.filterItem.abundance = "";
      state.deleteFilter = "abundance";
    },
    setURL: (state) => {
      state.url = `/species/?abundance=${state.filterItem.abundance === undefined ? "" : state.filterItem.abundance}&type=${state.filterItem.type ? state.filterItem.type.id : ""}`;
    },
    clearFilter: (state) => {
      state.filterSet = false;
      state.filterItem.abundance = "";
      state.filterItem.type = null;
    },
    isFilterSet: (state) => {
      if (!state.filterItem.type && !state.filterItem.abundance) {
        state.filterSet = false;
      } else {
        state.filterSet = true;
      }
    },
  },
});

export const speciesFilterAction = speciesFilterSlice.actions;
export default speciesFilterSlice;
