import { Fragment, useEffect, useState } from "react";
import useAxios from "../../../hook/useAxios";
import { Department } from "../../../type/department";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faInbox, faUsers } from "@fortawesome/pro-light-svg-icons";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { donorsSponsorsReportURL } from "../../../store";
import { DonorsTrainingSummery } from "../../../type/donorsTrainingSummery";
import { TableCell } from "@mui/material";
import ReportTable from "./ReportTable";

interface ReportFilter {
  dateTo: Date;
  dateFromReport: Date | null | "";
  level: string;
  department: Department[] | null;
  community: Department[] | null;
}

const DonorsSponsorsReportsTrainingParticipantsView: React.FC<{
  reportData: ReportFilter;
}> = ({ reportData }) => {
  const [summery, setSummery] = useState<DonorsTrainingSummery>();
  const url = useSelector(donorsSponsorsReportURL);

  const { response: trainingSummeryResponse } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/reports/donors/training_participants/summery${url}`,
    "GET",
    true,
    "",
    true,
    false
  );

  useEffect(() => {
    if (trainingSummeryResponse) {
      setSummery(trainingSummeryResponse.data);
    }
  }, [trainingSummeryResponse]);
  return summery?.total_participants ? (
    <div className="flex flex-col p-6 bg-ph-white">
      <h1 className="font-semibold text-default-text text-[14px] mb-5">
        {t("Reports.Training participants")}
      </h1>
      <div className="flex justify-center sm:justify-start mb-5 border-[1px] border-ph-input border-opacity-100 p-4">
        {reportData.level !== "L" ? (
          <FontAwesomeIcon
            className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-[16px]"
            icon={faBuilding}
          />
        ) : (
          <FontAwesomeIcon
            className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-[16px]"
            icon={faUsers}
          />
        )}
        <div>
          <p className="text-sm text-ph-gray">
            {reportData.level !== "L"
              ? t("Navbar.Departments")
              : t("Community.Communities")}
          </p>
          <p className="text-sm text-default-text font-medium">
            {reportData.level !== "L"
              ? summery?.departments
              : summery?.communities}
          </p>
        </div>
      </div>

      <span className="flex flex-wrap text-center sm:text-left mb-5 border-[1px] border-ph-input border-opacity-100 p-4">
        <div className="w-full sm:w-[280px] mb-7">
          <p className="text-sm text-ph-gray">
            {t("Statistics.Total participants")}
          </p>
          <p className="text-sm text-default-text font-medium">
            {summery?.total_participants}
          </p>
        </div>
        <div className="w-full sm:w-[280px] mb-7">
          <p className="text-sm text-ph-gray">{t("Workshops.PSA students")}</p>
          <p className="text-sm text-default-text font-medium">
            {summery?.psa_students}
          </p>
        </div>
        <div className="w-full sm:w-[280px] mb-7">
          <p className="text-sm text-ph-gray">
            {t("Workshops.School students")}
          </p>
          <p className="text-sm text-default-text font-medium">
            {summery?.school_students}
          </p>
        </div>
        <div className="w-full sm:w-[280px] mb-7">
          <p className="text-sm text-ph-gray">{t("Workshops.PSA tutors")}</p>
          <p className="text-sm text-default-text font-medium">
            {summery?.psa_tutors}
          </p>
        </div>
        <div className="w-full sm:w-[280px] mb-7">
          <p className="text-sm text-ph-gray">
            {t("Workshops.School teachers")}
          </p>
          <p className="text-sm text-default-text font-medium">
            {summery?.school_tutors}
          </p>
        </div>
        <div className="w-full sm:w-[280px] mb-7">
          <p className="text-sm text-ph-gray">
            {t("Workshops.Other participants")}
          </p>
          <p className="text-sm text-default-text font-medium">
            {summery?.other_participants}
          </p>
        </div>
      </span>
      {reportData.level !== "N" && (
        <ReportTable
          url={`donors/training_participants/list${url}`}
          titles={[
            reportData.level === "D"
              ? { name: t("Plantings.Department"), value: "department" }
              : { name: t("Community.Community"), value: "community" },
            {
              name: t("Statistics.Total participants"),
              value: "total_participants",
            },
            { name: t("Workshops.PSA students"), value: "psa_students" },
            { name: t("Workshops.School students"), value: "school_students" },
            { name: t("Workshops.PSA tutors"), value: "psa_tutors" },
            { name: t("Workshops.School teachers"), value: "school_tutors" },
            {
              name: t("Workshops.Other participants"),
              value: "other_participants",
            },
          ]}
          renderItem={(participantsListItem) => (
            <Fragment>
              {reportData.level === "D" ? (
                <TableCell align="left">
                  {participantsListItem.department}
                </TableCell>
              ) : (
                <TableCell align="left">
                  {participantsListItem.community}
                </TableCell>
              )}
              <TableCell align="left">
                {participantsListItem.total_participants}
              </TableCell>
              <TableCell align="left">
                {participantsListItem.psa_students}
              </TableCell>
              <TableCell align="left">
                {participantsListItem.school_students}
              </TableCell>
              <TableCell align="left">
                {participantsListItem.psa_tutors}
              </TableCell>
              <TableCell align="left">
                {participantsListItem.school_tutors}
              </TableCell>
              <TableCell align="left">
                {participantsListItem.other_participants}
              </TableCell>
            </Fragment>
          )}
        />
      )}
    </div>
  ) : (
    <div className="parent-container">
      <p className="m-auto text-center font-medium text-2xl text-default-text">
        <FontAwesomeIcon className="mr-4" icon={faInbox} />
        {t("Message.No results found")}
      </p>
    </div>
  );
};

export default DonorsSponsorsReportsTrainingParticipantsView;
