import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type PlantingOrganizerFilter = {
  isActive: string;
  category: string;
};
const initialState: {
  filterItem: PlantingOrganizerFilter;
  url: string;
  filterSet: boolean;
  deleteFilter: "category" | "isActive" | "";
} = {
  filterItem: {
    isActive: "",
    category: "",
  },
  url: "/organizers/?",
  filterSet: false,
  deleteFilter: "",
};

const plantingOrganizerFilterSlice = createSlice({
  name: "plantingOrganizerFilter",
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<PlantingOrganizerFilter>) => {
      state.filterItem = action.payload;
    },
    setURL: (state) => {
      state.url = `/organizers/?is_active=${state.filterItem.isActive}&category=${state.filterItem.category}`;
    },
    removeCategory: (state) => {
      state.filterItem.category = "";
      state.deleteFilter = "category";
    },
    removeStatus: (state) => {
      state.filterItem.isActive = "";
      state.deleteFilter = "isActive";
    },
    clearFilter: (state) => {
      state.filterSet = false;
      state.filterItem.isActive = "";
      state.filterItem.category = "";
    },
    isFilterSet: (state) => {
      if (!state.filterItem.isActive && !state.filterItem.category) {
        state.filterSet = false;
      } else {
        state.filterSet = true;
      }
    },
  },
});

export const plantingOrganizerFilterAction =
  plantingOrganizerFilterSlice.actions;
export default plantingOrganizerFilterSlice;
