import { faArrowDownToLine } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

const FileResource: React.FC<{
  address: string;
  id: number;
}> = ({ address, id }) => {
  const format = address.split(".");
  const realFormat = format[format.length - 1];
  const { t } = useTranslation();
  return (
    <div className="flex flex-row items-center justify-between">
      <div className="flex flex-row items-center w-[40%] sm:w-1/2">
        {(realFormat.toLowerCase() === "svg" ||
          realFormat.toLowerCase() === "png" ||
          realFormat.toLowerCase() === "jpg" ||
          realFormat.toLowerCase() === "jpeg") && (
          <img alt="img" src={address} className="w-[22px] h-[22px]" />
        )}

        {realFormat === "pdf" && (
          <img
            alt="img"
            src="/img/file-pdf.svg"
            className="w-[31px] h-[42px]"
          />
        )}
        {realFormat === "txt" && (
          <img
            alt="img"
            src="/img/file-txt.svg"
            className="w-[31px] h-[42px]"
          />
        )}
        {(realFormat === "docx" || realFormat === "doc") && (
          <img
            alt="img"
            src="/img/file-doc.svg"
            className="w-[31px] h-[42px]"
          />
        )}
        {(realFormat === "xlsx" || realFormat === "xls") && (
          <img
            alt="img"
            src="/img/file-excel.svg"
            className="w-[31px] h-[42px]"
          />
        )}
        <div className="text-sm ml-2 h-5 max-w-[100px] overflow-hidden whitespace-nowrap text-ellipsis font-medium text-default-text">
          <span>{address.split("/")[5]}</span>
        </div>
      </div>

      <a
        href={address}
        target="_blank"
        rel="noreferrer"
        className="text-sm text-default-text flex flex-row w-[40%] sm:w-[22%] items-center"
      >
        <FontAwesomeIcon
          className="text-ph-gray"
          icon={faArrowDownToLine}
          size="lg"
        />

        <div className="ml-3">{t("Tree Species.Download")}</div>
      </a>
    </div>
  );
};

export default FileResource;
