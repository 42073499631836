import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import Warning from "../../ErrorAndAlert/Warning";
import Status from "../../Global/Status";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ThemeProvider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useAxios from "../../../hook/useAxios";
import { CategoryBaseName } from "../../../type/CategoryBaseName";
import { organizerCategoryDropdown } from "../../../type/organizerCategoryDropdown";
import { theme } from "../../Register/AddRegister/FormTheme";

type FormValues = {
  name: string;
  category: CategoryBaseName;
};

const AddPlantingOrganizerForm: React.FC<{
  send: (data: any) => void;
  sendResponse: any;
  getInfo?: any;
  textButton: string;
  type: string;
}> = ({ send, sendResponse, getInfo, textButton, type }) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [dirty, isDirty] = useState<boolean>(true);
  const [active, isActive] = useState<boolean>(true);
  const history = useHistory();

  const [categoryDropDown, setCategoryDropDown] =
    useState<organizerCategoryDropdown>({
      organizer_category_transes: [],
    });
  const { response: baseResponse } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/base_data/",
    "GET",
    true,
    "",
    true,
    false
  );
  useEffect(() => {
    if (baseResponse) {
      setCategoryDropDown(baseResponse.data);
    }
  }, [baseResponse]);
  useEffect(() => {
    if (sendResponse) {
      toast.success(
        `${getInfo
          ? `${t("Message.The record updated successfully")}`
          : `${t("Message.The record added successfully")}`
        }`
      );
      history.replace(`/setting/organizers/${type}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendResponse]);

  useEffect(() => {
    if (getInfo) {
      setLoading(true);
      setValue("name", getInfo.data.name);
      setValue("category", getInfo.data.category);
      isActive(getInfo.data.is_active);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInfo]);
  const {
    handleSubmit,
    setValue,
    register,
    control,
    formState: { errors },
  } = useForm<FormValues>();

  const addCollaborator = (data: FormValues) => {
    isDirty(false);
    send({
      type: type,
      name: data.name,
      category: data.category,
      is_active: active,
    });
  };

  return (
    <Fragment>
      {loading && getInfo ? (
        <p className="text-center text-2xl font-bold">
          {t("Message.Loading")}
        </p>
      ) : (
        <form onSubmit={handleSubmit(addCollaborator)}>
          <div className="flex flex-wrap justify-between mt-5">
            <div
              className={`${type !== "ins" ? "w-full" : "w-full sm:w-1/2 md:pr-2"
                } `}
            >
              <p className="text-default-text text-sm mb-[6px]">
                {t("Tree Species.Name")} *
              </p>
              <input
                {...register("name", {
                  required: `${t("Message.Required field")}`,
                })}
                type="text"
                className="input-field pl-4 py-3 text-sm placeholder-ph-light-gray"
                placeholder={t("Tree Species.Name") + ""}
              />
              {errors.name && (
                <p className="error-text">{errors.name.message}</p>
              )}
            </div>
            {type === "ins" && (
              <div className="w-full sm:w-1/2 sm:pl-2 mt-7 sm:mt-0">
                <p className="text-sm text-ph-dark-text mb-[6px]">
                  {t("Register.Category")} *
                </p>
                <ThemeProvider theme={theme}>
                  <Controller
                    control={control}
                    rules={{ required: `${t("Message.Required field")}` }}
                    name="category"
                    render={({ field: { onChange, value } }) => (
                      <FormControl style={{ width: "100%" }}>
                        <InputLabel shrink={false}>
                          {!value && `${t("Register.Category")}`}
                        </InputLabel>
                        <Select
                          style={{ height: "46px" }}
                          IconComponent={ExpandMoreIcon}
                          variant="outlined"
                          value={value || null}
                          onChange={(e) => {
                            onChange(e as ChangeEvent<Element>);
                          }}
                          sx={{
                            border: "1px solid #eeecec",
                            borderRadius: "4px",
                            "& fieldset": { border: "none" },
                          }}
                        >
                          {categoryDropDown.organizer_category_transes.map(
                            (cat) => (
                              <MenuItem key={cat.value} value={cat.value}>
                                {i18n.language === "en" ? cat.en : cat.es}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    )}
                  />
                </ThemeProvider>
                {errors.category && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
            )}
          </div>
          <div className="mt-7">
            <Status
              active={active}
              isActive={(value) => {
                isActive(value);
              }}
            />
          </div>

          <button
            type="submit"
            className="green-btn text-sm  px-6 py-2 mt-5 float-right font-medium"
          >
            {textButton}
          </button>
        </form>
      )}
      <Warning when={dirty} onCancel={() => false} onOK={() => true} />
    </Fragment>
  );
};

export default AddPlantingOrganizerForm;
