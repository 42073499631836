/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import ReportsGeneral from "../ReportsGeneral";
import useAxios from "../../../hook/useAxios";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from "chart.js";
import { Department } from "../../../type/department";
import { TemplateType } from "../../../type/templateType";
import SpeciesList from "../../../type/speciesList";
import NoDataAvailable from "../../Global/NoDataAvailable";
import { useSelector } from "react-redux";
import { momentReportFilterSet, momentReportURL } from "../../../store";
import PlantingMomentFilterApplied from "./PlantingMomentFilterApplied";
import { handleExport } from "../../Global/ExportReport";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

type FormValues = {
  templateName: TemplateType;
  dateTo: Date;
  dateFromReport: Date | null | "";
  level: string;
  view: string;
  department: Department[] | null;
  incidents?: SpeciesList[] | null;
  year: string;
};

const PlantingMomentReport = () => {
  const { t, i18n } = useTranslation();
  const [departmentsData, setDepartmentsData] = useState<Department[]>([]);
  const [incidentsData, setIncidentsData] = useState<SpeciesList[]>([]);
  const [years, setYears] = useState<string[]>([]);
  const [templateList, setTemplateList] = useState<TemplateType[]>([]);
  const [filterData, setFilterData] = useState<FormValues | null>(null);
  const url = useSelector(momentReportURL);
  const isFilter = useSelector(momentReportFilterSet);
  const [chartPlantingData, setChartPlantingData] = useState<ChartData<"bar">>({
    labels: [],
    datasets: [],
  });
  const [chartOccurrenceData, setOccurrenceChartData] = useState<
    ChartData<"bar">
  >({
    labels: [],
    datasets: [],
  });
  const { fetchData: getPlantingMomentReport, response: reportRes } = useAxios(
    process.env.REACT_APP_API_URL + `/api/admin/reports/planting_moments${url}`,
    "GET",
    false,
    "",
    true,
    true
  );
  const { response: exportResponse, fetchData: getExport } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/reports/export/planting_moments${url}`,
    "GET",
    false,
    t("Message.Successful Export"),
    true,
    true,
    "",
    i18n.language,
    "blob"
  );
  const { response: departmentRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/departments/all/",
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: incidentRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/incidents/all/",
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: yearRes } = useAxios(
    process.env.REACT_APP_API_URL +
      "/api/admin/reports/planting_moments/year/ddl/",
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: templateRes, fetchData: fetchTemplates } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/templates/?type=PM",
    "GET",
    true,
    "",
    true,
    false
  );
  const { fetchData, response: fetchTemplate } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/templates/new/",
    "POST",
    false,
    "",
    true,
    true
  );
  const colorPalette = [
    "#5DA5DA", // Light blue
    "#60BD68", // Light green
    "#F15854", // Bright red
    "#B276B2", // Soft violet
    "#DECF3F", // Soft yellow
    "#FAA43A", // Vibrant orange
    "#4D4D4D", // Neutral gray
    "#F17CB0", // Bright pink
    "#76B7B2", // Soft teal
    "#FF9F40", // Warm peach
  ];
  let colorIndex = 0;
  const getNextColor = () => {
    const color = colorPalette[colorIndex];
    colorIndex = (colorIndex + 1) % colorPalette.length;
    return color;
  };

  useEffect(() => {
    if (!isFilter) {
      setFilterData(null);
    }
  }, [isFilter]);

  useEffect(() => {
    if (filterData) {
      getPlantingMomentReport({});
    }
  }, [filterData]);

  useEffect(() => {
    if (templateRes) {
      setTemplateList(templateRes.data);
    }
    if (yearRes) {
      setYears(yearRes.data.year);
    }
    if (departmentRes) {
      setDepartmentsData([{ name: "All", slug: "all" }, ...departmentRes.data]);
    }
    if (incidentRes) {
      setIncidentsData([
        { name_transes: { en: "All", es: "Todos" }, slug: "all" },
        ...incidentRes.data,
      ]);
    }
    if (exportResponse) {
      handleExport(exportResponse);
    }
  }, [departmentRes, incidentRes, templateRes, yearRes, exportResponse]);

  useEffect(() => {
    if (reportRes) {
      const plantings = reportRes?.data?.plantings[0];
      const occurrence = reportRes?.data?.occurrence;

      const labels = Object.keys(plantings);
      const data = Object.values(plantings);

      const occurrenceLabels = occurrence.map((item: any) =>
        i18n.language === "en"
          ? item.species_name_transes.en || item.species_name_transes.es
          : item.species_name_transes.es
      );
      const datasets: {
        label: string;
        data: number[];
        backgroundColor: string;
        stack: string;
      }[] = [];

      const incidentColors: Record<string, string> = {};

      occurrence.forEach((species: any, idx: number) => {
        species.incidents.forEach((incident: any) => {
          const incidentName =
            incident.name_transes.en ||
            incident.name_transes.es ||
            "Unknown Incident";
          const count = incident.count;

          if (!incidentColors[incidentName]) {
            incidentColors[incidentName] = getNextColor();
          }

          const existingDataset = datasets.find(
            (dataset) => dataset.label === incidentName
          );
          if (existingDataset) {
            existingDataset.data[idx] = count;
          } else {
            const newDataset = {
              label: incidentName,
              data: Array(occurrence.length).fill(0),
              backgroundColor: incidentColors[incidentName],
              stack: "stack1",
            };
            newDataset.data[idx] = count;
            datasets.push(newDataset);
          }
        });
      });

      setOccurrenceChartData({
        labels: occurrenceLabels,
        datasets: datasets.map((dataset) => ({
          ...dataset,
          maxBarThickness: 30,
          backgroundColor: dataset.backgroundColor,
        })),
      });
      setChartPlantingData({
        labels,
        datasets: [
          {
            label: `${t("Reports.Trees Planted")}`,
            data: data as number[],
            backgroundColor: "#7EB48B",
          },
        ],
      });
    }
  }, [reportRes]);

  const handleApplyFilter = (data: FormValues) => {
    setFilterData(data);
  };

  const options: ChartOptions<"bar"> = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 100,
        },
        title: {
          display: true,
          color: "#414141",
          font: { size: 14, family: "Inter" },
          text: `${t("Reports.Trees planted each month")}`,
        },
      },
      x: {
        title: {
          display: true,
          color: "#414141",
          font: { size: 14, family: "Inter" },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: `${t(
          "Reports.Number of Trees Planted Each Month Throughout the Year"
        )}`,
      },
    },
  };

  const occurrenceOptions: ChartOptions<"bar"> = {
    indexAxis: "y",
    responsive: true,
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          font: { size: 14, family: "Inter" },
          color: "#414141",
          text: `${t("Reports.Number of plantings with incidents")}`,
        },
        ticks: {
          stepSize: 10,
        },
      },
      y: {
        title: {
          display: true,
          font: { size: 14, family: "Inter" },
          color: "#414141",
          text: `${t("Plantings.Species")}`,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 20,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          boxWidth: 8,
          boxHeight: 8,
          generateLabels: (chart) => {
            const datasets = chart.data.datasets;
            return datasets.map((dataset, idx) => ({
              text: dataset.label || "Unknown",
              fillStyle: dataset.backgroundColor as string,
              strokeStyle: dataset.backgroundColor as string,
              hidden: !chart.isDatasetVisible(idx),
              index: idx,
              pointStyle: "circle",
            }));
          },
        },
      },
      title: {
        display: true,
        text: `${t("Reports.Number of Trees With Incidents by Species")}`,
      },
    },
  };

  return (
    <>
      <ReportsGeneral
        header={t("Reports.Planting moments in the year")}
        type={"moments"}
        lists={{
          templateList: templateList,
          departmentList: departmentsData,
          incidentList: incidentsData,
          years: years,
        }}
        onApplyFilter={handleApplyFilter}
        onSaveTemplate={fetchData}
        templateResponse={fetchTemplate}
        templateList={fetchTemplates}
        exportResponse={() => {
          getExport({});
        }}
        filterData={filterData}
        chartPlantingData={chartPlantingData}
        chartOccurrenceData={chartOccurrenceData}
      />
      <div className="parent-container">
        {filterData && <PlantingMomentFilterApplied />}
        {filterData ? (
          <>
            <div className="font-medium text-sm mt-8 bg-ph-bg-white py-3 text-ph-dark-gray px-5">
              {t("Plantings.Plantings")}
            </div>
            <div className="p-4 bg-white rounded-md">
              {filterData && <Bar data={chartPlantingData} options={options} />}
            </div>
            <div className="font-medium text-sm mt-8 bg-ph-bg-white py-3 text-ph-dark-gray px-5">
              {t("Reports.Occurrences")}
            </div>
            <div className="p-4 bg-white rounded-md">
              {filterData && (
                <Bar data={chartOccurrenceData} options={occurrenceOptions} />
              )}
            </div>
          </>
        ) : (
          <NoDataAvailable
            detailText={t(
              "Reports.Please select the report filters and press Apply."
            )}
          />
        )}
      </div>
    </>
  );
};

export default PlantingMomentReport;
