import { useTranslation } from "react-i18next";
import TranslationField from "../../../type/translationField";

const TreesDetail: React.FC<{
  num: number;
  trees: { species_name_transes: TranslationField; register_count: number }[];
  index?: boolean;
  scroll?: boolean;
}> = ({ trees, index, num, scroll }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="tree-number">
      {num}
      <table
        className={`tree-info ${trees.length <= 10 ? "h-fit" : "h-[24rem]"
          } !overflow-y-auto absolute z-10 shadow-2xl rounded-md text-center bg-white px-4 py-1  ${scroll
            ? "top-[20px]"
            : index
              ? "bottom-[20px]"
              : "top-[20px]"
          }`}
      >
        <thead>
          <tr className="border-b border-b-gray-200">
            <th className="w-32">{t("Plantings.Species")}</th>
            <th>Nr.</th>
          </tr>
        </thead>
        <tbody>
          {trees.map((tree, index) => (
            <tr className="text-[#939393]" key={index}>
              <td className="py-2">
                {i18n.language === "en"
                  ? tree.species_name_transes.en || tree.species_name_transes.es
                  : tree.species_name_transes.es}
              </td>
              <td>{tree.register_count}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TreesDetail;
