import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";

const AppliedItem: React.FC<{
  type: string;
  value: string;
  removeFilter: () => void;
  isReportFilter?: boolean;
}> = ({ type, value, removeFilter, isReportFilter }) => {
  const removerFilter = () => {
    removeFilter();
  };
  return (
    <div className="applied-filter-item">
      <p className="text-xs text-default-text">
        {type}{" "}
        <span className="font-medium text-default-text">
          {value === "" ? "" : `: ${value}`}
        </span>
      </p>
      {!isReportFilter ? (
        <span className="relative text-sm pl-2" onClick={removerFilter}>
          <FontAwesomeIcon icon={faXmark} />
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

export default AppliedItem;
