import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark, faUser } from "@fortawesome/pro-solid-svg-icons";
import { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Dropdown from "./Dropdown";
import Wrapper from "../Wrapper";
import SettingMenu from "./SettingMenu";
import { useTranslation } from "react-i18next";
import useAxios from "../../hook/useAxios";
import { useDispatch, useSelector } from "react-redux";
import { LoginSliceActions } from "../../store/login-slice";
import { profileInfo } from "../../store";
import i18next from "i18next";
import Tools from "./Tools";
import { colors } from "../../styles/colors";
import ReportsMenu from "./ReportsMenu";

const Navbar: React.FC<{
  showUserProfile: () => void;
}> = ({ showUserProfile }) => {
  const [click, isClick] = useState<boolean>(false);
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const { response } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/user/profile/",
    "GET",
    true,
    "",
    false,
    false
  );
  const dispatch = useDispatch();
  const profile = useSelector(profileInfo);
  useEffect(() => {
    if (response) {
      dispatch(LoginSliceActions.profile(response.data));
      i18next.changeLanguage(response.data.language);
    }
  }, [response, dispatch]);

  const toggle = () => {
    isClick(!click);
  };

  const setPlantingIsActive = () => {
    if (location.pathname.includes("/plantings")) {
      return true;
    } else {
      return false;
    }
  };

  const setRegisterIsActive = () => {
    if (location.pathname.includes("/register")) {
      return true;
    } else {
      return false;
    }
  };

  const setStatisticIsActive = () => {
    if (location.pathname.includes("/statistics")) {
      return true;
    } else {
      return false;
    }
  };

  const setMapIsActive = () => {
    if (location.pathname.includes("/map")) {
      return true;
    } else {
      return false;
    }
  };

  const setWorkshopIsActive = () => {
    if (location.pathname.includes("/workshops")) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <nav className="bg-white ">
      <Wrapper>
        <div className="sm:flex bg-white justify-between px-6 md:px-[72px] py-4 items-center">
          <div className="flex justify-between items-center">
            <Link to="/">
              <img
                src={
                  i18n.language === "en"
                    ? "/img/EnglishMenuLogo.png"
                    : "/img/TE logo left align.svg"
                }
                alt="logo"
                className="h-16"
              />
            </Link>

            <span onClick={toggle} className="cursor-pointer block sm:hidden">
              <FontAwesomeIcon icon={click ? faXmark : faBars} />
            </span>
          </div>

          <div
            className={`${
              click ? "inline-block" : "hidden"
            } sm:flex items-center`}
          >
            <Dropdown />
            <div
              className="cursor-pointer flex items-center"
              onClick={showUserProfile}
            >
              <h1 className="mt-4 sm:mt-0 text-default-text block sm:inline-block text-sm">
                {t("Navbar.Hi")},{" "}
                <span className="font-semibold">{profile.first_name}</span>
              </h1>
              {profile.profile_picture ? (
                <img
                  className="w-9 h-9 object-cover rounded-sm mt-4 sm:mt-0 ml-3"
                  alt="profile"
                  src={profile.profile_picture}
                />
              ) : (
                <div className="flex items-center justify-center w-9 h-9 object-cover rounded-sm mt-4 sm:mt-0 ml-3 bg-ph-input">
                  <FontAwesomeIcon
                    className="text-[#9D9A9ABD] text-xl"
                    icon={faUser}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Wrapper>
      <div className="w-full border-t-2 border-gray-200 sm:py-2">
        <Wrapper>
          <div
            className={`${
              click ? "block" : "hidden"
            }  sm:flex justify-between items-center bg-white px-6 md:px-[72px] py-2`}
          >
            <div>
              <NavLink
                exact
                to="/statistics"
                isActive={setStatisticIsActive}
                activeStyle={{
                  color: colors.default_highlight,
                }}
                className="mr-4 lg:mr-8 font-medium text-default-text block sm:inline-block text-sm hover:text-default-highlight"
                onClick={toggle}
              >
                {t("Statistics.Statistics")}
              </NavLink>
              <NavLink
                exact
                to="/plantings"
                isActive={setPlantingIsActive}
                activeStyle={{
                  color: colors.default_highlight,
                }}
                className="mr-4 lg:mr-8 font-medium text-default-text block sm:inline-block mt-6 sm:mt-0 text-sm hover:text-default-highlight"
                onClick={toggle}
              >
                {t("Navbar.Planting events")}
              </NavLink>
              <NavLink
                exact
                to="/registers/all"
                isActive={setRegisterIsActive}
                activeStyle={{
                  color: colors.default_highlight,
                }}
                className="mr-4 lg:mr-8 font-medium text-default-text block sm:inline-block mt-6 sm:mt-0 text-sm hover:text-default-highlight"
                onClick={toggle}
              >
                {t("Navbar.Tree registers")}
              </NavLink>
              <NavLink
                exact
                to="/map"
                isActive={setMapIsActive}
                activeStyle={{
                  color: colors.default_highlight,
                }}
                className="mr-4 lg:mr-8 font-medium text-default-text block sm:inline-block mt-6 sm:mt-0 text-sm hover:text-default-highlight"
                onClick={toggle}
              >
                {t("Navbar.Map")}
              </NavLink>
              <NavLink
                exact
                to="/workshops"
                isActive={setWorkshopIsActive}
                activeStyle={{
                  color: colors.default_highlight,
                }}
                className={`${
                  i18n.language === "es" ? "mr-6 md:mr-8" : "mr-4 lg:mr-8"
                } font-medium text-default-text block sm:inline-block mt-6 sm:mt-0 text-sm hover:text-default-highlight`}
                onClick={toggle}
              >
                {t("Workshops.Workshops")}
              </NavLink>
              {profile.role !== "V" && (
                <div
                  className={`block sm:inline-block ${
                    i18n.language === "es" ? "mr-6 md:mr-8" : "mr-2 lg:mr-8"
                  }  mt-5 sm:mt-0`}
                >
                  <Tools toggle={toggle} />
                </div>
              )}
              {(profile.role === "A" || profile.role === "C") && (
                <div className="block sm:inline-block ml-[10px] sm:ml-0 mr-2 lg:mr-5 mt-5 sm:mt-0">
                  <ReportsMenu toggle={toggle} />
                </div>
              )}
              <div className="block sm:inline-block mr-7 mt-5 sm:mt-0">
                <SettingMenu toggle={toggle} />
              </div>
            </div>
          </div>
        </Wrapper>
      </div>
    </nav>
  );
};

export default Navbar;
