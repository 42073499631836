import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSeedling, faClockRotateLeft, faHandHoldingHeart } from "@fortawesome/pro-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { profileInfo } from "../../store";
import { colors } from "../../styles/colors";

const ReportsMenu: React.FC<{ toggle: () => void }> = ({ toggle }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const location = useLocation();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const profile = useSelector(profileInfo);

  const theme = createTheme({
    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: colors.default_text,
            fontFamily: "Inter,sans-serif",
            ":hover": {
              backgroundColor: "white",
            },
            "&.menu-item-content:hover": {
              color: colors.default_highlight,
              backgroundColor: "white",
              "& .icon": {
                color: colors.default_highlight,
              },
              "& .help": {
                color: colors.default_highlight,
              },
            },
            "&.Mui-selected": {
              color: colors.default_highlight,
              backgroundColor: "white",
              "& .icon": {
                color: colors.default_highlight,
              },
              "& .help": {
                color: colors.default_highlight,
              },
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            color: colors.default_text,
            fontFamily: "Inter,sans-serif",
            padding: "0px",
            marginLeft: "-12px",
            fontWeight: "500",
            textTransform: "capitalize",
            fontSize: "14px",
            ":hover": {
              color: colors.default_highlight,
              backgroundColor: "white",
              "& .icon": {
                color: colors.default_highlight,
              },
              "& .help": {
                color: colors.default_highlight,
              },
            },
            ":focus": {
              backgroundColor: "white",
            },
            "&[aria-selected=true]": {
              color: colors.default_highlight,
              "& .icon": {
                color: colors.default_highlight,
              },
              "& .help": {
                color: colors.default_highlight,
              },
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        aria-selected={location.pathname.includes("/reports")}
        onClick={handleClick}
      >
        {t("Reports.Reports")}
      </Button>
      <Menu
        style={{ overflow: "clip" }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {(profile.role === "A" || profile.role === "C") && (
          <>
            <MenuItem
              selected={location.pathname.includes("/reports/planted-trees")}
              onClick={handleClose}
              className="menu-item-content text-default-text"
            >
              <Link
                onClick={toggle}
                to="/reports/planted-trees"
                className="text-sm font-medium py-[2px] hover:text-default-highlight w-full inline-block"
              >
                <FontAwesomeIcon icon={faSeedling}  className="mr-2 text-ph-menu-gray icon" />
                {t("Statistics.Planted trees")}
              </Link>
            </MenuItem>
            <MenuItem
              selected={location.pathname.includes("/reports/moments")}
              onClick={handleClose}
              className="menu-item-content text-default-text"
            >
              <Link
                onClick={toggle}
                to="/reports/moments"
                className="text-sm font-medium py-[2px] hover:text-default-highlight w-full inline-block"
              >
                <FontAwesomeIcon icon={faClockRotateLeft}  className="mr-2 text-ph-menu-gray icon" />
                {t("Reports.Planting moments in the year")}
              </Link>
            </MenuItem>
            <MenuItem
              selected={location.pathname.includes("/reports/donors-sponsors")}
              onClick={handleClose}
              className="menu-item-content text-default-text"
            >
              <Link
                onClick={toggle}
                to="/reports/donors-sponsors"
               className="text-sm font-medium py-[2px] hover:text-default-highlight w-full inline-block"
              >
                <FontAwesomeIcon icon={faHandHoldingHeart}  className="mr-2 text-ph-menu-gray icon" />
                {t("Reports.Donors & Sponsors")}
              </Link>
            </MenuItem>
          </>
        )}
      </Menu>
    </ThemeProvider>
  );
}
export default ReportsMenu;