import { useEffect, useState } from "react";
import { DonorsTrainingList } from "../../../type/donorsTrainingList";
import GenericTable from "../../Global/GenericTable";
import useAxios from "../../../hook/useAxios";

const ReportTable: React.FC<{
  url: string;
  titles: {
    name: string;
    value: string;
  }[];
  renderItem: (item: any) => JSX.Element;
}> = ({ url, titles, renderItem}) => {
  const [dataList, setDataList] = useState<DonorsTrainingList[]>([]);
  const [page, setPage] = useState<number>(1);
  const [ordering, setOrdering] = useState<string>("");
  const [count, setCount] = useState<number>(0);
  const [callRefresh , isCallRefresh] = useState<boolean>(false);

  const {
    fetchData: getReportList,
    response: reportListResponse,
    loading: reportListLoading,
  } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/reports/${url}&page=${page}&ordering=${ordering}`,
    "GET",
    true,
    "",
    callRefresh,
    false
  );

  useEffect(() => {
    if (reportListResponse) {
      isCallRefresh(false);
      setCount(reportListResponse.data.count);
      setDataList(reportListResponse.data.results);
    }
  }, [reportListResponse]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    isCallRefresh(true);
    setPage(value);
  };

  const order = (value: string) => {
    isCallRefresh(true);
    setOrdering(value);
  };
  return (
    <GenericTable
      countData={count}
      handleChange={handleChange}
      dataList={dataList}
      fetchData={() => {
        getReportList({});
      }}
      loading={reportListLoading}
      setOrderingList={order}
      showAction={false}
      titles={titles}
      renderItem={renderItem}
    />
  );
};

export default ReportTable;
