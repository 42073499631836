import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OldFile from "../../Global/OldFile";
import Video from "../AddPlanting/Video";
import { Link} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faFlag,
  faLandmark,
  faUsers,
} from "@fortawesome/pro-light-svg-icons";

const PlantingView: React.FC<{
  getInfo: any;
}> = ({ getInfo }) => {
  const [planting, setPlanting] = useState<any>();
  const { i18n, t } = useTranslation();
  useEffect(() => {
    if (getInfo) {
      setPlanting(getInfo.data);
    }
  }, [getInfo]);
  return (
    <Fragment>
      {planting && (
        <div className="flex flex-wrap justify-between w-full h-fit lg:w-[48%] sc:w-[559px]">
          <div className="w-full lg:w-[611px] h-fit sc:w-[620px] bg-white rounded-md relative">
            <div className="flex justify-start m-[30px] flex-col border-[1px] border-ph-input border-opacity-100  p-4">
              <div className="flex flex-col md:flex-row mb-8 mt-[24px] justify-between">
                <div className="flex flex-col mb-7 md:mb-0">
                  <p className="font-medium text-default-text text-[16px] mb-[11px]">
                    {i18n.language === "en"
                      ? planting.name_transes.en || planting.name_transes.es
                      : planting.name_transes.es}
                  </p>
                  <div className="flex flex-row items-center">
                    <p className="text-sm font-regular text-ph-gray mr-4">
                      {t("Statistics.Planted trees")}
                    </p>
                    <p className="text-default-text font-medium text-sm">
                      {planting.planted_trees}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col items-start">
                  <p className="mb-2 text-[12px] font-regular text-ph-gray ">
                    {t("Plantings.Date of planting")}
                  </p>
                  <p className="bg-ph-toggle-bg-green text-sm text-ph-toggle-text-green text-center opacity-100 py-1 px-4 rounded-[14px]">
                    {planting.date}
                  </p>
                </div>
              </div>
              <hr />
              <div className="flex flex-col md:flex-row mt-[40px] mb-[24px]">
                <div className="flex flex-col items-start text-left mr-[15px] md:mr-[138px]">
                  <div className="flex mb-10 flex-row">
                    <FontAwesomeIcon
                      className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-4"
                      icon={faFlag}
                    />
                    <div className="flex flex-col">
                      <p className="text-sm font-regular text-ph-gray mb-2">
                        {t("Plantings.Country")}
                      </p>
                      <p className="text-default-text font-medium text-sm">
                        {planting.project_country}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-row mb-10 md:mb-0">
                    <FontAwesomeIcon
                      className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-4"
                      icon={faLandmark}
                    />
                    <div className="flex flex-col">
                      <p className="text-sm font-regular text-ph-gray mb-2">
                        {t("Plantings.Municipality")}
                      </p>
                      <p className="text-default-text font-medium text-sm">
                        {planting.municipality}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-start text-left">
                  <div className="flex mb-10 flex-row">
                    <FontAwesomeIcon
                      className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-4"
                      icon={faBuilding}
                    />
                    <div className="flex flex-col">
                      <p className="text-sm font-regular text-ph-gray mb-2">
                        {t("PlantingFilter.Department")}
                      </p>
                      <p className="text-default-text font-medium text-sm">
                        {planting.department}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <FontAwesomeIcon
                      className="text-ph-gray w-[25px] h-[20px] my-[11px] mr-4"
                      icon={faUsers}
                    />
                    <div className="flex flex-col">
                      <p className="text-sm font-regular text-ph-gray mb-2">
                        {t("Plantings.Community")}
                      </p>
                      <p className="text-default-text font-medium text-sm">
                        {planting.community}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-start m-[30px] flex-col border-[1px] border-ph-input border-opacity-100  p-4">
              <p className="text-sm font-semibold text-default-text py-3">
                {t("Plantings.Organizer")}
              </p>
              <div className="flex flex-row justify-start mb-2 mt-[24px] ml-[10px]">
                <p className="text-sm text-ph-gray font-medium mr-[100px] md:mr-[216px]">
                  {t("Tree Species.Type")}
                </p>
                <p className="text-sm text-ph-gray font-medium">
                  {t("Tree Species.Name")}
                </p>
              </div>
              <hr />
              <div className="flex flex-row">
                <div className="flex flex-col mr-[62px] md:mr-[178px] ml-[10px]">
                  {planting.organizers.map((orginizer: any, index: number) => (
                    <p key={index} className="text-sm font-medium py-3">
                      {orginizer.type === "ind"
                        ? t("Register.Individual")
                        : orginizer.type === "psa"
                        ? t("Register.PSA group")
                        : t("Register.Institution")}
                    </p>
                  ))}
                </div>
                <div className="flex flex-col">
                  {planting.organizers.map((orginizer: any, index: number) => (
                    <p
                      key={orginizer.name}
                      className="text-sm font-medium py-3"
                    >
                      {orginizer.name}
                    </p>
                  ))}
                </div>
              </div>
            </div>

            <div className="flex justify-start m-[30px] flex-col border-[1px] border-ph-input border-opacity-100 w-[551px] p-4">
              <div>
                <p className="text-sm font-semibold text-default-text py-3">
                  {t("PlantingFilter.Participants")}
                </p>
                <div className="flex flex-row justify-start mb-4 mt-[24px]">
                  <div className="flex flex-col mr-[15px] md:mr-[138px]">
                    <div className="flex flex-col">
                      <p className="text-sm font-regular text-ph-gray mb-2">
                        {t("Workshops.PSA students")}
                      </p>
                      <p className="text-default-text font-medium text-sm">
                        {planting.psa_students}
                      </p>
                    </div>
                    <div className="flex flex-col mt-[40px]">
                      <p className="text-sm font-regular text-ph-gray mb-2">
                        {t("Workshops.PSA tutors")}
                      </p>
                      <p className="text-default-text font-medium text-sm">
                        {planting.psa_tutors}
                      </p>
                    </div>
                    <div className="flex flex-col mt-[40px]">
                      <p className="text-sm font-regular text-ph-gray mb-2">
                        {t("Workshops.Other participants")}
                      </p>
                      <p className="text-default-text font-medium text-sm">
                        {planting.other_participants}
                      </p>
                    </div>
                  </div>
                  <div className="flex mb-10 flex-col">
                    <div className="flex flex-col">
                      <p className="text-sm font-regular text-ph-gray mb-2">
                        {t("Workshops.School students")}
                      </p>
                      <p className="text-default-text font-medium text-sm">
                        {planting.school_students}
                      </p>
                    </div>
                    <div className="flex flex-col mt-[40px]">
                      <p className="text-sm font-regular text-ph-gray mb-2">
                        {t("Workshops.School teachers")}
                      </p>
                      <p className="text-default-text font-medium text-sm">
                        {planting.school_tutors}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {(planting.files.length > 0 || planting.video_files.length > 0) && (
              <div className="flex justify-start m-[30px] flex-col border-[1px] border-ph-input border-opacity-100  p-4">
                <p className="text-sm font-semibold text-default-text py-3 mb-[24px]">
                  {t("AddPlanting.Additional resources")}
                </p>

                {planting.files.map((file: any) => (
                  <OldFile
                    delete={false}
                    address={file.file_address}
                    id={file.id}
                    removeOldFile={() => {}}
                    key={file.id}
                  />
                ))}
                {planting.video_files.map((video: any, index: number) => (
                  <Video
                    key={index}
                    delete={false}
                    removeAction={() => {}}
                    title={video.title}
                    url={video.link}
                    videoIndex={index}
                  />
                ))}
              </div>
            )}

            {(planting.description_transes.en !== "" ||
              planting.description_transes.es !== "") && (
              <div className="flex justify-start m-[30px] flex-col border-[1px] border-ph-input border-opacity-100 p-4">
                <div>
                  <p className="text-sm font-regular text-ph-gray mb-2">
                    {t("Tree Species.Description")}
                  </p>
                </div>
                <div className="">
                  <p className="text-sm text-default-text font-medium max-w-[500px] max-h-[400px] break-words overflow-y-auto">
                    {i18n.language === "en"
                      ? planting.description_transes.en === ""
                        ? planting.description_transes.es
                        : planting.description_transes.en
                      : planting.description_transes.es}
                  </p>
                </div>
              </div>
            )}
            {(planting.comment_transes.en !== "" ||
              planting.comment_transes.es !== "") && (
              <div className="flex justify-start m-[30px] flex-col border-[1px] border-ph-input border-opacity-100 p-4">
                <div>
                  <p className="text-sm font-regular text-ph-gray mb-2">
                    {t("Plantings.Comment")}
                  </p>
                </div>
                <div className="">
                  <p className="text-sm text-default-text font-medium max-w-[500px] max-h-[400px] break-words overflow-y-auto">
                    {i18n.language === "en"
                      ? planting.comment_transes.en === ""
                        ? planting.comment_transes.es
                        : planting.comment_transes.en
                      : planting.comment_transes.es}
                  </p>
                </div>
              </div>
            )}

            <Link
              to = "/plantings"
              className="green-btn text-sm  px-6 py-2 mt-5  mb-[40px] mr-[90px] md:mr-[55px] float-right font-medium"
            >
              {t("Plantings.Back to plantings")}
            </Link>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default PlantingView;
