import { ThemeProvider } from "@emotion/react";
import { theme } from "../../Register/AddRegister/FormTheme";
import TextField from "@mui/material/TextField";
import { SelectChangeEvent } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import file from "../../../type/file";
import TranslationField from "../../../type/translationField";
import DragDropField from "../../Global/DragDropField";
import useAxios from "../../../hook/useAxios";
import OldFile from "../../Global/OldFile";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Switch from "@mui/material/Switch";
import dayjs, { Dayjs } from "dayjs";
import LanguagePoint from "../../Global/LanguagePoint";
import CustomDate from "../../Global/CustomDate";
import Participants from "../../Global/Participants";
import SelectLanguage from "../../Global/SelectLanguage";
import Warning from "../../ErrorAndAlert/Warning";
import { Department } from "../../../type/department";
import CustomAutocomplete from "../../Global/CustomAutocomplete";
import { Community } from "../../../type/community";
import { Municipality } from "../../../type/municipality";

type FormValues = {
  dateFrom: Date;
  dateTo: Date;
  timeFrom: Dayjs | null;
  timeTo: Dayjs | null;
  psaStudent: number;
  schoolStudent: number;
  psaTutor: number;
  schoolTeacher: number;
  other: number;
  municipality: Municipality;
  name: string;
  participants: string;
  department: Department;
  community: Community;
};

const AddWorkshopForm: React.FC<{
  send: (data: any) => void;
  departments: Department[];
  sendResponse: any;
  getInfo?: any;
  textButton: string;
}> = ({ send, departments, sendResponse, getInfo, textButton }) => {
  const { t } = useTranslation();
  const [lang, setLang] = useState<string>("ES");
  const [translatableInput, setTranslatableInput] = useState<{
    name: TranslationField;
    description: TranslationField;
  }>({
    name: { en: "", es: "" },
    description: { en: "", es: "" },
  });
  const [resources, setResources] = useState<file[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [uploading, isUploading] = useState<boolean>(false);
  const [municipalities, setMunicipalities] = useState<{
    municipality: Municipality[];
  }>({ municipality: [] });
  const [communities, setCommunities] = useState<{
    community: Community[];
  }>({ community: [] });
  const [oldResource, setOldResource] = useState<
    {
      id: number;
      file_address: string;
    }[]
  >([]);
  const [deleteRecourse, setDeleteResource] = useState<number[]>([]);
  const [requestNumber, setRequestNumber] = useState<number>(1);
  const [dirty, isDirty] = useState<boolean>(true);
  const [checked, setChecked] = useState<boolean>(false);
  const [fileIndex, setFileIndex] = useState<number>(0);
  const {
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    register,
    getValues,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      timeFrom: null,
      timeTo: null,
      psaStudent: 0,
      schoolStudent: 0,
      psaTutor: 0,
      schoolTeacher: 0,
      other: 0,
    },
  });
  const departmentValue = watch("department");
  const municipalityValue = watch("municipality");
  const { fetchData: sendFile, response: fileResp } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/files/new/",
    "POST",
    false,
    "",
    true,
    true,
    "multipart/form-data"
  );
  const { fetchData: deleteImage } = useAxios(
    "",
    "DELETE",
    false,
    "",
    true,
    true
  );
  const { response: municipalityRes, fetchData: getMunicipality } = useAxios(
    `${process.env.REACT_APP_API_URL}/api/admin/${departmentValue?.slug}/municipalities/all`,
    "GET",
    false,
    "",
    true,
    true
  );
  const { response: communityRes, fetchData: getCommunity } = useAxios(
    `${process.env.REACT_APP_API_URL}/api/admin/${municipalityValue?.slug}/communities/all/`,
    "GET",
    false,
    "",
    true,
    true
  );

  const history = useHistory();
  const handleLangChange = (event: SelectChangeEvent) => {
    setLang(event.target.value);
  };

  //getting municipality response
  useEffect(() => {
    if (municipalityRes) {
      if (municipalityRes.data) {
        setMunicipalities((prevState) => ({
          ...prevState,
          municipality: municipalityRes.data,
        }));
      }
    }
  }, [municipalityRes]);

  useEffect(() => {
    if (departmentValue?.slug) {
      getMunicipality({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentValue?.slug]);

  //getting community response
  useEffect(() => {
    if (communityRes) {
      if (communityRes.data) {
        setCommunities((prevState) => ({
          ...prevState,
          community: communityRes.data,
        }));
      }
    }
  }, [communityRes]);

  useEffect(() => {
    if (municipalityValue?.slug) {
      getCommunity({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [municipalityValue?.slug]);

  useEffect(() => {
    if (sendResponse) {
      isDirty(false);
      setRequestNumber((prevState) => prevState - 1);
      isUploading(true);
      if (resources.length > 0) {
        const formData = new FormData();
        formData.append("file_address", resources[fileIndex].data);
        formData.append("model_name", "Workshop");
        formData.append("input_slug", sendResponse.data.slug);
        sendFile(formData);
      }
      if (deleteRecourse.length > 0) {
        deleteRecourse.forEach((r) => {
          setRequestNumber((prevState) => prevState - 1);
          deleteImage(
            { id: r },
            process.env.REACT_APP_API_URL + `/api/admin/files/${r}/delete/`
          );
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendResponse]);

  useEffect(() => {
    if (getInfo) {
      setTranslatableInput({
        description: getInfo.data.description_transes,
        name: getInfo.data.name_transes,
      });
      const dateF: string[] = getInfo.data.date_from.split("/");
      const dateT: string[] = getInfo.data.date_to.split("/");
      setValue(
        "dateFrom",
        new Date(Number(dateF[2]), Number(dateF[1]) - 1, Number(dateF[0]))
      );
      setValue(
        "dateTo",
        new Date(Number(dateT[2]), Number(dateT[1]) - 1, Number(dateT[0]))
      );
      setValue(
        "timeFrom",
        getInfo.data.time_from
          ? dayjs(`2020-01-01 ${getInfo.data.time_from}`)
          : null
      );
      setValue(
        "timeTo",
        getInfo.data.time_to
          ? dayjs(`2020-01-01 ${getInfo.data.time_to}`)
          : null
      );
      setValue("department", {
        slug: getInfo.data.department_slug,
        name: getInfo.data.department_name,
      });
      setValue("community", {
        slug: getInfo.data.community_slug,
        name: getInfo.data.community_name,
      });
      setValue("municipality", {
        slug: getInfo.data.municipality_slug,
        name: getInfo.data.municipality_name,
      });
      setValue("psaStudent", getInfo.data.psa_students);
      setValue("schoolStudent", getInfo.data.school_students);
      setValue("psaTutor", getInfo.data.psa_tutors);
      setValue("schoolTeacher", getInfo.data.school_tutors);
      setValue("other", getInfo.data.other_participants);
      setOldResource(getInfo.data.files);
      setLoading(false);
      setChecked(getInfo.data.is_wholeday);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInfo]);

  useEffect(() => {
    if (fileResp) {
      setRequestNumber((prevState) => prevState - 1);
      setFileIndex(fileIndex + 1);
      if (resources.length - 1 >= fileIndex + 1) {
        const formData = new FormData();
        formData.append("file_address", resources[fileIndex + 1].data);
        formData.append("model_name", "Workshop");
        formData.append("input_slug", sendResponse.data.slug);
        sendFile(formData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileResp]);
  useEffect(() => {
    if (requestNumber === 0) {
      reset();
      toast.success(
        `${getInfo
          ? `${t("Message.The record updated successfully")}`
          : `${t("Message.The record added successfully")}`
        }`
      );
      history.replace("/workshops");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestNumber]);

  const nameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (lang === "EN") {
      setTranslatableInput((prevState) => {
        return {
          ...prevState,
          name: { en: event.target.value, es: prevState.name.es },
        };
      });
      if (event.target.value !== "") {
        clearErrors("name");
      }
    } else if (lang === "ES") {
      setTranslatableInput((prevState) => {
        return {
          ...prevState,
          name: { es: event.target.value, en: prevState.name.en },
        };
      });
      if (event.target.value !== "") {
        clearErrors("name");
      }
    }
  };

  const participantsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(event.target.value) > 0) {
      clearErrors("participants");
    }
  };
  const descriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (lang === "EN") {
      setTranslatableInput((prevState) => {
        return {
          ...prevState,
          description: {
            en: event.target.value,
            es: prevState.description.es,
          },
        };
      });
    } else if (lang === "ES") {
      setTranslatableInput((prevState) => {
        return {
          ...prevState,
          description: {
            es: event.target.value,
            en: prevState.description.en,
          },
        };
      });
    }
  };
  const insertResources = (files: file[]) => {
    files.forEach((f) => {
      if (!f.type.includes("image")) {
        toast.error(
          `${t("Message.The supported formats are “jpg/jpeg, png”")}`
        );
      } else if (f.size > 10000000) {
        toast.error(`${t("Message.Maximum file size is 10 MB")}`);
      } else {
        setRequestNumber((prevState) => prevState + 1);
        setResources((prevState) => [...prevState, f]);
      }
    });
  };

  const removeOldResource = (fileId: number) => {
    setDeleteResource((prevState) => [...prevState, fileId]);
    setOldResource((prevState) =>
      prevState.filter((prevItem) => prevItem.id !== fileId)
    );
    setRequestNumber((prevState) => prevState + 1);
  };
  const removeResource = (indexFile: number) => {
    setRequestNumber((prevState) => prevState - 1);
    setResources((prevState) =>
      prevState.filter((prevItem, index: number) => index !== indexFile)
    );
  };
  const addSpeciesItem = (data: FormValues) => {
    if (
      (translatableInput.name.en.trim() !== "" &&
        translatableInput.name.es.trim() === "") ||
      (translatableInput.description.en.trim() !== "" &&
        translatableInput.description.es.trim() === "")
    ) {
      toast.error(
        `${t(
          "Message.While other languages have a translation, the default language cannot be empty."
        )}`
      );
    } else {
      const timeF =
        data?.timeFrom?.hour() === undefined
          ? null
          : `${data?.timeFrom?.hour()}:${data?.timeFrom?.minute()}`;
      const timeT =
        data?.timeTo?.hour() === undefined
          ? null
          : `${data?.timeTo?.hour()}:${data?.timeTo?.minute()}`;
      send({
        name_transes: translatableInput.name,
        date_from: `${data.dateFrom.getDate()}/${data.dateFrom.getMonth() + 1
          }/${data.dateFrom.getFullYear()}`,
        date_to: `${data.dateTo.getDate()}/${data.dateTo.getMonth() + 1
          }/${data.dateTo.getFullYear()}`,
        time_from: timeF,
        time_to: timeT,
        is_wholeday: checked,
        psa_students: data.psaStudent || 0,
        school_students: data.schoolStudent || 0,
        psa_tutors: data.psaTutor || 0,
        school_tutors: data.schoolTeacher || 0,
        other_participants: data.other || 0,
        community: data.community.slug,
        description_transes: translatableInput.description,
      });
    }
  };
  return (
    <Fragment>
      {loading && getInfo ? (
        <p className="text-center text-2xl font-medium text-default-text">
          {t("Message.Loading")}
        </p>
      ) : (
        <form onSubmit={handleSubmit(addSpeciesItem)}>
          <ThemeProvider theme={theme}>
            <p className="text-sm text-default-text mb-[6px]">
              {t("AddPlanting.Language")}
            </p>
            <SelectLanguage handleLangChange={handleLangChange} lang={lang} />

            <div className="flex flex-wrap justify-between mt-7">
              <div className="w-full sm:w-1/2 sm:pr-2">
                <p className="text-sm text-default-text mb-[6px]">
                  {t("Tree Species.Name")} * <LanguagePoint lang={lang} />
                </p>
                <input
                  name="name"
                  onChange={nameChange}
                  value={
                    lang === "EN"
                      ? translatableInput.name.en
                      : translatableInput.name.es
                  }
                  type="text"
                  className="input-field pl-4 py-3 text-sm placeholder-ph-light-gray"
                  placeholder={t("Workshops.Workshop name") + ""}
                />
                {errors.name && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
              <div className="w-full mt-7 md:mt-0 sm:w-1/2 sm:pl-2">
                <p className="text-sm text-default-text mb-[6px]">
                  {t("PlantingFilter.Department")} *
                </p>
                <CustomAutocomplete
                  control={control}
                  name="department"
                  rules={{
                    required: `${t("Message.Required field")}`,
                  }}
                  selectOptions={departments}
                  placeholder={t("PlantingFilter.Department")}
                  getOptionLabel={(option) => option.name}
                  onCustomChange={() => {
                    setValue("municipality", {
                      slug: "",
                      name: "",
                    });
                    setValue("community", {
                      slug: "",
                      name: "",
                    });
                  }}
                />
                {errors.department && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
            </div>
            <div className="flex flex-wrap justify-between mt-7">
              <div className="w-full sm:w-1/2 sm:pr-2">
                <p
                  className={`${departmentValue?.slug === "" || !departmentValue?.slug
                      ? "text-ph-light-gray"
                      : "text-default-text"
                    } text-sm mb-[6px]`}
                >
                  {t("Community.Municipality")} *
                </p>
                <CustomAutocomplete
                  rules={{
                    required: `${t("Message.Required field")}`,
                    validate: (value: any) => {
                      if (!value || value.slug === "") {
                        return `${t("Message.Required field")}`;
                      }
                      return true;
                    },
                  }}
                  control={control}
                  name="municipality"
                  selectOptions={municipalities.municipality}
                  placeholder={t("Community.Municipality")}
                  getOptionLabel={(option) => option.name}
                  disabled={
                    departmentValue?.slug === "" || !departmentValue?.slug
                  }
                  onCustomChange={() => {
                    setValue("community", {
                      slug: "",
                      name: "",
                    });
                  }}
                />
                {errors.municipality && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>

              <div className="w-full sm:w-1/2 sm:pl-2">
                <p
                  className={`${municipalityValue?.slug === "" || !municipalityValue?.slug
                      ? "text-ph-light-gray"
                      : "text-default-text"
                    } text-sm mb-[6px] mt-7 sm:mt-0`}
                >
                  {t("Plantings.Community")} *
                </p>
                <CustomAutocomplete
                  rules={{
                    required: `${t("Message.Required field")}`,
                    validate: (value: any) => {
                      if (!value || value.slug === "") {
                        return `${t("Message.Required field")}`;
                      }
                      return true;
                    },
                  }}
                  control={control}
                  name="community"
                  selectOptions={communities.community}
                  placeholder={t("Plantings.Community")}
                  getOptionLabel={(option) => option.name}
                  disabled={
                    municipalityValue?.slug === "" || !municipalityValue?.slug
                  }
                />
                {errors.community && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
            </div>
            <div className="flex flex-wrap justify-between mt-7 mb-2">
              <div className="w-[48.5%]">
                <p className="text-sm text-default-text mb-[6px]">
                  {t("Workshops.Start date")} *
                </p>
                <CustomDate
                  clearError={clearErrors}
                  control={control}
                  name="dateFrom"
                  label={t("AddPlanting.Select date")}
                  rules={{
                    required: `${t("Message.Required field")}`,
                  }}
                />
                {errors.dateFrom && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
              <div className="w-[48.5%]">
                <p className="text-sm text-default-text mb-[6px]">
                  {t("Workshops.End date")} *
                </p>
                <CustomDate
                  clearError={clearErrors}
                  control={control}
                  name="dateTo"
                  label={t("PlantingFilter.To")}
                  rules={{
                    required: `${t("Message.Required field")}`,
                  }}
                />
                {errors.dateTo && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </div>
            </div>
            <div className="flex flex-wrap justify-between mt-6">
              <div className="w-[48.5%]">
                <p
                  className={`${checked && "text-ph-light-gray"
                    } text-sm text-default-text mb-[6px]`}
                >
                  {t("Workshops.Start time")}
                </p>
                <Controller
                  control={control}
                  name="timeFrom"
                  render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        disabled={checked}
                        label={value === null && "00:00 AM"}
                        value={value}
                        onChange={(newValue) => {
                          onChange(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              border: "1px solid #eeecec",
                              borderRadius: "4px",
                              "& fieldset": { border: "none" },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
              </div>

              <div className="w-[48.5%]">
                <p
                  className={`${checked && "text-ph-light-gray"
                    } text-sm text-default-text mb-[6px]`}
                >
                  {t("Workshops.End time")}
                </p>
                <Controller
                  control={control}
                  name="timeTo"
                  render={({ field: { onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        disabled={checked}
                        label={value === null && "00:00 AM"}
                        value={value}
                        onChange={(newValue) => {
                          onChange(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              border: "1px solid #eeecec",
                              borderRadius: "4px",
                              "& fieldset": { border: "none" },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                />
              </div>
            </div>
            <div className="mt-5 flex items-center">
              <p className="text-sm text-default-text mr-6">
                {t("Workshops.Whole day")}
              </p>{" "}
              <Switch
                color="warning"
                checked={checked}
                onChange={(e) => {
                  setChecked(e.target.checked);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
          </ThemeProvider>

          <p className="hr-lines">{t("PlantingFilter.Participants")} *</p>

          {errors.participants && (
            <p className="error-text">
              {t("Message.At least one field should be more than zero")}
            </p>
          )}

          <Participants
            clearErrors={clearErrors}
            getValues={getValues}
            participantsChange={participantsChange}
            register={register}
            setValue={setValue}
          />

          <p className="text-sm text-default-text mt-7 mb-[6px]">
            {t("Tree Species.Description")} <LanguagePoint lang={lang} />
          </p>
          <textarea
            onChange={descriptionChange}
            value={
              lang === "EN"
                ? translatableInput.description.en
                : translatableInput.description.es
            }
            rows={7}
            className="input-field resize-none text-sm  pl-4  py-[10px] placeholder-ph-light-gray"
            placeholder={t("Tree Species.Description") + ""}
          />
          <p className="text-sm text-default-text mt-7 mb-[6px]">
            {t("Workshops.Images")}
          </p>
          <DragDropField
            files={resources}
            format="jpg/jpeg, png (max. 10 MB)"
            insertFile={insertResources}
            removeFile={removeResource}
          />
          {oldResource.map((or) => (
            <OldFile
              address={or.file_address}
              id={or.id}
              delete={true}
              removeOldFile={removeOldResource}
              key={or.id}
            />
          ))}
          <button
            onClick={() => {
              if (
                translatableInput.name.es.trim() === "" &&
                translatableInput.name.en.trim() === ""
              ) {
                setError("name", {
                  type: "custom",
                  message: `${t("Message.Required field")}`,
                });
              }
              if (
                Number(getValues("psaStudent")) +
                Number(getValues("schoolStudent")) +
                Number(getValues("psaTutor")) +
                Number(getValues("schoolTeacher")) +
                Number(getValues("other")) ===
                0
              ) {
                setError("participants", {
                  type: "custom",
                  message: `${t(
                    "Message.At least one field should be more than zero"
                  )}`,
                });
              }
            }}
            type="submit"
            className={`${uploading ? "green-btn-disable" : "green-btn"
              } text-sm  px-6 py-2 mt-5 float-right font-medium`}
            disabled={uploading ? true : false}
          >
            {uploading ? t("Message.Wait for upload files") : textButton}
          </button>
        </form>
      )}
      <Warning when={dirty} onCancel={() => false} onOK={() => true} />
    </Fragment>
  );
};

export default AddWorkshopForm;
