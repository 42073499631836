import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Department } from "../type/department";

type ReportFilter = {
    view_by: string;
    level: string;
    date_to: string;
    date_from: string | null;
    departments: Department[];
    communities: Department[];

};
const initialState: {
    filterItem: ReportFilter;
    url: string;
    filterSet: boolean;
} = {
    filterItem: {
        level: "",
        view_by: "",
        date_to: "",
        date_from: "",
        departments: [],
        communities: []
    },
    url: "/?",
    filterSet: false
};

const donorsSponsorsReportFilterSlice = createSlice({
    name: "donorsSponsorsReportFilter",
    initialState,
    reducers: {
        setFilter: (state, action: PayloadAction<ReportFilter>) => {
            state.filterItem = action.payload;
        },
        setURL: (state) => {
            let dep = "";
            let com = "";
            let queryParams = [];

            if (state.filterItem.departments?.length !== 0) {
                state.filterItem?.departments?.forEach((c) => {
                    dep += `departments=${c.slug}&`;
                });
            }
            if (state.filterItem.communities?.length !== 0) {
                state.filterItem?.communities?.forEach((c) => {
                    com += `communities=${c.slug}&`;
                });
            }
            if (state.filterItem.date_from) {
                queryParams.push(`date_from=${state.filterItem.date_from}`);
            }
            if (state.filterItem.date_to) {
                queryParams.push(`date_to=${state.filterItem.date_to}`);
            }
            if (dep) {
                queryParams.push(dep.slice(0, -1));
            }
            if (com) {
                queryParams.push(com.slice(0, -1));
            }

            if (state.filterItem.level) {
                queryParams.push(`level=${state.filterItem.level}`);
            }
            state.url = `/?${queryParams.join("&")}`;
        },
        clearFilter: (state) => {
            state.filterSet = false;
            state.filterItem.level = "";
            state.filterItem.view_by = "";
            state.filterItem.date_to = "";
            state.filterItem.date_from = "";
            state.filterItem.departments = [];
            state.filterItem.communities = [];
        },
        isFilterSet: (state) => {
            if (!state.filterItem.view_by &&
                !state.filterItem.level &&
                !state.filterItem.date_to &&
                !state.filterItem.date_from &&
                state.filterItem.communities?.length === 0 &&
                state.filterItem.departments?.length === 0
            ) {
                state.filterSet = false;
            } else {
                state.filterSet = true;
            }
        },
    },
});

export const donorsSponsorsReportFilterAction = donorsSponsorsReportFilterSlice.actions;
export default donorsSponsorsReportFilterSlice;
