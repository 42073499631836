import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Warning from "../../ErrorAndAlert/Warning";
import CustomAutocomplete from "../../Global/CustomAutocomplete";
import i18n from "../../../i18n";
import useAxios from "../../../hook/useAxios";
import { ThemeProvider } from "@emotion/react";
import { theme } from "../../Register/AddRegister/FormTheme";
import { Nursery } from "../../../type/Nursery";
import NumberField from "../../Global/NumberField";
import { Community } from "../../../type/community";
import SpeciesList from "../../../type/speciesList";
import { ResponsibleNames } from "../../../type/responsibleNames";
import AddTaggingToolButton from "./AddTaggingToolButton";

type FormValues = {
  community_slug: Community;
  responsible_name: ResponsibleNames;
  nursery: Nursery;
  species: SpeciesList;
  number_of_tags: number;
};

const AddTaggingToolForm: React.FC<{
  send: (data: any) => void;
  selectOptions: {
    community: Community[];
  };
  sendResponse: any;
  nurseries: Nursery[];
  treeSpecies: SpeciesList[];
}> = ({ send, sendResponse, selectOptions, nurseries, treeSpecies }) => {
  const { t } = useTranslation();
  const [dirty, isDirty] = useState<boolean>(true);
  const [responsibleNames, setResponsibleNames] = useState<ResponsibleNames[]>(
    []
  );
  const [addNewTag, isAddNewTag] = useState<boolean>(false);
  const history = useHistory();
  const {
    handleSubmit,
    setValue,
    register,
    getValues,
    watch,
    resetField,
    clearErrors,
    control,
    formState: { errors },
  } = useForm<FormValues>();

  const communityValue = watch("community_slug");

  const { response: responsibleRes, fetchData: getResponsibleNames } = useAxios(
    `${process.env.REACT_APP_API_URL}/api/admin/${communityValue?.slug}/responsible_names/all`,
    "GET",
    false,
    "",
    true,
    true
  );

  useEffect(() => {
    if (sendResponse) {
      toast.success(t("Message.The record added successfully"));
      history.replace("/tools/tagging-tool");
    }
    if (addNewTag) {
      history.replace("/tools/tagging-tool/add-tag");
      resetField("community_slug");
      resetField("responsible_name");
      resetField("species");
      resetField("nursery");
      resetField("number_of_tags");
    }
    isAddNewTag(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendResponse]);

  useEffect(() => {
    if (communityValue) {
      getResponsibleNames({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityValue]);

  useEffect(() => {
    if (responsibleRes) {
      setResponsibleNames(responsibleRes.data);
    }
  }, [responsibleRes]);

  const generateTag = (data: FormValues) => {
    isDirty(false);
    send({
      community: data.community_slug.slug,
      responsible_name: data.responsible_name.slug,
      nursery: data.nursery.slug,
      species: data.species.slug,
      number_of_tags: data.number_of_tags,
    });
  };

  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <form onSubmit={handleSubmit(generateTag)}>
          <div className="flex flex-wrap justify-between">
            <div className="w-full sm:w-1/2 sm:pr-2">
              <p className="text-sm text-ph-dark-text mb-[6px]">
                {t("Community.Community")} *
              </p>
              <CustomAutocomplete
                rules={{
                  required: `${t("Message.Required field")}`,
                }}
                control={control}
                name="community_slug"
                selectOptions={selectOptions.community}
                placeholder={t("Community.Community")}
                getOptionLabel={(option) =>
                  option.name + " (" + option.initials + ")"
                }
                onCustomChange={() => {
                  resetField("responsible_name");
                }}
              />
              {errors.community_slug && (
                <p className="error-text">{t("Message.Required field")}</p>
              )}
            </div>

            <div className="w-full sm:w-1/2 sm:pl-2">
              <p
                className={`${
                  communityValue?.slug === "" || !communityValue?.slug
                    ? "text-ph-light-gray"
                    : ""
                } text-sm mb-[6px] mt-7 sm:mt-0`}
              >
                {t("Register.Responsible name")} *
              </p>
              <CustomAutocomplete
                rules={{
                  required: `${t("Message.Required field")}`,
                  validate: (value: any) => {
                    if (!value || value.slug === "") {
                      return `${t("Message.Required field")}`;
                    }
                    return true;
                  },
                }}
                control={control}
                name="responsible_name"
                selectOptions={responsibleNames}
                placeholder={t("Register.Responsible name")}
                getOptionLabel={(option) =>
                  option.full_name + " (" + option.initials + ")"
                }
                disabled={communityValue?.slug === "" || !communityValue?.slug}
              />
              {errors.responsible_name && (
                <p className="error-text">{t("Message.Required field")}</p>
              )}
            </div>
          </div>
          <div className="flex flex-wrap justify-between mt-7 items-center">
            <div className="w-full sm:w-1/2 sm:pr-2">
              <p className="text-sm text-ph-dark-text mb-[6px]">
                {t("Tagging tool.Nursery")} *
              </p>
              <CustomAutocomplete
                rules={{
                  required: `${t("Message.Required field")}`,
                }}
                control={control}
                name="nursery"
                selectOptions={nurseries}
                placeholder={t("Tagging tool.Nursery")}
                getOptionLabel={(option) =>
                  i18n.language === "en"
                    ? option.name_transes?.en || option.name_transes?.es
                    : option.name_transes?.es
                }
              />
              {errors.nursery && (
                <p className="error-text">{t("Message.Required field")}</p>
              )}
            </div>
            <div className="w-full sm:w-1/2 sm:pl-2 mt-7 md:mt-0">
              <p className="text-sm text-ph-dark-text mb-[6px]">
                {t("Plantings.Species")} *
              </p>
              <CustomAutocomplete
                rules={{
                  required: `${t("Message.Required field")}`,
                }}
                control={control}
                name="species"
                selectOptions={treeSpecies}
                placeholder={t("Plantings.Species")}
                getOptionLabel={(option) =>
                  i18n.language === "en"
                    ? option.name_transes?.en || option.name_transes?.es
                    : option.name_transes?.es
                }
              />
              {errors.species && (
                <p className="error-text">{t("Message.Required field")}</p>
              )}
            </div>
          </div>
          <div className="flex flex-wrap justify-between mt-7 items-center">
            <div className="w-full sm:w-1/2 sm:pr-2">
              <p className="text-sm text-ph-dark-text mb-[6px]">
                {t("Tagging tool.Number of tags")} *
              </p>
              <NumberField
                onChange={() => {}}
                getValues={getValues}
                clearError={clearErrors}
                inputStyle="py-3 placeholder-ph-light-gray"
                name="number_of_tags"
                placeholder={t("0") + ""}
                register={register("number_of_tags", {
                  required: `${t("Message.Required field")}`,
                })}
                setValue={setValue}
                readOnl={false}
              />
              {errors.number_of_tags?.type === "required" && (
                <p className="error-text">{t("Message.Required field")}</p>
              )}
            </div>
          </div>
          <AddTaggingToolButton
            isAddNewTag={() => {
              isAddNewTag(true);
            }}
          />
          {/* <button
            type="submit"
            className="green-btn text-sm px-6 py-2 mt-5 float-right font-medium"
          >
            {t("Tagging tool.Generate tag")}
          </button> */}
        </form>
      </ThemeProvider>

      <Warning when={dirty} onCancel={() => false} onOK={() => true} />
    </Fragment>
  );
};

export default AddTaggingToolForm;
