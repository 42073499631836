import { useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import { colors } from "../../styles/colors";

type title = {
  name: string;
  value: string;
  align?: "left" | "center" | "right";
};

interface IconComponentProps {
  valueToOrderBy: string;
  title: { value: string; name: string };
  angle: JSX.Element;
}

const IconComponent: React.FC<IconComponentProps> = ({
  valueToOrderBy,
  title,
  angle,
}) => {
  return (
    <div>
      {valueToOrderBy === title.value && <div className="ml-3">{angle}</div>}
    </div>
  );
};

const TableHeader: React.FC<{
  setOrdering: (value: string) => void;
  titles: title[];
  showAction?: boolean;
}> = ({ setOrdering, titles, showAction = true }) => {
  const [orderDirection, setOrderDirection] = useState<
    "desc" | "asc" | undefined
  >("asc");
  const [valueToOrderBy, setValueToOrderBy] = useState<string>("");
  const { t } = useTranslation();

  useEffect(() => {
    if (valueToOrderBy !== "") {
      if (orderDirection === "desc") {
        setOrdering(`-${valueToOrderBy}`);
      } else if (orderDirection === "asc") {
        setOrdering(valueToOrderBy);
      } else if (orderDirection === undefined) {
        setOrdering("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueToOrderBy, orderDirection]);

  const createSortHandler =
    (property: string) =>
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      const isAscending =
        valueToOrderBy === property && orderDirection === "asc";
      const isDesc = valueToOrderBy === property && orderDirection === "desc";
      const isUnde =
        valueToOrderBy === property && orderDirection === undefined;
      setValueToOrderBy(property);
      if (isAscending) {
        setOrderDirection("desc");
      } else if (isDesc) {
        setOrderDirection(undefined);
      } else if (isUnde) {
        setOrderDirection("asc");
      } else if (property !== valueToOrderBy) {
        setOrderDirection("asc");
      }
    };
  let angle = <span></span>;
  if (orderDirection === "asc") {
    angle = <FontAwesomeIcon icon={faAngleUp} />;
  }
  if (orderDirection === "desc") {
    angle = <FontAwesomeIcon icon={faAngleDown} />;
  }

  return (
    <TableHead>
      <TableRow>
        {titles.map((title: { name: string; value: string }) => {
          return title.value ? (
            <TableCell key={title.value}>
              <TableSortLabel
                active={
                  valueToOrderBy === title.value && orderDirection !== undefined
                }
                direction={
                  valueToOrderBy === title.value ? orderDirection : "asc"
                }
                onClick={createSortHandler(title.value)}
                IconComponent={(props) => (
                  <IconComponent
                    {...props}
                    valueToOrderBy={valueToOrderBy}
                    title={title}
                    angle={angle}
                  />
                )}
              >
                {title.name}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell key={title.name} style={{ fontWeight: "500" }}>
              {title.name}{" "}
            </TableCell>
          );
        })}
        {showAction ? (
          <TableCell
            align="right"
            style={{ fontWeight: "500", color: colors.default_text }}
          >
            {t("Tree Species.Action")}
          </TableCell>
        ) : (
          <TableCell
            align="right"
            style={{ fontWeight: "500", color: "#414141" }}
          ></TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
