import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAxios from "../../../hook/useAxios";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
} from "@mui/material";
import { organizerCategoryDropdown } from "../../../type/organizerCategoryDropdown";
import { Controller, useForm } from "react-hook-form";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CategoryBaseName } from "../../../type/CategoryBaseName";
import { OrganizerType } from "../../../type/OrganizerType";

type FormValues = {
  name: string;
  category: CategoryBaseName;
};
const AddOrganizerName: React.FC<{
  closeAddOrganizer: () => void;
  addOrganizer: (type: string, value: OrganizerType) => void;
  organizerType: string;
  openModal: boolean;
}> = ({ closeAddOrganizer, addOrganizer, organizerType, openModal }) => {
  const { t, i18n } = useTranslation();

  const [categoryDropDown, setCategoryDropDown] =
    useState<organizerCategoryDropdown>({
      organizer_category_transes: [],
    });
  const { fetchData, response: organizerResponse } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/organizers/new/",
    "POST",
    false,
    `${t("Message.The record added successfully")}`,
    true,
    true
  );

  const { response: baseResponse } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/base_data/",
    "GET",
    true,
    "",
    true,
    false
  );
  useEffect(() => {
    if (baseResponse) {
      setCategoryDropDown(baseResponse.data);
    }
  }, [baseResponse]);
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<FormValues>();

  const addCollaboratorName = (data: FormValues) => {
    fetchData({
      type: organizerType,
      name: data.name,
      is_active: true,
      category: organizerType === "ins" ? data.category : null,
    });
  };
  useEffect(() => {
    if (organizerResponse && organizerResponse.data) {
      if (organizerType === "ins") {
        addOrganizer(organizerType, {
          name: organizerResponse.data.name,
          slug: organizerResponse.data.slug,
          category: organizerResponse.data.category,
        });
      } else {
        addOrganizer(organizerType, {
          name: organizerResponse.data.name,
          slug: organizerResponse.data.slug,
        });
      }

      closeAddOrganizer();
    }
  }, [organizerResponse, organizerType, addOrganizer, closeAddOrganizer]);

  return (
    <Modal
      open={openModal}
      onClose={() => {
        closeAddOrganizer();
      }}
      className="w-full h-full z-[9990] bg-ph-gray bg-opacity-70 flex items-center justify-center fixed top-0 left-0"
    >
      <form>
        <div
          className={`bg-white w-full px-9 py-5 sm:w-[492px] max-h-[90vh] overflow-y-auto rounded-md shadow-md ${
            errors.name || errors.category
              ? "h-auto"
              : organizerType === "ins"
              ? "sm:h-[340px]"
              : "sm:h-[260px]"
          }`}
        >
          <h2 className="font-semibold mb-6 text-default-text">
            {t("Plantings.Create new organizer")}
          </h2>
          <label
            className="text-sm text-default-text"
            htmlFor="addCollaborator"
          >
            {t("Tree Species.Name")}*
          </label>
          <input
            {...register("name", {
              required: `${t("Message.Required field")}`,
            })}
            type="text"
            className="input-field pl-4 py-3 text-sm placeholder-ph-light-gray"
            placeholder={t("Tree Species.Name") + ""}
          />
          {errors.name && <p className="error-text">{errors.name.message}</p>}
          <div className="mt-5">
            {organizerType === "ins" && (
              <>
                <label
                  className="text-sm text-default-text"
                  htmlFor="addCollaborator"
                >
                  {t("Register.Category")}*
                </label>
                <Controller
                  control={control}
                  rules={{ required: `${t("Message.Required field")}` }}
                  name="category"
                  render={({ field: { onChange, value } }) => (
                    <FormControl style={{ width: "100%" }}>
                      <InputLabel shrink={false}>
                        {!value && `${t("Register.Category")}`}
                      </InputLabel>
                      <Select
                        style={{ height: "46px" }}
                        IconComponent={ExpandMoreIcon}
                        variant="outlined"
                        value={value || null}
                        onChange={(e) => {
                          onChange(e as ChangeEvent<Element>);
                        }}
                        sx={{
                          border: "1px solid #eeecec",
                          borderRadius: "4px",
                          "& fieldset": { border: "none" },
                        }}
                      >
                        {categoryDropDown.organizer_category_transes.map(
                          (cat) => (
                            <MenuItem key={cat.value} value={cat.value}>
                              {i18n.language === "en" ? cat.en : cat.es}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  )}
                />
                {errors.category && (
                  <p className="error-text">{t("Message.Required field")}</p>
                )}
              </>
            )}
          </div>
          <div className="mt-10">
            <button
              onClick={handleSubmit(addCollaboratorName)}
              type="button"
              className="bg-ph-btn float-right text-sm text-white rounded-3xl px-6 py-2 hover:bg-ph-hover font-medium"
            >
              {t("Plantings.Create")}
            </button>
            <button
              onClick={() => {
                closeAddOrganizer();
              }}
              type="button"
              className="text-default-text mr-4 float-right text-sm rounded-3xl px-6 py-2 font-medium"
            >
              {t("Message.Cancel")}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default AddOrganizerName;
