/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { t } from "i18next";
import ReportsGeneral from "../ReportsGeneral";
import { Department } from "../../../type/department";
import useAxios from "../../../hook/useAxios";
import { TemplateType } from "../../../type/templateType";
import SpeciesList from "../../../type/speciesList";
import NoDataAvailable from "../../Global/NoDataAvailable";
import ReportsLocationView from "./ReportsLocationView";
import ReportsTreesView from "./ReportsTreesView";
import ReportsParticipantsView from "./ReportsParticipantsView";
import { useSelector } from "react-redux";
import {
  reportPlantedFilterSet,
  reportPlantedURL,
} from "../../../store";
import PlantedTreesFilterApplied from "./PlantedTreesFilterApplied";
import { useTranslation } from "react-i18next";
import { handleExport } from "../../Global/ExportReport";

type FormValues = {
  templateName: TemplateType;
  dateTo: Date;
  dateFromReport: Date | null | "";
  level: string;
  view: string;
  department: Department[] | null;
  community: Department[] | null;
  incidents?: SpeciesList[] | null;
};

const PlantedTreesReport: React.FC = () => {
  const [lists, setLists] = useState<{
    templateList: TemplateType[];
    departmentList: Department[];
    communityList: Department[];
    incidentList: SpeciesList[];
  }>({
    templateList: [],
    departmentList: [],
    communityList: [],
    incidentList: [],
  });

  const [filterData, setFilterData] = useState<FormValues | null>(null);
  const isFilter = useSelector(reportPlantedFilterSet);
  const url = useSelector(reportPlantedURL);
  const { i18n } = useTranslation();
  const { response: departmentRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/departments/all/",
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: communityRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/communities/all/",
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: templateRes, fetchData: fetchTemplates } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/templates/?type=PT",
    "GET",
    true,
    "",
    true,
    false
  );
  const { fetchData, response } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/templates/new/",
    "POST",
    false,
    "",
    true,
    true
  );

  const { fetchData: exportTrees, response: exportTreesResponse } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/reports/export/planted_trees${url}`,
    "GET",
    false,
    t("Message.Successful Export"),
    true,
    true,
    "",
    i18n.language,
    "blob"
  );

  const { fetchData: exportLocations, response: exportLocationsResponse } =
    useAxios(
      process.env.REACT_APP_API_URL +
        `/api/admin/reports/export/planted_trees${url}`,
      "GET",
      false,
      t("Message.Successful Export"),
      true,
      true,
      "",
      i18n.language,
      "blob"
    );

  const {
    fetchData: exportParticipants,
    response: exportParticipantsResponse,
  } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/reports/export/planted_trees${url}`,
    "GET",
    false,
    t("Message.Successful Export"),
    true,
    true,
    "",
    i18n.language,
    "blob"
  );

  useEffect(() => {
    if (!isFilter) {
      setFilterData(null);
    }
  }, [isFilter]);

  useEffect(() => {
    if (templateRes) {
      setLists((prevState) => ({
        ...prevState,
        templateList: templateRes.data,
      }));
    }
    if (departmentRes) {
      setLists((prevState) => ({
        ...prevState,
        departmentList: [
          { name: "All", slug: "all" },
          ...departmentRes.data,
        ],
      }));
    }
    if (communityRes) {
      const communityInitials: Department[] = communityRes.data.map(
        (community: any) => {
          return {
            name: community.name + ` (${community.initials})`, // Concatenate name and initials
            slug: community.slug,
          };
        }
      );
      setLists((prevState) => ({
        ...prevState,
        communityList: [{ name: "All", slug: "all" }, ...communityInitials],
      }));
    }
    if (exportTreesResponse) {
      handleExport(exportTreesResponse);
    }
    if (exportLocationsResponse) {
      handleExport(
        exportLocationsResponse
      );
    }
    if (exportParticipantsResponse) {
      handleExport(
        exportParticipantsResponse
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    templateRes,
    departmentRes,
    communityRes,
    exportTreesResponse,
    exportLocationsResponse,
    exportParticipantsResponse,
  ]);

  const handleApplyFilter = (data: FormValues) => {
    setFilterData(data);
  };

  return (
    <div>
      <ReportsGeneral
        header={t("Statistics.Planted trees")}
        lists={lists}
        type="planted"
        onApplyFilter={handleApplyFilter}
        onSaveTemplate={fetchData}
        templateResponse={response}
        templateList={fetchTemplates}
        filterData={filterData}
        exportResponse={() => {
          if (filterData?.view === "TR") {
            exportTrees({});
          } else if (filterData?.view === "LO") {
            exportLocations({});
          } else if (filterData?.view === "PR") {
            exportParticipants({});
          }
        }}
      />
      {filterData ? (
        filterData?.view === "LO" ? (
          <>
            <div className="parent-container">
              {isFilter && <PlantedTreesFilterApplied />}
            </div>
            <div className="parent-container">
              <ReportsLocationView reportData={filterData} />
            </div>
          </>
        ) : filterData?.view === "TR" ? (
          <>
            <div className="parent-container">
              {isFilter && <PlantedTreesFilterApplied />}
            </div>
            <div className="parent-container">
              <ReportsTreesView reportData={filterData} />
            </div>
          </>
        ) : (
          <>
            <div className="parent-container">
              {isFilter && <PlantedTreesFilterApplied />}
            </div>
            <div className="parent-container">
              <ReportsParticipantsView reportData={filterData} />
            </div>
          </>
        )
      ) : (
        <div className="parent-container">
          <NoDataAvailable
            detailText={t(
              "Reports.Please select the report filters and press Apply."
            )}
          />
        </div>
      )}
    </div>
  );
};

export default PlantedTreesReport;
