import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faUserGroup,
  faBuilding,
} from "@fortawesome/pro-solid-svg-icons";

const OrginizerName: React.FC<{
  organizers: { type: string; slug: string; name: string }[];
  index?: boolean;
  scroll?: boolean;
}> = ({ organizers: organizer, index, scroll }) => {
  return (
    <div className="tree-number">
      {organizer.length}
      <div
        className={`incident-info shadow-2xl ${scroll
            ? "top-[20px]"
            : index
              ? "bottom-[20px]"
              : "top-[20px]"
          }`}
      >
        {organizer.map((name) => (
          <div className="py-2 px-[15px] flex items-center" key={name.slug}>
            <FontAwesomeIcon
              icon={
                name.type === "ind"
                  ? faUser
                  : name.type === "psa"
                    ? faUserGroup
                    : faBuilding
              }
              className="text-ph-gray mr-3"
            />
            <p>{name.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrginizerName;
