import { useTranslation } from "react-i18next";
import CollaboratorName from "../../../type/collaboratorName";

const AppliedReportItem: React.FC<{
  type: string;
  list: boolean;
  listValue: CollaboratorName[];
  stringValue: string;
}> = ({ type, list, listValue, stringValue }) => {
  const { i18n } = useTranslation();
  return (
    <div className="applied-filter-item">
      <p className="text-xs text-default-text">
        {type} :{" "}
        {list ? (
          listValue.map((c, index) => (
            <span key={c.slug}>
              {c.slug === "all"
                ? (i18n.language === "en"
                  ? "All"
                  : "Todos")
                : c.name}
              {index < listValue.length - 1 ? ", " : ""}
            </span>
          ))
        ) : (
          <span className="font-medium text-default-text">{stringValue}</span>
        )}
      </p>
    </div>
  );
};

export default AppliedReportItem;
