import React, { useEffect, useState } from "react";
import { t } from "i18next";
import ReportsGeneral from "../ReportsGeneral";
import { Department } from "../../../type/department";
import useAxios from "../../../hook/useAxios";
import { TemplateType } from "../../../type/templateType";
import SpeciesList from "../../../type/speciesList";
import NoDataAvailable from "../../Global/NoDataAvailable";
import { useSelector } from "react-redux";
import {
  donorsSponsorsReportFilterSet,
  donorsSponsorsReportURL,
} from "../../../store";
import DonorsSponsorsFilterApplied from "./DonorsSponsorsFilterApplied";
import DonorsSponsorsTreesView from "./DonorsSponsorsReportsTreesView";
import DonorsSponsorsReportsTrainingParticipantsView from "./DonorsSponsorsReportsTrainingParticipantsView";
import DonorsSponsorsReportsPlantingParticipantsView from "./DonorsSponsorsReportsPlantingParticipantsView";
import { handleExport } from "../../Global/ExportReport";
import { useTranslation } from "react-i18next";

type FormValues = {
  templateName: TemplateType;
  dateTo: Date;
  dateFromReport: Date | null | "";
  level: string;
  view: string;
  department: Department[] | null;
  community: Department[] | null;
  incidents?: SpeciesList[] | null;
};

const DonorsSponsorsReport: React.FC = () => {
  const [lists, setLists] = useState<{
    templateList: TemplateType[];
    departmentList: Department[];
    communityList: Department[];
    incidentList: SpeciesList[];
  }>({
    templateList: [],
    departmentList: [],
    communityList: [],
    incidentList: [],
  });

  const [filterData, setFilterData] = useState<FormValues | null>(null);
  const isFilter = useSelector(donorsSponsorsReportFilterSet);
  const url = useSelector(donorsSponsorsReportURL);
  const { i18n } = useTranslation();
  const { response: departmentRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/departments/all/",
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: communityRes } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/communities/all/",
    "GET",
    true,
    "",
    false,
    false
  );
  const { response: templateRes, fetchData: fetchTemplates } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/templates/?type=DS",
    "GET",
    true,
    "",
    true,
    false
  );
  const { fetchData: newTemplate, response: newTemplateResponse } = useAxios(
    process.env.REACT_APP_API_URL + "/api/admin/templates/new/",
    "POST",
    false,
    "",
    true,
    true
  );

  const { fetchData: exportTrees, response: exportTreesResponse } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/reports/export/donors/trees${url}`,
    "GET",
    false,
    t("Message.Successful Export"),
    true,
    true,
    "",
    i18n.language,
    "blob"
  );

  const {
    fetchData: exportTrainingParticipants,
    response: exportTrainingParticipantsResponse,
  } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/reports/export/donors/training_participants${url}`,
    "GET",
    false,
    t("Message.Successful Export"),
    true,
    true,
    "",
    i18n.language,
    "blob"
  );

  const {
    fetchData: exportPlantingParticipants,
    response: exportPlantingParticipantsResponse,
  } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/reports/export/donors/planting_participants${url}`,
    "GET",
    false,
    t("Message.Successful Export"),
    true,
    true,
    "",
    i18n.language,
    "blob"
  );

  useEffect(() => {
    if (!isFilter) {
      setFilterData(null);
    }
  }, [isFilter]);

  useEffect(() => {
    if (templateRes) {
      setLists((prevState) => ({
        ...prevState,
        templateList: templateRes.data,
      }));
    }
    if (departmentRes) {
      setLists((prevState) => ({
        ...prevState,
        departmentList: [{ name: "All", slug: "all" }, ...departmentRes.data],
      }));
    }
    if (communityRes) {
      const communityInitials: Department[] = communityRes.data.map(
        (community: any) => {
          return {
            name: community.name + ` (${community.initials})`, // Concatenate name and initials
            slug: community.slug,
          };
        }
      );
      setLists((prevState) => ({
        ...prevState,
        communityList: [{ name: "All", slug: "all" }, ...communityInitials],
      }));
    }
    if (exportTreesResponse) {
      handleExport(exportTreesResponse);
    }
    if (exportTrainingParticipantsResponse) {
      handleExport(
        exportTrainingParticipantsResponse
      );
    }
    if (exportPlantingParticipantsResponse) {
      handleExport(
        exportPlantingParticipantsResponse
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    templateRes,
    departmentRes,
    communityRes,
    exportTreesResponse,
    exportTrainingParticipantsResponse,
    exportPlantingParticipantsResponse,
  ]);

  const handleApplyFilter = (data: FormValues) => {
    setFilterData(data);
  };

  return (
    <div>
      <ReportsGeneral
        header={t("Reports.Donors & Sponsors")}
        lists={lists}
        type="sponsors"
        onApplyFilter={handleApplyFilter}
        onSaveTemplate={newTemplate}
        templateResponse={newTemplateResponse}
        templateList={fetchTemplates}
        filterData={filterData}
        exportResponse={() => {
          if (filterData?.view === "TR") {
            exportTrees({});
          } else if (filterData?.view === "TP") {
            exportTrainingParticipants({});
          } else if (filterData?.view === "PO") {
            exportPlantingParticipants({});
          }
        }}
      />
      {filterData ? (
        filterData?.view === "TP" ? (
          <>
            <div className="parent-container">
              {isFilter && <DonorsSponsorsFilterApplied />}
            </div>
            <div className="parent-container">
              <DonorsSponsorsReportsTrainingParticipantsView
                reportData={filterData}
              />
            </div>
          </>
        ) : filterData?.view === "TR" ? (
          <>
            <div className="parent-container">
              {isFilter && <DonorsSponsorsFilterApplied />}
            </div>
            <div className="parent-container">
              <DonorsSponsorsTreesView reportData={filterData} />
            </div>
          </>
        ) : (
          <>
            <div className="parent-container">
              {isFilter && <DonorsSponsorsFilterApplied />}
            </div>
            <div className="parent-container">
              <DonorsSponsorsReportsPlantingParticipantsView
                reportData={filterData}
              />
            </div>
          </>
        )
      ) : (
        <div className="parent-container">
          <NoDataAvailable
            detailText={t(
              "Reports.Please select the report filters and press Apply."
            )}
          />
        </div>
      )}
    </div>
  );
};

export default DonorsSponsorsReport;
