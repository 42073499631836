import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { donorsSponsorsReportURL } from "../../../store";
import useAxios from "../../../hook/useAxios";

interface ChartData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
    maxBarThickness: number;
  }[];
}
const TreeChart = () => {
  const [chartData, setChartData] = useState<ChartData>({
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: "",
        borderColor: "",
        borderWidth: 1,
        maxBarThickness: 50,
      },
    ],
  });
  const url = useSelector(donorsSponsorsReportURL);

  const { response: treeChartResponse } = useAxios(
    process.env.REACT_APP_API_URL +
      `/api/admin/reports/donors/trees/charts${url}`,
    "GET",
    true,
    "",
    false,
    false
  );

  useEffect(() => {
    if (treeChartResponse) {
      const speciesNames = treeChartResponse.data.planted_trees_data.map(
        (item: any) => item.species_transes.es
      );

      const treeCounts = treeChartResponse.data.planted_trees_data.map(
        (item: any) => item.trees
      );

      setChartData({
        labels: speciesNames,
        datasets: [
          {
            label: "Number of Trees",
            data: treeCounts,
            backgroundColor: "#7DB48B",
            borderColor: "#7DB48B",
            borderWidth: 1,
            maxBarThickness: 50,
          },
        ],
      });
    }
  }, [treeChartResponse]);

  return (
    <div className="w-full mt-[-50px]">
      <div className="py-4 px-5 bg-ph-bg-white">
        <p className="text-ph-dark-gray text-sm">
          {t("Statistics.Planted trees")}
        </p>
      </div>
      <Bar
        data={chartData}
        options={{
          responsive: true,
          scales: {
            x: {
              title: {
                display: true,
                text: `${t("Statistics.Species")}`,
                color: "#414141",
                padding: 15,
                font: { size: 14, family: "Inter" },
              },
              ticks: {
                color: "#414141", // Set the color for x-axis labels (e.g., Tailwind's gray-600)
                font: {
                  family: "Inter",
                  size: 14,
                },
              },
              beginAtZero: true,
            },
            y: {
              title: {
                display: true,
                text: `${t("Statistics.Planted trees")}`,
                color: "#414141",
                font: { size: 14, family: "Inter" },
              },
              ticks: {
                stepSize: 1,
                color: "#414141",
                padding: 15,
                font: {
                  family: "Inter",
                  size: 14,
                },
              },
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      />
    </div>
  );
};

export default TreeChart;
